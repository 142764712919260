import { getDepartmentApi, getMemberApi, getDepartmentMemberApi } from '../apis'
import { createContext, useEffect, useState } from 'react'
import { backend_url,getCookie } from "../constant";
import { toast } from 'react-toastify'
import axios from "axios";
import jwtDecode from "jwt-decode";
const CreateTestContext = createContext()


export const CreateTestProvider = ({ children }) => {
    const [department, setDepartment] = useState([])
    const [image, setImage] = useState("");
    const [member, setMember] = useState([])
    const [validity, setValidity] = useState()
    const departmentData = async () => {
        const resMember = await getMemberApi()
        setMember(resMember.data.data)
        const resDepartment = await getDepartmentApi()
        setDepartment(resDepartment.data.data)
    }
    const deleteDepartment = (id) => {
        setDepartment(department.filter(ele => ele._id !== id))
    }
    const deleteMember = (id) => {
        setMember(member.filter(ele => ele._id !== id))
    }
    const updateMember = (id, updateMember) => {
        setMember(member.map((ele) => ele._id === id ? updateMember : ele))
    }

    const updateDepartment = (updateDepartment) => {
        setDepartment(department.push(updateDepartment))
    }

    const getNoEmpApi = () => {
        getDepartmentMemberApi()
    }
    const getProfileData = async () => {

        const token = getCookie('Xh7ERL0G')
        const decode = jwtDecode(token)
        // console.log(decode)
        const userProfileResponse = await axios.get(`${backend_url}eqa/find/${decode?.user_id}`, { headers: { "token": token } })

        setImage(userProfileResponse.data.data.profileUrl ? userProfileResponse.data.data.profileUrl : "")


    }
    useEffect(() => {
        departmentData()
    }, [])
    const context = {
        department: department,
        deleteDepartment: deleteDepartment,
        updateMember: updateMember,
        deleteMember: deleteMember,
        updateDepartment: updateDepartment,
        getNoEmpApi: getNoEmpApi,
        setImage: setImage,
        member: member,
        image: image
    }
    return (
        <CreateTestContext.Provider value={context}>{children}</CreateTestContext.Provider>
    )
}

export default CreateTestContext