import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import NavigationBar from "../../component/NavigationBar/NavigationBar";
import NewDashboardCard from "../../component/NewDashboardCard";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { backend_url, getCookie } from "../../constant";
import CreateTestContext from "../../store/CreateTestContext";
import ReactTooltip from "react-tooltip";
import SuperNavbar from "../../component/SuperNavbar";
import { getvalidityUserApi } from "../../apis";
import EmployeeChart from "./charts/EmployeeChart";
import GaugeChart from "./charts/GaugeChart";
import ColumnChart from "./charts/ColumnChart";

const NewDashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    invitesUsed: "",
    companyTest: "",
    candidateAppear: "",
    candidateSelected: "",
  });
  const [statisticsLoading, setStatisticsLoading] = useState(false);
  const { department, member } = useContext(CreateTestContext);
  const [validity, setValidity] = useState({});
  const [hired, setHired] = useState();
  const [departmentData, setDepartmentData] = useState();

  const getDashboardData = async () => {
    setStatisticsLoading(true);
    try {
      const token = getCookie("Xh7ERL0G");
      const decode = jwtDecode(token);
      const res = await axios.get(`${backend_url}dashboard/${decode.user_id}`, {
        headers: { token: token },
      });

      let appearedCount = 0;
      let qualifiedCount = 0;

      res.data.invitesData.forEach((data) => {
        switch (data.status) {
          case "appeared":
          case "reviewed":
          case "shortlisted":
          case "hired":
          case "rejected":
            appearedCount++;
            break;
          case "hired":
            qualifiedCount++;
            break;
        }
      });

      const resinvite = await axios.get(`${backend_url}invites/findCandidate`, {
        headers: { token: token },
      });
      setHired(resinvite.data);
      setDashboardData((prev) => ({
        ...prev,
        invitesUsed: res.data.invitesData.length,
        companyTest: res.data.assement.length,
        candidateAppear: appearedCount,
        candidateSelected: qualifiedCount,
      }));
      setStatisticsLoading(false);
    } catch (error) {
      setStatisticsLoading(false);
    }
  };
  const DashboardDatafun = async () => {
    const resValidity = await getvalidityUserApi();
    setValidity(resValidity.data.data);
  };

  useEffect(() => {
    getDashboardData();
    DashboardDatafun();
  }, []);

  return (
    <div>
      <SuperNavbar active="dashboard" />
      <ReactTooltip />
      <div className="new-dashboard-container">
        <div className="card-container">
          <div className="inviteCardMainContainer">
            <div data-tip="Invite Used" className="inviteCard">
              <span>
                Invites Used
                <br />
                {statisticsLoading ? (
                  <div className="loader"></div>
                ) : (
                  <b>
                    {500}/{1000}
                  </b>
                )}
              </span>
              <meter id="disk_d" value={(500 / 1000).toFixed(2)}>
                100%
              </meter>
            </div>
            <div className="icon-container">
              <svg
                width="83"
                height="92"
                viewBox="0 0 98 108"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0H96C97.1046 0 98 0.895431 98 2V106C98 107.105 97.1046 108 96 108H0V0Z"
                  fill="#384455"
                />
                <path
                  d="M55.25 51.5H59M59 51.5H62.75M59 51.5V47.75M59 51.5V55.25M35.25 64V62.75C35.25 60.4294 36.1719 58.2038 37.8128 56.5628C39.4538 54.9219 41.6794 54 44 54M44 54C46.3206 54 48.5462 54.9219 50.1872 56.5628C51.8281 58.2038 52.75 60.4294 52.75 62.75V64M44 54C45.3261 54 46.5979 53.4732 47.5355 52.5355C48.4732 51.5979 49 50.3261 49 49C49 47.6739 48.4732 46.4021 47.5355 45.4645C46.5979 44.5268 45.3261 44 44 44C42.6739 44 41.4021 44.5268 40.4645 45.4645C39.5268 46.4021 39 47.6739 39 49C39 50.3261 39.5268 51.5979 40.4645 52.5355C41.4021 53.4732 42.6739 54 44 54Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>{" "}
            </div>
          </div>

          <div className="inviteCardMainContainer">
            <div data-tip="Access Used" className="accessCard">
              <span>
                Access Used <br />
                {statisticsLoading ? (
                  <div className="loader"></div>
                ) : (
                  <b>
                    {500}/{1000}
                  </b>
                )}
              </span>
              <meter id="disk_d" value={(500 / 1000).toFixed(2)}>
                100%
              </meter>
            </div>

            <div className="icon-container">
              <svg
                width="83"
                height="92"
                viewBox="0 0 98 108"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0H96C97.1046 0 98 0.895431 98 2V106C98 107.105 97.1046 108 96 108H0V0Z"
                  fill="#384455"
                />
                <path
                  d="M35.25 64V62.75C35.25 60.4294 36.1719 58.2038 37.8128 56.5628C39.4538 54.9219 41.6794 54 44 54C46.3206 54 48.5462 54.9219 50.1872 56.5628C51.8281 58.2038 52.75 60.4294 52.75 62.75V64"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M50.25 56.5C50.25 54.8424 50.9085 53.2527 52.0806 52.0806C53.2527 50.9085 54.8424 50.25 56.5 50.25C57.3208 50.25 58.1335 50.4117 58.8918 50.7258C59.6501 51.0398 60.3391 51.5002 60.9194 52.0806C61.4998 52.6609 61.9602 53.3499 62.2742 54.1082C62.5883 54.8665 62.75 55.6792 62.75 56.5V57.125"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M44 54C45.3261 54 46.5979 53.4732 47.5355 52.5355C48.4732 51.5979 49 50.3261 49 49C49 47.6739 48.4732 46.4021 47.5355 45.4645C46.5979 44.5268 45.3261 44 44 44C42.6739 44 41.4021 44.5268 40.4645 45.4645C39.5268 46.4021 39 47.6739 39 49C39 50.3261 39.5268 51.5979 40.4645 52.5355C41.4021 53.4732 42.6739 54 44 54ZM56.5 50.25C57.4946 50.25 58.4484 49.8549 59.1516 49.1517C59.8549 48.4484 60.25 47.4946 60.25 46.5C60.25 45.5054 59.8549 44.5516 59.1516 43.8483C58.4484 43.1451 57.4946 42.75 56.5 42.75C55.5054 42.75 54.5516 43.1451 53.8483 43.8483C53.1451 44.5516 52.75 45.5054 52.75 46.5C52.75 47.4946 53.1451 48.4484 53.8483 49.1517C54.5516 49.8549 55.5054 50.25 56.5 50.25Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>

          <div className="inviteCardMainContainer">
            <div data-tip="Hired Card" className="hiredCard">
              <span>
                Candidates Hired
                <br />
                {statisticsLoading ? (
                  <div className="loader"></div>
                ) : (
                  <b>
                    {500}/{1000}
                  </b>
                )}
              </span>
              <meter id="disk_d" value={(500 / 1000).toFixed(2)}>
                100%
              </meter>
            </div>
            <div className="icon-container">
              <svg
                width="83"
                height="92"
                viewBox="0 0 98 108"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0H96C97.1046 0 98 0.895431 98 2V106C98 107.105 97.1046 108 96 108H0V0Z"
                  fill="#384455"
                />
                <path
                  d="M49 54C50.3261 54 51.5979 53.4732 52.5355 52.5355C53.4732 51.5979 54 50.3261 54 49C54 47.6739 53.4732 46.4021 52.5355 45.4645C51.5979 44.5268 50.3261 44 49 44C47.6739 44 46.4021 44.5268 45.4645 45.4645C44.5268 46.4021 44 47.6739 44 49C44 50.3261 44.5268 51.5979 45.4645 52.5355C46.4021 53.4732 47.6739 54 49 54Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M40.25 64.0001V62.7501C40.2497 61.2738 40.6229 59.8215 41.3349 58.5282C42.0469 57.235 43.0746 56.1429 44.3222 55.3537C45.5698 54.5644 46.9968 54.1037 48.4704 54.0143C49.9439 53.925 51.4162 54.2099 52.75 54.8426M54.7938 59.5188L56.0925 56.7651C56.1276 56.6864 56.1848 56.6195 56.2571 56.5726C56.3294 56.5257 56.4138 56.5007 56.5 56.5007C56.5862 56.5007 56.6706 56.5257 56.7429 56.5726C56.8152 56.6195 56.8724 56.6864 56.9075 56.7651L58.2075 59.5188L61.1112 59.9638C61.4837 60.0201 61.6312 60.5001 61.3612 60.7751L59.2612 62.9163L59.7562 65.9426C59.82 66.3314 59.4312 66.6276 59.0975 66.4438L56.5 65.0151L53.9025 66.4438C53.5687 66.6276 53.18 66.3313 53.2438 65.9438L53.7388 62.9163L51.6388 60.7751C51.3688 60.5001 51.5162 60.0201 51.8887 59.9626L54.7938 59.5188Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="top-report">
          <div className="employe-report-container">
            <div className="employee-report-header">
              <div className="left-side">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="24"
                    cy="24"
                    r="24"
                    fill="#00C49A"
                    fill-opacity="0.1"
                  />
                  <path
                    d="M13 32V31C13 29.1435 13.7375 27.363 15.0503 26.0503C16.363 24.7375 18.1435 24 20 24C21.8565 24 23.637 24.7375 24.9497 26.0503C26.2625 27.363 27 29.1435 27 31V32"
                    stroke="#00C49A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M25 26C25 24.6739 25.5268 23.4021 26.4645 22.4645C27.4021 21.5268 28.6739 21 30 21C30.6566 21 31.3068 21.1293 31.9134 21.3806C32.52 21.6319 33.0712 22.0002 33.5355 22.4645C33.9998 22.9288 34.3681 23.48 34.6194 24.0866C34.8707 24.6932 35 25.3434 35 26V26.5"
                    stroke="#00C49A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M20 24C21.0609 24 22.0783 23.5786 22.8284 22.8284C23.5786 22.0783 24 21.0609 24 20C24 18.9391 23.5786 17.9217 22.8284 17.1716C22.0783 16.4214 21.0609 16 20 16C18.9391 16 17.9217 16.4214 17.1716 17.1716C16.4214 17.9217 16 18.9391 16 20C16 21.0609 16.4214 22.0783 17.1716 22.8284C17.9217 23.5786 18.9391 24 20 24ZM30 21C30.7956 21 31.5587 20.6839 32.1213 20.1213C32.6839 19.5587 33 18.7956 33 18C33 17.2044 32.6839 16.4413 32.1213 15.8787C31.5587 15.3161 30.7956 15 30 15C29.2043 15 28.4413 15.3161 27.8787 15.8787C27.3161 16.4413 27 17.2044 27 18C27 18.7956 27.3161 19.5587 27.8787 20.1213C28.4413 20.6839 29.2043 21 30 21Z"
                    stroke="#00C49A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div className="employee-report-header-title">
                  <span>Employee Reports</span>
                  <p>Employee assessment report analysis</p>
                </div>
              </div>
              <div className="right-side">
                <div className="employee-report-btn">
                  <div className="select-emplyee-btn">
                    <select name="id" id="" onChange={(e) => {}}>
                      <option>Select Employee</option>
                      {member.map((ele, i) => {
                        return (
                          <option key={i} value={ele._id}>
                            {ele.fullName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-container">
              <EmployeeChart />
            </div>
          </div>

          <div className="invites-report-container">
            <div className="invites-report-header">
              <div className="left-side">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="24"
                    cy="24"
                    r="24"
                    fill="#00C49A"
                    fill-opacity="0.1"
                  />
                  <path
                    d="M29 22H32M32 22H35M32 22V19M32 22V25M13 32V31C13 29.1435 13.7375 27.363 15.0503 26.0503C16.363 24.7375 18.1435 24 20 24M20 24C21.8565 24 23.637 24.7375 24.9497 26.0503C26.2625 27.363 27 29.1435 27 31V32M20 24C21.0609 24 22.0783 23.5786 22.8284 22.8284C23.5786 22.0783 24 21.0609 24 20C24 18.9391 23.5786 17.9217 22.8284 17.1716C22.0783 16.4214 21.0609 16 20 16C18.9391 16 17.9217 16.4214 17.1716 17.1716C16.4214 17.9217 16 18.9391 16 20C16 21.0609 16.4214 22.0783 17.1716 22.8284C17.9217 23.5786 18.9391 24 20 24Z"
                    stroke="#00C49A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div className="invites-report-header-title">
                  <span>Invites Reports</span>
                  <p>Invite usage across departments</p>
                </div>
              </div>
              <div className="right-side">
                <div className="invites-report-btn">
                  <div className="select-invites-btn">
                    {/* <select name="" id="">
                                            <option value="Select Department">Select Department</option>
                                        </select> */}

                    <select
                      name="id"
                      onChange={(e) => {
                        setDepartmentData(JSON.parse(e?.target?.value));
                      }}
                    >
                      {department.map((ele, i) => {
                        return (
                          <option key={i} value={JSON.stringify(ele)}>
                            {ele.department}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-container">
              <GaugeChart departmentData={departmentData} />
              <div className="GuageIndicators">
                <div className="indicator">
                  <svg
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="7.10791"
                      cy="8.02417"
                      r="7.10791"
                      fill="#00C49A"
                    />
                  </svg>
                  <p>Used:</p>
                  <h5>
                    {(departmentData?.inviteUsed /
                      departmentData?.inviteAssigned) *
                      100}
                    %
                  </h5>
                </div>

                <div className="indicator">
                  <svg
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="7.32275"
                      cy="8.02417"
                      r="7.10791"
                      fill="#827C7C"
                    />
                  </svg>

                  <p>Remaining:</p>
                  <h5>
                    {100 -
                      (departmentData?.inviteUsed /
                        departmentData?.inviteAssigned) *
                        100}
                    %
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-report">
          <div className="bottom-report-header">
            <svg
              width="40"
              height="40"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="24"
                cy="24"
                r="24"
                fill="#00C49A"
                fill-opacity="0.1"
              />
              <path
                d="M15.5 33C15.1 33 14.75 32.85 14.45 32.55C14.15 32.25 14 31.9 14 31.5V19.275C14 18.875 14.15 18.525 14.45 18.225C14.75 17.925 15.1 17.775 15.5 17.775H20.25V15.5C20.25 15.1 20.4 14.75 20.7 14.45C21 14.15 21.35 14 21.75 14H26.25C26.65 14 27 14.15 27.3 14.45C27.6 14.75 27.75 15.1 27.75 15.5V17.775H32.5C32.9 17.775 33.25 17.925 33.55 18.225C33.85 18.525 34 18.875 34 19.275V31.5C34 31.9 33.85 32.25 33.55 32.55C33.25 32.85 32.9 33 32.5 33H15.5ZM21.75 17.775H26.25V15.5H21.75V17.775ZM32.5 26.725H26.275V28.225H21.775V26.725H15.5V31.5H32.5V26.725ZM23.275 26.725H24.775V25.225H23.275V26.725ZM15.5 25.225H21.775V23.725H26.275V25.225H32.5V19.275H15.5V25.225Z"
                fill="#00C49A"
              />
            </svg>

            <div className="bottom-report-header-title">
              <span>Department Reports</span>
              <p>View Reports of all departments</p>
            </div>
          </div>
          <div
            className="content-container"
            style={{ padding: "20px", height: "auto" }}
          >
            <ColumnChart />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDashboard;
