import { Funnel, FUNNEL_CONVERSATION_FIELD } from "@ant-design/plots";

const AntFunnelGraph = (props) => {
  console.log("Funnel Props", props.data);
  const data = [
    {
      stage: "Invited",
      number: props?.data?.candidatesInvited,
    },
    {
      stage: "Attempted",
      number: props?.data?.candidatesAppeared,
    },
    {
      stage: "Shortlisted",
      number: props?.data?.candidatesShortlisted,
    },
  ];
  const config = {
    data: data,
    xField: "stage",
    yField: "number",
    isTransposed: true,
    minSize: 0.04,
    maxSize: 0.8,
    label: {
      formatter: (datum) => {
        return `${datum.stage}:${datum.number}`;
      },
    },
    conversionTag: {
      formatter: (datum) => {
        return (
          datum[FUNNEL_CONVERSATION_FIELD][1] /
          datum[FUNNEL_CONVERSATION_FIELD][0]
        ).toFixed(2);
      },
    },
    tooltip: {
      formatter: (datum) => {
        return {
          name: datum.stage,
          value: `${datum.number}个`,
        };
      },
    },
    color: "#FEE9E1",
  };
  return <Funnel {...config} label={{ style: { fill: "black" } }} />;
};
export default AntFunnelGraph;
