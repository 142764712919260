import axios from "axios";
import { backend_url,getCookie } from "../constant";



export const getApi = (path, data) => {
	const token = getCookie('Xh7ERL0G');

	return axios.get(backend_url + path, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	});
};

export const deleteApi = (path, data) => {
	const token = getCookie('Xh7ERL0G');


	return axios.delete(backend_url + path, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	});
};
export const postApi = (path, data) => {
	const token = getCookie('Xh7ERL0G');

	return axios.post(backend_url + path, data, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	});
};

export const putApi = (path, data) => {
	const token = getCookie('Xh7ERL0G');

	return axios.put(backend_url + path, data, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	});
};

// export const putApiWithoutToken = (path, data) => {
// 	return axios.put(API_BASE_URL + path, data, {
// 		headers: {
// 			"Content-Type": "application/json",
// 		},
// 	});
// };
// export const postApiWithoutToken = (path, data) => {
// 	return axios.post(API_BASE_URL + path, data, {
// 		headers: {
// 			"Content-Type": "application/json",
// 		},
// 	});
// };


// export const getApiWithoutToken = (path) => {
// 	return axios.get(API_BASE_URL + path, {
// 		headers: {
// 			"Content-Type": "application/json",
// 		},
// 	});
// };








// export const fileUpload = (path, data) => {
// 	const token = getCookie('Xh7ERL0G');

// 	return axios.post(API_BASE_URL + path, data, {
// 		headers: {
// 			"Content-Type": "multipart/form-data",
// 			Authorization: `Bearer ${token}`,
// 		},
// 	});
// };
