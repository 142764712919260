import { Column } from "@ant-design/plots";
import { useEffect, useState } from "react";
import { backend_url, getCookie } from "../../../constant";
import axios from "axios";

const ColumnChart = () => {
  const [data, setData] = useState([]);
  const getDepartmentReportData = async () => {
    try {
      const token = getCookie("Xh7ERL0G");
      const res = await axios.get(`${backend_url}invites/department/report`, {
        headers: { token: token },
      });
      setData(
        res?.data?.data.flatMap(
          ({ department, inviteUsed, inviteRemaining, candidatesStats }) => [
            {
              name: "Candidates Selected",
              department,
              value: candidatesStats?.candidatesHired,
              type: "Candidates Selected",
            },
            {
              name: "Invites",
              department,
              value: inviteUsed,
              type: "Invites Used",
            },
            {
              name: "Invites",
              department,
              value: inviteRemaining,
              type: "Invites Ramaining",
            },
          ]
        )
      );
    } catch (error) {}
  };

  useEffect(() => {
    getDepartmentReportData();
  }, []);

  const config = {
    data,

    xField: "department",
    yField: "value",
    isGroup: true,
    isStack: true,

    colorField: "type", // or seriesField in some cases
    color: ({ type }) => {
      switch (type) {
        case "Invites Used":
          return "#FF6812";
        case "Invites Ramaining":
          return "#FF9736";
        case "Candidates Selected":
          return "#00C49A";
      }
      return "yellow";
    },
    tooltip: {
      formatter: (datum) => {
        return {
          name: datum.type,
          value: datum.value,
        };
      },
    },

    seriesField: "type",
    groupField: "name",
    columnWidthRatio: 0.4,
  };

  return <Column width={50} {...config} limitInPlot />;
};

export default ColumnChart;
