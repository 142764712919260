import React, { useState, useContext, useEffect } from "react";
import "./index.css";
import SuperNavbar from "../../component/SuperNavbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ManageAccessSideBar from "../../component/ManageAccessSidebar";
import DeletePopup from "../../component/DeletePopup";
import EditInviteMember from "../../component/EditInviteMember";
import CreateTestContext from "../../store/CreateTestContext";
import { AssignInvitesApi } from "../../apis";
import { useNavigate } from "react-router-dom";
import {
  deleteDepartmentApi,
  addDepartmentApi,
  getDepartmentApi,
  getvalidityUserApi,
} from "../../apis";

const ManageInvites = () => {
  let navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [deleteInvite, setDeleteInvite] = useState(false);
  const [validity, setValidity] = useState();
  const [depMemCount, setDepMemCount] = useState([]);
  const [editInvite, setEditInvite] = useState(false);
  const [id, setId] = useState();
  const { deleteDepartment } = useContext(CreateTestContext);
  const [department, setDepartment] = useState([]);
  const [errorDepartmentId, setErrorDepartmentId] = useState();
  const [errorInviteAssigned, setErrorInviteAssigned] = useState();

  const [fromData, setFromData] = useState({
    inviteAssigned: 0,
  });

  const handelChange = (e) => {
    console.log(e.target.value);
    setFromData({ ...fromData, [e.target.name]: parseInt(e.target.value) });
    setErrorDepartmentId();
    setErrorInviteAssigned();
    console.log(fromData);
  };
  const handelonSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;
    if (id == undefined) {
      setErrorDepartmentId("Department is required");
      isValid = false;
    }
    if (parseInt(fromData.inviteAssigned) <= 0) {
      setErrorInviteAssigned(" Must be greater than 0");
      isValid = false;
    }
    if (parseInt(fromData.inviteAssigned) > validity?.totalRemainingInvite) {
      setErrorInviteAssigned(" Must be less than remaining invites");
      isValid = false;
    }

    if (isValid) {
      const res = await AssignInvitesApi(id, fromData);
      if (res.status == 200) {
        getDepartment();
        memberData();
        fromData.inviteAssigned = 0;
        e.target.reset();
        toast(res.data.msg, {
          className: "toast-message",
        });
      }
    }
  };
  const handleDeleteInvitePopup = () => {
    setDeleteInvite(false);
  };

  const memberData = async () => {
    try {
      const resValidity = await getvalidityUserApi();
      console.log(resValidity);
      setValidity(resValidity.data.data);
    } catch (error) {
      toast(error.message, {
        className: "toast-message",
      });
    }
  };

  const getDepartment = async () => {
    try {
      const resDepartment = await getDepartmentApi(
        `page=${currentPage}&limit=10`
      );
      setDepartment(resDepartment.data.data);
      // setDepMemCount(resDepartment.data.countNo);
      setloading(false);
      setCurrentPage(parseInt(resDepartment.data.currentPage));
      setTotalPages(parseInt(resDepartment.data.totalPages));
      setTotalDocs(parseInt(resDepartment.data.totalDocs));
    } catch (error) {
      toast(error.message, {
        className: "toast-message",
      });
    }
  };

  useEffect(() => {
    memberData();
    getDepartment();
  }, [currentPage]);

  return (
    <div className="manage-invites-main-container">
      <SuperNavbar active={"settings"} />
      {deleteInvite ? (
        <DeletePopup
          cancelButton={handleDeleteInvitePopup}
          onClickYes={async () => {
            const res = await deleteDepartmentApi(id);
            if (res.status == 200) {
              deleteDepartment(id);
              memberData();
              setDeleteInvite(false);
              toast("Deleted successfully", {
                className: "toast-message",
              });
            }
          }}
        />
      ) : null}
      {editInvite ? (
        <EditInviteMember
          data={id}
          closeModal={() => setEditInvite(false)}
          memberData={memberData}
          getDepartment={getDepartment}
        />
      ) : null}
      <div className="manage-invites-outer-container">
        <div className="manage-invites-left">
          <ManageAccessSideBar active={"invite"} />
        </div>
        <div className="manage-invite-right">
          <div className="statistics-card-container">
            <div data-tip="Invite Used" className="inviteCard">
              <div className="totalInviteUsed">
                {validity ? (
                  <>
                    <span>
                      Total Invites
                      <br />
                      <b>{validity?.numberOfInvites}</b>
                    </span>
                  </>
                ) : (
                  <div class="loader"></div>
                )}
              </div>
              <div className="icon-container">
                <svg
                  width="83"
                  height="75"
                  viewBox="0 0 98 108"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 0H96C97.1046 0 98 0.895431 98 2V106C98 107.105 97.1046 108 96 108H0V0Z"
                    fill="#384455"
                  />
                  <path
                    d="M55.25 51.5H59M59 51.5H62.75M59 51.5V47.75M59 51.5V55.25M35.25 64V62.75C35.25 60.4294 36.1719 58.2038 37.8128 56.5628C39.4538 54.9219 41.6794 54 44 54M44 54C46.3206 54 48.5462 54.9219 50.1872 56.5628C51.8281 58.2038 52.75 60.4294 52.75 62.75V64M44 54C45.3261 54 46.5979 53.4732 47.5355 52.5355C48.4732 51.5979 49 50.3261 49 49C49 47.6739 48.4732 46.4021 47.5355 45.4645C46.5979 44.5268 45.3261 44 44 44C42.6739 44 41.4021 44.5268 40.4645 45.4645C39.5268 46.4021 39 47.6739 39 49C39 50.3261 39.5268 51.5979 40.4645 52.5355C41.4021 53.4732 42.6739 54 44 54Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div data-tip="Invite Used" className="inviteCard">
              <div className="totalInviteUsed">
                {validity ? (
                  <>
                    <span>
                      Invites Used
                      <br />
                      <b>{validity?.totalInviteUsed}</b>
                    </span>
                  </>
                ) : (
                  <div class="loader"></div>
                )}
              </div>
              <div className="icon-container">
                <svg
                  width="83"
                  height="75"
                  viewBox="0 0 98 108"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 0H96C97.1046 0 98 0.895431 98 2V106C98 107.105 97.1046 108 96 108H0V0Z"
                    fill="#384455"
                  />
                  <path
                    d="M55.25 51.5H59M59 51.5H62.75M59 51.5V47.75M59 51.5V55.25M35.25 64V62.75C35.25 60.4294 36.1719 58.2038 37.8128 56.5628C39.4538 54.9219 41.6794 54 44 54M44 54C46.3206 54 48.5462 54.9219 50.1872 56.5628C51.8281 58.2038 52.75 60.4294 52.75 62.75V64M44 54C45.3261 54 46.5979 53.4732 47.5355 52.5355C48.4732 51.5979 49 50.3261 49 49C49 47.6739 48.4732 46.4021 47.5355 45.4645C46.5979 44.5268 45.3261 44 44 44C42.6739 44 41.4021 44.5268 40.4645 45.4645C39.5268 46.4021 39 47.6739 39 49C39 50.3261 39.5268 51.5979 40.4645 52.5355C41.4021 53.4732 42.6739 54 44 54Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div data-tip="Invite Used" className="inviteCard">
              <div className="totalInviteUsed">
                {validity ? (
                  <>
                    <span>
                      Invites Remaining
                      <br />
                      <b>{validity?.totalRemainingInvite}</b>
                    </span>
                  </>
                ) : (
                  <div class="loader"></div>
                )}
              </div>
              <div className="icon-container">
                <svg
                  width="83"
                  height="75"
                  viewBox="0 0 98 108"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 0H96C97.1046 0 98 0.895431 98 2V106C98 107.105 97.1046 108 96 108H0V0Z"
                    fill="#384455"
                  />
                  <path
                    d="M55.25 51.5H59M59 51.5H62.75M59 51.5V47.75M59 51.5V55.25M35.25 64V62.75C35.25 60.4294 36.1719 58.2038 37.8128 56.5628C39.4538 54.9219 41.6794 54 44 54M44 54C46.3206 54 48.5462 54.9219 50.1872 56.5628C51.8281 58.2038 52.75 60.4294 52.75 62.75V64M44 54C45.3261 54 46.5979 53.4732 47.5355 52.5355C48.4732 51.5979 49 50.3261 49 49C49 47.6739 48.4732 46.4021 47.5355 45.4645C46.5979 44.5268 45.3261 44 44 44C42.6739 44 41.4021 44.5268 40.4645 45.4645C39.5268 46.4021 39 47.6739 39 49C39 50.3261 39.5268 51.5979 40.4645 52.5355C41.4021 53.4732 42.6739 54 44 54Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            {/* <div data-tip="Access Used" className="accessCard">
              {!validity ? (
                <>
                  {" "}
                  <span>
                    Total Assigned
                    <br />
                    <b>500</b>
                  </span>
                </>
              ) : (
                <div class="loader"></div>
              )}
            </div>
            <div data-tip="Access Used" className="accessCard">
              {!validity ? (
                <>
                  <span>
                    Total Remaining
                    <br />
                    <b>500</b>
                  </span>
                </>
              ) : (
                <div class="loader"></div>
              )}
            </div> */}
          </div>

          <div className="manage-invites-form-container">
            <div className="manage-access-content">
              <div className="manage-access-header">
                <div className="manage-access-title">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="24"
                      cy="24"
                      r="24"
                      fill="#00C49A"
                      fill-opacity="0.1"
                    />
                    <path
                      d="M33 16L22 27"
                      stroke="#00C49A"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M33 16L26 36L22 27L13 23L33 16Z"
                      stroke="#00C49A"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <div className="manage-access-name">
                    <span>Assign Invites</span>
                    <p>Assign invites to each department</p>
                  </div>
                </div>
              </div>
              <form onSubmit={(e) => handelonSubmit(e)}>
                <div className="manage-access-form-container">
                  <div className="top-forms-container">
                    <div
                      className="input-outer-container"
                      style={{
                        width: "25%",
                      }}
                    >
                      <span>
                        Department <span className="requeredclass">*</span>
                      </span>
                      <div className="input-inner-container name-input-box">
                        <select
                          name="id"
                          id=""
                          onChange={(e) => {
                            setId(e.target.value);
                          }}
                        >
                          <option>Select</option>
                          {department.map((ele, i) => {
                            return (
                              <option key={i} value={ele._id}>
                                {ele.department}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <span className="errorMsg">{errorDepartmentId}</span>
                    </div>

                    <div className="input-outer-container">
                      <span>
                        Assign Invites <span className="requeredclass">*</span>
                      </span>
                      <div className="input-inner-container name-input-box">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 1.5L8.25 9.75"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M16.5 1.5L11.25 16.5L8.25 9.75L1.5 6.75L16.5 1.5Z"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        <input
                          type="number"
                          placeholder="Enter number of invites"
                          name="inviteAssigned"
                          onChange={handelChange}
                        />
                      </div>
                      <span className="errorMsg">{errorInviteAssigned}</span>
                    </div>

                    <div className="send-invite-btn-container">
                      <button>
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="10" cy="10.2449" r="10" fill="white" />
                          <g clip-path="url(#clip0_6565_5953)">
                            <path
                              d="M14.5 5.24487L9 10.7449"
                              stroke="#FF6812"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M14.5 5.24487L11 15.2449L9 10.7449L4.5 8.74487L14.5 5.24487Z"
                              stroke="#FF6812"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6565_5953">
                              <rect
                                width="12"
                                height="12"
                                fill="white"
                                transform="translate(3.5 4.24487)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        Assign Invites
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <div className="manage-access-table-content">
                {/* <div className="table-header-container">
                                    <span>Manage Department & Invites</span>
                                </div> */}
                <div className="table1-heading">
                  <div className="table-header-container">
                    <span>Manage Department & Invites</span>
                  </div>

                  <div className="pagination">
                    <button
                      onClick={() => {
                        if (currentPage > 1) {
                          if (currentPage > 1) {
                            if (loading === false) {
                              setCurrentPage(currentPage - 1);
                            }
                          }
                        }
                      }}
                    >
                      <svg
                        width="7"
                        height="12"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L2.41421 6Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    <span>
                      {totalPages === 0 ? 0 : currentPage}/{totalPages}
                    </span>
                    <button
                      onClick={() => {
                        setCurrentPage(
                          currentPage === totalPages || totalPages === 0
                            ? currentPage
                            : loading === false
                            ? currentPage + 1
                            : currentPage
                        );
                      }}
                    >
                      <svg
                        width="7"
                        height="12"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <table cellSpacing={0}>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Department</th>
                    <th>Assigned Invites</th>
                    <th>Used Invites</th>
                    <th>Remaining Invites</th>
                    <th>Settings</th>
                  </tr>
                  {department.map((ele, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{ele?.department}</td>
                        <td>{ele?.inviteAssigned}</td>
                        <td>{ele?.inviteUsed}</td>
                        <td>{ele?.inviteRemaining}</td>
                        <td>
                          <button
                            onClick={() => {
                              setEditInvite(true);
                              setId(ele);
                            }}
                          >
                            <svg
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.867188 8.87124H1.41719L6.95469 3.33374L6.40469 2.78374L0.867188 8.32124V8.87124ZM8.54219 2.79624L6.94219 1.19624L7.46719 0.67124C7.60885 0.529574 7.78385 0.45874 7.99219 0.45874C8.20052 0.45874 8.37552 0.529574 8.51719 0.67124L9.06719 1.22124C9.20885 1.36291 9.27969 1.53791 9.27969 1.74624C9.27969 1.95457 9.20885 2.12957 9.06719 2.27124L8.54219 2.79624ZM8.01719 3.32124L1.71719 9.62124H0.117188V8.02124L6.41719 1.72124L8.01719 3.32124ZM6.67969 3.05874L6.40469 2.78374L6.95469 3.33374L6.67969 3.05874Z"
                                fill="#333333"
                              />
                            </svg>
                            Edit
                          </button>
                          <button
                            onClick={() => {
                              setDeleteInvite(true);
                              setId(ele._id);
                            }}
                          >
                            <svg
                              width="9"
                              height="10"
                              viewBox="0 0 9 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.87969 9.12134C1.67344 9.12134 1.49688 9.0479 1.35 8.90103C1.20313 8.75415 1.12969 8.57759 1.12969 8.37134V1.24634H0.617188V0.496338H2.96719V0.121338H6.26719V0.496338H8.61719V1.24634H8.10469V8.37134C8.10469 8.57134 8.02969 8.74634 7.87969 8.89634C7.72969 9.04634 7.55469 9.12134 7.35469 9.12134H1.87969ZM7.35469 1.24634H1.87969V8.37134H7.35469V1.24634ZM3.20469 7.29634H3.95469V2.30884H3.20469V7.29634ZM5.27969 7.29634H6.02969V2.30884H5.27969V7.29634Z"
                                fill="#333333"
                              />
                            </svg>
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageInvites;
