import { Pie, measureTextWidth } from "@ant-design/plots";
import { click } from "@testing-library/user-event/dist/click";

const EmployeeChart = () => {
  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : "inherit"
    };">${text}</div>`;
  }

  const data = [
    {
      type: "Test Created",
      value: 100,
    },
    {
      type: "Invitations Sent",
      value: 100,
    },
    {
      type: "Candidates Interviewed",
      value: 50,
    },
    {
      type: "Selected",
      value: 50,
    },
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",

    color: ({ type }) => {
      switch (type) {
        case "Test Created":
          return "#FF6812";
        case "Selected":
          return "#00C49A";
        case "Invitations Sent":
          return "#384455";
        case "Candidates Interviewed":
          return "#809FB8";
      }
      return "yellow";
    },
    radius: 0.7,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) => `${v}`,
      },
    },
    label: {
      type: "inner",
      offset: "-50%",
      style: {
        textAlign: "center",
        fontFamily: "Roboto",
        fontWeight: "bold",
      },
      autoRotate: false,
      content: "{value}",
    },

    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.type : "Selected";
          return renderStatistic(d, text, {
            fontSize: 18,
          });
        },
        style: {
          fontFamily: "Roboto",
          fontWeight: "400",
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: "18px",
          fontFamily: "Roboto",
          fontWeight: "bold",
        },
        customHtml: (container, view, datum, data) => {
          const { width } = container.getBoundingClientRect();
          const text = datum ? `${datum.value}` : `50`;
          return renderStatistic(width, text, {
            fontSize: 18,
          });
        },
      },
    },
  };

  return <Pie {...config} />;
};

export default EmployeeChart;
