import { createContext, useState } from "react";
import { backend_url,getCookie } from "../constant"
import axios from 'axios';

const TestSummaryContext = createContext({
    testId: '',
    testTakenCandidates: [],
    reviewedCandidates: [],
    shortlistedCandidates: [],
    invitedCandidates: [],
    loading: false,
    test: null,

    handleTestId: () => { },
    getCandidates: () => { },
    handleTestTakenCandidates: () => { },
    handleReviewedCandidates: () => { },
    handleShortlistedCandidates: () => { },
    handleInvitedCandidates: () => { },
    filterCandidates: () => { },
    handleTest: () => { },
})

export const TestSummaryProvider = ({ children }) => {
    const [testId, setTestId] = useState('')
    const [testTakenCandidates, setTestTakenCandidates] = useState([])
    const [reviewedCandidates, setReviewedCandidates] = useState([])
    const [shortlistedCandidates, setShortlistedCandidates] = useState([])
    const [invitedCandidates, setInvitedCandidates] = useState([])
    const [testTakenCandidatesFilter, setTestTakenCandidatesFilter] = useState([])
    const [reviewedCandidatesFilter, setReviewedCandidatesFilter] = useState([])
    const [shortlistedCandidatesFilter, setShortlistedCandidatesFilter] = useState([])
    const [invitedCandidatesFilter, setInvitedCandidatesFilter] = useState([])
    const [loading, setLoading] = useState(false)
    const [allCandidates, setAllCandidates] = useState([])
    const [test, setTest] = useState(null)


    const handleTestId = (value) => {
        setTestId(value)
    }

    const filterCandidates = (page, value) => {
        if (page === "testtaken") {
            if (value === "recommended") {
                const sortedCandidates = [...testTakenCandidatesFilter].sort((a, b) => b.testScore - a.testScore);
                setTestTakenCandidates(sortedCandidates);
            } else if (value === "Greater than 75%") {
                const passingPercentage = 0.75;
                const passingScore = testTakenCandidatesFilter[0].testId.totalScore * passingPercentage;
                const filteredCandidates = testTakenCandidatesFilter.filter(candidate => candidate.testScore >= passingScore);
                setTestTakenCandidates(filteredCandidates);
            } else if (value === "latest") {
                const testTaken = allCandidates.filter((data) => data.status === 'appeared')
                setTestTakenCandidates(testTaken)
            } else if (value === "Greater than 50%") {
                const passingPercentage = 0.5;
                const passingScore = testTakenCandidatesFilter[0].testId.totalScore * passingPercentage;
                const filteredCandidates = testTakenCandidatesFilter.filter(candidate => candidate.testScore >= passingScore);
                setTestTakenCandidates(filteredCandidates);
            } else {
                const failingPercentage = 0.5;
                const passingScore = testTakenCandidatesFilter[0].testId.totalScore * failingPercentage;
                const filteredCandidates = testTakenCandidatesFilter.filter(candidate => candidate.testScore < passingScore);
                setTestTakenCandidates(filteredCandidates);
            }
        } else if (page === "reviewed") {
            if (value === "recommended") {
                const sortedCandidates = [...reviewedCandidatesFilter].sort((a, b) => b.testScore - a.testScore);
                setReviewedCandidates(sortedCandidates)
            } else if (value === "Greater than 75%") {
                const passingPercentage = 0.75;
                const passingScore = reviewedCandidatesFilter[0].testId.totalScore * passingPercentage;
                const filteredCandidates = reviewedCandidatesFilter.filter(candidate => candidate.testScore >= passingScore);
                setReviewedCandidates(filteredCandidates)
            } else if (value === "latest") {
                const reviewed = allCandidates.filter((data) => data.status === 'reviewed')
                setReviewedCandidates(reviewed)
            } else if (value === "Greater than 50%") {
                const passingPercentage = 0.5;
                const passingScore = reviewedCandidatesFilter[0].testId.totalScore * passingPercentage;
                const filteredCandidates = reviewedCandidatesFilter.filter(candidate => candidate.testScore >= passingScore);
                setReviewedCandidates(filteredCandidates)
            } else {
                const failingPercentage = 0.5;
                const passingScore = reviewedCandidatesFilter[0].testId.totalScore * failingPercentage;
                const filteredCandidates = reviewedCandidatesFilter.filter(candidate => candidate.testScore < passingScore);
                setReviewedCandidates(filteredCandidates)
            }
        } else {
            if (value === "recommended") {
                const sortedCandidates = [...shortlistedCandidatesFilter].sort((a, b) => b.testScore - a.testScore);
                setShortlistedCandidates(sortedCandidates)
            } else if (value === "Greater than 75%") {
                const passingPercentage = 0.75;
                const passingScore = shortlistedCandidatesFilter[0].testId.totalScore * passingPercentage;
                const filteredCandidates = shortlistedCandidatesFilter.filter(candidate => candidate.testScore >= passingScore);
                setShortlistedCandidates(filteredCandidates)
            } else if (value === "latest") {
                const shortlisted = allCandidates.filter((data) => data.status === 'shortlisted')
                setShortlistedCandidates(shortlisted)
            } else if (value === "Greater than 50%") {
                const passingPercentage = 0.5;
                const passingScore = shortlistedCandidatesFilter[0].testId.totalScore * passingPercentage;
                const filteredCandidates = shortlistedCandidatesFilter.filter(candidate => candidate.testScore >= passingScore);
                setShortlistedCandidates(filteredCandidates)
            } else {
                const failingPercentage = 0.5;
                const passingScore = shortlistedCandidatesFilter[0].testId.totalScore * failingPercentage;
                const filteredCandidates = shortlistedCandidatesFilter.filter(candidate => candidate.testScore < passingScore);
                setShortlistedCandidates(filteredCandidates)
            }
        }
    };


    const getCandidates = async (id) => {
        try {
            setAllCandidates([])
            setTestTakenCandidates([])
            setReviewedCandidates([])
            setShortlistedCandidates([])
            setInvitedCandidates([])
            setTestTakenCandidatesFilter([])
            setReviewedCandidatesFilter([])
            setShortlistedCandidatesFilter([])
            setInvitedCandidatesFilter([])
            setLoading(true)
            const token = getCookie('Xh7ERL0G')
            const response = await axios.get(`${backend_url}invites/getInvite/${id === undefined ? testId : id}`, { headers: { "token": token } })
            const testTaken = response.data.data.filter((data) => data.status === 'appeared')
            testTaken.sort((a, b) => b.testScore - a.testScore);
            const reviewed = response.data.data.filter((data) => data.status === 'reviewed')
            const shortlisted = response.data.data.filter((data) => data.status === 'shortlisted')
            shortlisted.sort((a, b) => b.testScore - a.testScore);
            const invited = response.data.data.filter((data) => data.status === 'invited' || data.status === 'shortlisted' || data.status === 'reviewed' || data.status === 'appeared' || data.status === 'rejected' || data.status === 'hired')
            setAllCandidates(response.data.data)
            setTestTakenCandidates(testTaken)
            setReviewedCandidates(reviewed)
            setShortlistedCandidates(shortlisted)
            setInvitedCandidates(invited)
            setTestTakenCandidatesFilter(testTaken)
            setReviewedCandidatesFilter(reviewed)
            setShortlistedCandidatesFilter(shortlisted)
            setInvitedCandidatesFilter(invited)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }


    const handleTest = (value) => {
        setTest(value)
    }


    const handleTestTakenCandidates = (value) => {
        setTestTakenCandidates(value)
    }
    const handleReviewedCandidates = (value) => {
        setReviewedCandidates(value)
    }
    const handleShortlistedCandidates = (value) => {
        setShortlistedCandidates(value)
    }

    const handleInvitedCandidates = (value) => {
        setInvitedCandidates(value)
    }



    const context = {
        testId: testId,
        testTakenCandidates: testTakenCandidates,
        reviewedCandidates: reviewedCandidates,
        shortlistedCandidates: shortlistedCandidates,
        invitedCandidates: invitedCandidates,
        loading: loading,
        test: test,

        handleTestId: handleTestId,
        getCandidates: getCandidates,
        handleTestTakenCandidates: handleTestTakenCandidates,
        handleReviewedCandidates: handleReviewedCandidates,
        handleShortlistedCandidates: handleShortlistedCandidates,
        handleInvitedCandidates: handleInvitedCandidates,
        filterCandidates: filterCandidates,
        handleTest: handleTest
    }

    return (
        <TestSummaryContext.Provider value={context}>{children}</TestSummaryContext.Provider>
    )
}

export default TestSummaryContext