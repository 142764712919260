import React, { useEffect, useState, useContext } from "react";
import "./index.css";
import {
  getMemberApi,
  deleteMemberApi,
  inviteMemberApi,
  getDepartmentApi,
} from "../../apis";
import SuperNavbar from "../../component/SuperNavbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ManageAccessSideBar from "../../component/ManageAccessSidebar";
import DeleteInvitePopup from "../../component/DeleteInvitePopup";
import EditInvitePopup from "../../component/EditInvitePopup";

function ManageAccess({ inviteHR, viewMembers, inviteMembers }) {
  const [deleteInvite, setDeleteInvite] = useState(false);
  const [editInvite, setEditInvite] = useState(false);
  const [data, setData] = useState([
    {
      fullName: "John Doe",
      email: "johndoe@example.com",
      departmentId: {
        department: "Sales",
      },
      access: "Admin",
    },
    {
      fullName: "Jane Smith",
      email: "janesmith@example.com",
      departmentId: {
        department: "Marketing",
      },
      access: "User",
    },
    {
      fullName: "Mike Johnson",
      email: "mikejohnson@example.com",
      departmentId: {
        department: "Finance",
      },
      access: "User",
    },
    {
      fullName: "Sarah Adams",
      email: "sarahadams@example.com",
      departmentId: {
        department: "Human Resources",
      },
      access: "Admin",
    },
    {
      fullName: "David Wilson",
      email: "davidwilson@example.com",
      departmentId: {
        department: "IT",
      },
      access: "User",
    },
  ]);

  const [loading, setloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  // const [errorMsg, setErrorMsg] = useState({
  const [errorName, setErrorName] = useState();
  const [errorEmail, setErrorEmail] = useState();
  const [errorDepartmentId, setErrorDepartmentId] = useState();
  const [errorAccess, setErrorAccess] = useState();
  // })
  const [department, setDepartment] = useState([
    {
      "Sr. No.": 1,
      department: "Marketing",
      "No Of Users": 30,
      "No Of Invites": 15,
      "Login Type": "Google",
      Settings: {
        Edit: true,
        Delete: true,
      },
    },
    {
      "Sr. No.": 2,
      department: "Finance",
      "No Of Users": 20,
      "No Of Invites": 8,
      "Login Type": "Email",
      Settings: {
        Edit: true,
        Delete: true,
      },
    },
    {
      "Sr. No.": 3,
      department: "IT",
      "No Of Users": 40,
      "No Of Invites": 12,
      "Login Type": "Google",
      Settings: {
        Edit: true,
        Delete: true,
      },
    },
    {
      "Sr. No.": 4,
      department: "Sales",
      "No Of Users": 35,
      "No Of Invites": 20,
      "Login Type": "Email",
      Settings: {
        Edit: true,
        Delete: true,
      },
    },
  ]);
  const [id, setId] = useState();

  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);

  const [openInviteMemberModal, setOpenInviteMemberModal] = useState(false);
  const [openInviteHRModal, setOpenInviteHRModal] = useState(false);

  const handleDeleteInvitePopup = (id) => {
    setDeleteInvite(false);
  };
  const handleEditInvitePopup = () => {
    setEditInvite(false);
  };

  const [fromData, setFromData] = useState({
    fullName: "",
    email: "",
    departmentId: "",
    access: "",
  });
  const handelChange = (e) => {
    setFromData({ ...fromData, [e.target.name]: e.target.value });

    setErrorName();
    setErrorEmail();
    setErrorDepartmentId();
    setErrorAccess();
  };

  const handelonSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;
    const regex = /^[a-zA-Z0-9 ]*$/;
    // Perform validation
    if (fromData.fullName == "") {
      setErrorName("Name is required!");
      isValid = false;
    } else {
      if (Number.isInteger(Number(fromData.fullName))) {
        setErrorName("Name can't be Integer Only");
        isValid = false;
      }
      if (!regex.test(fromData.fullName)) {
        setErrorName("Special characters are not allowed !");
        isValid = false;
      }
    }
    if (!fromData.email) {
      setErrorEmail("Email is required");
      isValid = false;
    } else if (!isValidEmail(fromData.email)) {
      setErrorEmail("Email is Invalid!");
      isValid = false;
    }
    if (fromData.departmentId == "") {
      setErrorDepartmentId("Department is required");
      isValid = false;
    }
    if (fromData.access == "") {
      setErrorAccess("Access is required");
      isValid = false;
    }

    if (isValid) {
      const res = await inviteMemberApi(fromData);
      console.log(res.status);
      if (res.status == 201) {
        memberData();
        setFromData({
          fullName: "",
          email: "",
          departmentId: "",
          access: "",
        });
        e.target.reset();
        toast(res.data.massage, {
          className: "toast-message",
        });
      }
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const memberData = async () => {
    try {
      const resDepartment = await getDepartmentApi();
      // setDepartment(resDepartment.data.data);
      const response = await getMemberApi(`page=${currentPage}&limit=10`); //page=${currentPage}&limit=10
      setData(response.data.data);

      setloading(false);

      setCurrentPage(parseInt(response.data.currentPage));
      setTotalPages(parseInt(response.data.totalPages));
      setTotalDocs(parseInt(response.data.totalDocs));
    } catch (error) {
      setTimeout(() => {
        setloading(false);
      }, 2000);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // searchInvites();
    }
  };

  useEffect(() => {
    memberData();
  }, [currentPage]);
  // console.log(!isValid, "-0");
  return (
    <div className="manage-access">
      <SuperNavbar active={"settings"} />
      {deleteInvite ? (
        <DeleteInvitePopup
          cancelButton={handleDeleteInvitePopup}
          onClickYes={async () => {
            const res = await deleteMemberApi(id._id);
            if (res.status == 200) {
              let list = data.filter((obj) => obj._id !== id._id);
              setData(list);
              memberData();
              setDeleteInvite(false);
              toast("Deleted successfully", {
                className: "toast-message",
              });
            }
          }}
        />
      ) : null}
      {editInvite ? (
        <EditInvitePopup
          cancelButton={handleEditInvitePopup}
          data={id}
          setEditInvite={setEditInvite}
          setData={setData}
          datamem={data}
          setCurrentPage={setCurrentPage}
        />
      ) : null}
      <div className="manage-access-container">
        <div className="manage-access-left">
          <ManageAccessSideBar
            active={"manageAccess"}
            activeSubMenu={
              viewMembers
                ? "viewMembers"
                : inviteHR
                ? "inviteHR"
                : "inviteMembers"
            }
            openSubMenu
          />
        </div>
        <div className="manage-access-right">
          {openInviteMemberModal && (
            <div className="invite-member-modal">
              <div className="manage-access-content">
                <div className="manage-access-header">
                  <div className="manage-access-title">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="24"
                        cy="24"
                        r="24"
                        fill="#00C49A"
                        fill-opacity="0.1"
                      />
                      <path
                        d="M29 22H32M32 22H35M32 22V19M32 22V25M13 32V31C13 29.1435 13.7375 27.363 15.0503 26.0503C16.363 24.7375 18.1435 24 20 24M20 24C21.8565 24 23.637 24.7375 24.9497 26.0503C26.2625 27.363 27 29.1435 27 31V32M20 24C21.0609 24 22.0783 23.5786 22.8284 22.8284C23.5786 22.0783 24 21.0609 24 20C24 18.9391 23.5786 17.9217 22.8284 17.1716C22.0783 16.4214 21.0609 16 20 16C18.9391 16 17.9217 16.4214 17.1716 17.1716C16.4214 17.9217 16 18.9391 16 20C16 21.0609 16.4214 22.0783 17.1716 22.8284C17.9217 23.5786 18.9391 24 20 24Z"
                        stroke="#00C49A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <div className="manage-access-name">
                      <span>Invite Members</span>
                      <p>Invite Member to organisation and assign roles</p>
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      setOpenInviteMemberModal(!openInviteMemberModal)
                    }
                    className="title"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.0032 13.6551L7.00642 8.65882L2.01082 13.6551C1.55102 14.1148 0.805358 14.1151 0.345266 13.6554C-0.114825 13.1957 -0.115113 12.4499 0.344547 11.99L5.34122 6.99369L0.344476 1.99628C-0.102534 1.534 -0.0962087 0.798602 0.358851 0.34437C0.813839 -0.110149 1.54922 -0.115324 2.01082 0.332296L7.00642 5.32856L12.0032 0.332296C12.4666 -0.103824 13.1925 -0.0928997 13.6426 0.35702C14.0927 0.806652 14.1041 1.53256 13.6684 1.99628L8.67162 6.99369L13.6684 11.99C14.1157 12.4519 14.1098 13.1873 13.6551 13.6419C13.2004 14.0967 12.4651 14.1024 12.0031 13.6551H12.0032Z"
                        fill="#99B2C6"
                      />
                    </svg>
                  </div>
                </div>

                <form onSubmit={(e) => handelonSubmit(e)}>
                  <div className="manage-access-form-container">
                    <div className="top-forms-container">
                      <div className="input-outer-container">
                        <span>
                          Name <span className="requeredclass">*</span>
                        </span>
                        <div className="input-inner-container name-input-box">
                          <svg
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_6565_5921)">
                              <path
                                d="M15 15.2449V13.7449C15 12.9492 14.6839 12.1862 14.1213 11.6236C13.5587 11.0609 12.7957 10.7449 12 10.7449H6C5.20435 10.7449 4.44129 11.0609 3.87868 11.6236C3.31607 12.1862 3 12.9492 3 13.7449V15.2449"
                                stroke="#333333"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9 7.74487C10.6569 7.74487 12 6.40173 12 4.74487C12 3.08802 10.6569 1.74487 9 1.74487C7.34315 1.74487 6 3.08802 6 4.74487C6 6.40173 7.34315 7.74487 9 7.74487Z"
                                stroke="#333333"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_6565_5921">
                                <rect
                                  width="18"
                                  height="18"
                                  fill="white"
                                  transform="translate(0 0.244873)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <input
                            type="text"
                            placeholder="Person Name Here"
                            name="fullName"
                            onChange={handelChange}
                          />
                        </div>
                        <span className="errorMsg">{errorName}</span>
                      </div>
                      <div className="input-outer-container">
                        <span>
                          Email <span className="requeredclass">*</span>
                        </span>
                        <div className="input-inner-container name-input-box">
                          <svg
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_6565_5929)">
                              <path
                                d="M3.48047 3.24487H15.4805C16.3055 3.24487 16.9805 3.91987 16.9805 4.74487V13.7449C16.9805 14.5699 16.3055 15.2449 15.4805 15.2449H3.48047C2.65547 15.2449 1.98047 14.5699 1.98047 13.7449V4.74487C1.98047 3.91987 2.65547 3.24487 3.48047 3.24487Z"
                                stroke="#333333"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M16.9805 4.74487L9.48047 9.99487L1.98047 4.74487"
                                stroke="#333333"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_6565_5929">
                                <rect
                                  width="18"
                                  height="18"
                                  fill="white"
                                  transform="translate(0.480469 0.244873)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <input
                            type="text"
                            placeholder="something@something.com"
                            name="email"
                            onChange={handelChange}
                          />
                        </div>
                        <span className="errorMsg">{errorEmail}</span>
                      </div>
                    </div>

                    <div className="bottom-forms-container">
                      <div className="input-outer-container">
                        <span>
                          Department <span className="requeredclass">*</span>
                        </span>
                        <div className="input-inner-container name-input-box">
                          <select
                            required
                            name="departmentId"
                            id=""
                            onChange={handelChange}
                          >
                            <option>Select</option>
                            {department.map((ele, i) => {
                              return (
                                <option key={i} value={ele._id}>
                                  {ele.department}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <span className="errorMsg">{errorDepartmentId}</span>
                      </div>
                      <div className="input-outer-container">
                        <span>
                          Access <span className="requeredclass">*</span>
                        </span>
                        <div className="input-inner-container name-input-box">
                          <select
                            required
                            name="access"
                            id=""
                            onChange={handelChange}
                          >
                            <option>Select</option>
                            <option value="Recruitert">Admin</option>
                            <option value="Recruitert">Recruiter</option>
                            <option value="Human resources">Interviwer</option>
                            <option value="Librarian">Librarian</option>
                          </select>
                        </div>
                        <span className="errorMsg">{errorAccess}</span>
                      </div>
                    </div>

                    <div className="send-invite-btn-container">
                      <button type="submit">
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="10.5" cy="10.2449" r="10" fill="white" />
                          <g clip-path="url(#clip0_6565_5953)">
                            <path
                              d="M13 9H14.5M14.5 9H16M14.5 9V7.5M14.5 9V10.5M5 14V13.5C5 12.5717 5.36875 11.6815 6.02513 11.0251C6.6815 10.3687 7.57174 10 8.5 10M8.5 10C9.42826 10 10.3185 10.3687 10.9749 11.0251C11.6313 11.6815 12 12.5717 12 13.5V14M8.5 10C9.03043 10 9.53914 9.78929 9.91421 9.41421C10.2893 9.03914 10.5 8.53043 10.5 8C10.5 7.46957 10.2893 6.96086 9.91421 6.58579C9.53914 6.21071 9.03043 6 8.5 6C7.96957 6 7.46086 6.21071 7.08579 6.58579C6.71071 6.96086 6.5 7.46957 6.5 8C6.5 8.53043 6.71071 9.03914 7.08579 9.41421C7.46086 9.78929 7.96957 10 8.5 10Z"
                              stroke="#FF6812"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6565_5953">
                              <rect
                                width="12"
                                height="12"
                                fill="white"
                                transform="translate(4.5 4)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        Send Invite
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}

          {openInviteHRModal && (
            <div className="invite-member-modal">
              <div className="manage-access-content">
                <div className="manage-access-header">
                  <div className="manage-access-title">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="24"
                        cy="24"
                        r="24"
                        fill="#00C49A"
                        fill-opacity="0.1"
                      />
                      <path
                        d="M29 22H32M32 22H35M32 22V19M32 22V25M13 32V31C13 29.1435 13.7375 27.363 15.0503 26.0503C16.363 24.7375 18.1435 24 20 24M20 24C21.8565 24 23.637 24.7375 24.9497 26.0503C26.2625 27.363 27 29.1435 27 31V32M20 24C21.0609 24 22.0783 23.5786 22.8284 22.8284C23.5786 22.0783 24 21.0609 24 20C24 18.9391 23.5786 17.9217 22.8284 17.1716C22.0783 16.4214 21.0609 16 20 16C18.9391 16 17.9217 16.4214 17.1716 17.1716C16.4214 17.9217 16 18.9391 16 20C16 21.0609 16.4214 22.0783 17.1716 22.8284C17.9217 23.5786 18.9391 24 20 24Z"
                        stroke="#00C49A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <div className="manage-access-name">
                      <span>Invite HR</span>
                      <p>Invite HR to organisation</p>
                    </div>
                  </div>
                  <div
                    onClick={() => setOpenInviteHRModal(!openInviteHRModal)}
                    className="title"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.0032 13.6551L7.00642 8.65882L2.01082 13.6551C1.55102 14.1148 0.805358 14.1151 0.345266 13.6554C-0.114825 13.1957 -0.115113 12.4499 0.344547 11.99L5.34122 6.99369L0.344476 1.99628C-0.102534 1.534 -0.0962087 0.798602 0.358851 0.34437C0.813839 -0.110149 1.54922 -0.115324 2.01082 0.332296L7.00642 5.32856L12.0032 0.332296C12.4666 -0.103824 13.1925 -0.0928997 13.6426 0.35702C14.0927 0.806652 14.1041 1.53256 13.6684 1.99628L8.67162 6.99369L13.6684 11.99C14.1157 12.4519 14.1098 13.1873 13.6551 13.6419C13.2004 14.0967 12.4651 14.1024 12.0031 13.6551H12.0032Z"
                        fill="#99B2C6"
                      />
                    </svg>
                  </div>
                </div>

                <form onSubmit={(e) => handelonSubmit(e)}>
                  <div className="manage-access-form-container">
                    <div className="top-forms-container">
                      <div className="input-outer-container">
                        <span>
                          Name <span className="requeredclass">*</span>
                        </span>
                        <div className="input-inner-container name-input-box">
                          <svg
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_6565_5921)">
                              <path
                                d="M15 15.2449V13.7449C15 12.9492 14.6839 12.1862 14.1213 11.6236C13.5587 11.0609 12.7957 10.7449 12 10.7449H6C5.20435 10.7449 4.44129 11.0609 3.87868 11.6236C3.31607 12.1862 3 12.9492 3 13.7449V15.2449"
                                stroke="#333333"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9 7.74487C10.6569 7.74487 12 6.40173 12 4.74487C12 3.08802 10.6569 1.74487 9 1.74487C7.34315 1.74487 6 3.08802 6 4.74487C6 6.40173 7.34315 7.74487 9 7.74487Z"
                                stroke="#333333"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_6565_5921">
                                <rect
                                  width="18"
                                  height="18"
                                  fill="white"
                                  transform="translate(0 0.244873)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <input
                            type="text"
                            placeholder="Person Name Here"
                            name="fullName"
                            onChange={handelChange}
                          />
                        </div>
                        <span className="errorMsg">{errorName}</span>
                      </div>
                      <div className="input-outer-container">
                        <span>
                          Email <span className="requeredclass">*</span>
                        </span>
                        <div className="input-inner-container name-input-box">
                          <svg
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_6565_5929)">
                              <path
                                d="M3.48047 3.24487H15.4805C16.3055 3.24487 16.9805 3.91987 16.9805 4.74487V13.7449C16.9805 14.5699 16.3055 15.2449 15.4805 15.2449H3.48047C2.65547 15.2449 1.98047 14.5699 1.98047 13.7449V4.74487C1.98047 3.91987 2.65547 3.24487 3.48047 3.24487Z"
                                stroke="#333333"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M16.9805 4.74487L9.48047 9.99487L1.98047 4.74487"
                                stroke="#333333"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_6565_5929">
                                <rect
                                  width="18"
                                  height="18"
                                  fill="white"
                                  transform="translate(0.480469 0.244873)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <input
                            type="text"
                            placeholder="something@something.com"
                            name="email"
                            onChange={handelChange}
                          />
                        </div>
                        <span className="errorMsg">{errorEmail}</span>
                      </div>
                    </div>

                    <div className="send-invite-btn-container">
                      <button type="submit">
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="10.5" cy="10.2449" r="10" fill="white" />
                          <g clip-path="url(#clip0_6565_5953)">
                            <path
                              d="M13 9H14.5M14.5 9H16M14.5 9V7.5M14.5 9V10.5M5 14V13.5C5 12.5717 5.36875 11.6815 6.02513 11.0251C6.6815 10.3687 7.57174 10 8.5 10M8.5 10C9.42826 10 10.3185 10.3687 10.9749 11.0251C11.6313 11.6815 12 12.5717 12 13.5V14M8.5 10C9.03043 10 9.53914 9.78929 9.91421 9.41421C10.2893 9.03914 10.5 8.53043 10.5 8C10.5 7.46957 10.2893 6.96086 9.91421 6.58579C9.53914 6.21071 9.03043 6 8.5 6C7.96957 6 7.46086 6.21071 7.08579 6.58579C6.71071 6.96086 6.5 7.46957 6.5 8C6.5 8.53043 6.71071 9.03914 7.08579 9.41421C7.46086 9.78929 7.96957 10 8.5 10Z"
                              stroke="#FF6812"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6565_5953">
                              <rect
                                width="12"
                                height="12"
                                fill="white"
                                transform="translate(4.5 4)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        Send Invite
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}

          <div className="manage-access-content">
            <div className="manage-access-table-content">
              <div className="table1-heading">
                <div className="table-header-container">
                  <span>Manage Access</span>
                  <div>
                    <div className="candidate-input">
                      <svg
                        style={{ cursor: "pointer" }}
                        width="12"
                        height="12"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M15.0225 16.0985L11.5314 12.6066C8.80893 14.7527 4.88556 14.4049 2.58295 11.8136C0.280299 9.22218 0.39631 5.28521 2.84757 2.83395C5.29883 0.38264 9.23585 0.266628 11.8272 2.56924C14.4186 4.87189 14.7663 8.79521 12.6203 11.5177L16.1114 15.0081C16.3365 15.1959 16.4362 15.4947 16.3689 15.78C16.3017 16.0653 16.079 16.2881 15.7937 16.3556C15.5085 16.423 15.2096 16.3235 15.0217 16.0985H15.0225ZM2.46881 7.4614C2.4681 9.4746 3.67365 11.2929 5.52884 12.0762C7.38399 12.8595 9.52788 12.4554 10.9706 11.0505C10.9848 11.0332 10.9999 11.0168 11.0159 11.0013C11.0319 10.9859 11.0482 10.9709 11.0647 10.9564C12.6401 9.33856 12.9379 6.86632 11.7917 4.92058C10.6454 2.97484 8.33872 2.03697 6.15992 2.63078C3.98111 3.22458 2.46928 5.20315 2.46876 7.4614H2.46881Z"
                          fill="#606D77"
                        />
                      </svg>
                      <input
                        onKeyDown={handleKeyDown}
                        // onChange={(e) => {
                        //   setSearch(e.target.value);
                        //   searchInvitesOnChange(e);
                        // }}
                        // value={search}
                        placeholder="Search member"
                      />
                      {search !== "" ? (
                        <svg
                          onClick={() => {
                            setSearch("");
                          }}
                          style={{ right: 50, cursor: "pointer" }}
                          width="12"
                          height="12"
                          viewBox="0 0 14 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.0032 13.9813L7.00642 8.985L2.01082 13.9813C1.55102 14.441 0.805358 14.4412 0.345266 13.9815C-0.114825 13.5219 -0.115113 12.7761 0.344547 12.3161L5.34122 7.31986L0.344476 2.32245C-0.102534 1.86017 -0.0962087 1.12477 0.358851 0.670542C0.813839 0.216023 1.54922 0.210848 2.01082 0.658468L7.00642 5.65473L12.0032 0.658468C12.4666 0.222348 13.1925 0.233272 13.6426 0.683192C14.0927 1.13282 14.1041 1.85873 13.6684 2.32245L8.67162 7.31986L13.6684 12.3161C14.1157 12.7781 14.1098 13.5135 13.6551 13.968C13.2004 14.4228 12.4651 14.4286 12.0031 13.9813H12.0032Z"
                            fill="#99B2C6"
                          />
                        </svg>
                      ) : (
                        <></>
                      )}
                    </div>
                    {search !== "" ? (
                      <div className="search-container">
                        {searchData.length === 0 && searchLoading === false ? (
                          <p className="no-data">No data found.</p>
                        ) : null}
                        {searchLoading ? (
                          <p className="no-data">Loading...</p>
                        ) : (
                          searchData.map((data, index) => {
                            return (
                              <span
                                key={index}
                                onClick={() => {
                                  // setInvites([data]);
                                  setTotalDocs(1);
                                }}
                              >
                                {data.candidateName}
                              </span>
                            );
                          })
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* <div className="pagination">
                  <button
                    onClick={() => {
                      if (currentPage > 1) {
                        if (currentPage > 1) {
                          if (loading === false) {
                            setCurrentPage(currentPage - 1);
                          }
                        }
                      }
                    }}
                  >
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L2.41421 6Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                  <span>
                    {totalPages === 0 ? 0 : currentPage}/{totalPages}
                  </span>
                  <button
                    onClick={() => {
                      setCurrentPage(
                        currentPage === totalPages || totalPages === 0
                          ? currentPage
                          : loading === false
                          ? currentPage + 1
                          : currentPage
                      );
                    }}
                  >
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div> */}

                <div className="send-invite-btn-container">
                  <button
                    className="inviteMember"
                    onClick={() =>
                      setOpenInviteMemberModal(!openInviteMemberModal)
                    }
                  >
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="10.5" cy="10.2449" r="10" fill="white" />
                      <g clip-path="url(#clip0_6565_5953)">
                        <path
                          d="M13 9H14.5M14.5 9H16M14.5 9V7.5M14.5 9V10.5M5 14V13.5C5 12.5717 5.36875 11.6815 6.02513 11.0251C6.6815 10.3687 7.57174 10 8.5 10M8.5 10C9.42826 10 10.3185 10.3687 10.9749 11.0251C11.6313 11.6815 12 12.5717 12 13.5V14M8.5 10C9.03043 10 9.53914 9.78929 9.91421 9.41421C10.2893 9.03914 10.5 8.53043 10.5 8C10.5 7.46957 10.2893 6.96086 9.91421 6.58579C9.53914 6.21071 9.03043 6 8.5 6C7.96957 6 7.46086 6.21071 7.08579 6.58579C6.71071 6.96086 6.5 7.46957 6.5 8C6.5 8.53043 6.71071 9.03914 7.08579 9.41421C7.46086 9.78929 7.96957 10 8.5 10Z"
                          stroke="#FF6812"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6565_5953">
                          <rect
                            width="12"
                            height="12"
                            fill="white"
                            transform="translate(4.5 4)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    Invite Member
                  </button>

                  <button
                    className="inviteHR"
                    onClick={() => {
                      setOpenInviteHRModal(!openInviteHRModal);
                    }}
                  >
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="10.5" cy="10.2449" r="10" fill="white" />
                      <g clip-path="url(#clip0_6565_5953)">
                        <path
                          d="M13 9H14.5M14.5 9H16M14.5 9V7.5M14.5 9V10.5M5 14V13.5C5 12.5717 5.36875 11.6815 6.02513 11.0251C6.6815 10.3687 7.57174 10 8.5 10M8.5 10C9.42826 10 10.3185 10.3687 10.9749 11.0251C11.6313 11.6815 12 12.5717 12 13.5V14M8.5 10C9.03043 10 9.53914 9.78929 9.91421 9.41421C10.2893 9.03914 10.5 8.53043 10.5 8C10.5 7.46957 10.2893 6.96086 9.91421 6.58579C9.53914 6.21071 9.03043 6 8.5 6C7.96957 6 7.46086 6.21071 7.08579 6.58579C6.71071 6.96086 6.5 7.46957 6.5 8C6.5 8.53043 6.71071 9.03914 7.08579 9.41421C7.46086 9.78929 7.96957 10 8.5 10Z"
                          stroke="#00C49A"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6565_5953">
                          <rect
                            width="12"
                            height="12"
                            fill="white"
                            transform="translate(4.5 4)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    Invite HR
                  </button>
                </div>
              </div>
              <table cellSpacing={0}>
                <tr>
                  <th>Name</th>
                  <th>Email ID</th>
                  <th>Department</th>
                  <th>Access</th>
                  <th>Settings</th>
                </tr>
                {data.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.fullName}</td>
                      <td>{item.email}</td>
                      <td>{item.departmentId?.department}</td>
                      <td>{item.access}</td>
                      <td>
                        <label class="switch" data-tip="test off/on">
                          <input type="checkbox" />
                          <span class="slider round"></span>
                          <div class="tooltip" data-tip="test off/on">
                            Access On/Off
                          </div>
                        </label>
                        <button
                          onClick={() => {
                            setEditInvite(true);
                            setId(item);
                          }}
                        >
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.867188 8.87124H1.41719L6.95469 3.33374L6.40469 2.78374L0.867188 8.32124V8.87124ZM8.54219 2.79624L6.94219 1.19624L7.46719 0.67124C7.60885 0.529574 7.78385 0.45874 7.99219 0.45874C8.20052 0.45874 8.37552 0.529574 8.51719 0.67124L9.06719 1.22124C9.20885 1.36291 9.27969 1.53791 9.27969 1.74624C9.27969 1.95457 9.20885 2.12957 9.06719 2.27124L8.54219 2.79624ZM8.01719 3.32124L1.71719 9.62124H0.117188V8.02124L6.41719 1.72124L8.01719 3.32124ZM6.67969 3.05874L6.40469 2.78374L6.95469 3.33374L6.67969 3.05874Z"
                              fill="#333333"
                            />
                          </svg>
                          Edit
                        </button>
                        {item.access == "superadmin" ? (
                          <button
                            onClick={() => {
                              setDeleteInvite(true);
                              setId(item);
                            }}
                            disabled
                            style={{ cursor: "not-allowed", width: "120px" }}
                          >
                            Reset Password
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setDeleteInvite(true);
                              setId(item);
                            }}
                            style={{ width: "120px" }}
                          >
                            Reset Password
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </table>
              <div className="table-footer">
                <div className="pagination">
                  <button
                    onClick={() => {
                      if (currentPage > 1) {
                        if (currentPage > 1) {
                          if (loading === false) {
                            setCurrentPage(currentPage - 1);
                          }
                        }
                      }
                    }}
                  >
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L2.41421 6Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                  <span>
                    {totalPages === 0 ? 0 : currentPage}/{totalPages}
                  </span>
                  <button
                    onClick={() => {
                      setCurrentPage(
                        currentPage === totalPages || totalPages === 0
                          ? currentPage
                          : loading === false
                          ? currentPage + 1
                          : currentPage
                      );
                    }}
                  >
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageAccess;
