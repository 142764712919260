import React, { useState, useContext, useEffect } from "react";
import "./index.css";
import SuperNavbar from "../../component/SuperNavbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ManageAccessSideBar from "../../component/ManageAccessSidebar";
import CreateDepartmentModal from "../../component/CreateDepartmentModal";
import {
  deleteDepartmentApi,
  addDepartmentApi,
  getDepartmentApi,
  UpdateDepartmentStatusApi,
} from "../../apis";
import DeletePopup from "../../component/DeletePopup";
import { useNavigate } from "react-router-dom";
import InviteInterviewBoardModal from "../../component/InviteInterviewBoardModal";

const ManageDepartments = () => {
  let navigate = useNavigate();
  const [editDepartment, setEditDepartment] = useState(false);
  const [depMemCount, setDepMemCount] = useState([]);
  const [openDeparmentModal, setOpenDeparmentModal] = useState(false);
  const [departmentName, setDepartmentName] = useState();
  const [openInterviewBoardModal, setOpenInterviewBoardModal] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [id, setId] = useState();
  const [loading, setloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [department, setDepartment] = useState([]);
  const [reload, setReload] = useState(false);

  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);

  const memberData = async () => {
    try {
      const resDepartment = await getDepartmentApi(
        `page=${currentPage}&limit=10`
      );

      setDepartment(resDepartment.data.data);
      setDepMemCount(resDepartment.data.countNo);
      setloading(false);
      setCurrentPage(parseInt(resDepartment.data.currentPage));
      setTotalPages(parseInt(resDepartment.data.totalPages));
      setTotalDocs(parseInt(resDepartment.data.totalDocs));
    } catch (error) {
      setTimeout(() => {
        setloading(false);
      }, 2000);
    }
  };
  const result = (id) => {
    const aa = depMemCount?.filter((item) => item?._id === id);
    return aa[0]?.count;
  };
  useEffect(() => {
    memberData();
  }, [reload, currentPage]);

  const handleDelete = () => {
    deleteDepartmentApi(id).then((res) => {
      console.log(res.data.data.acknowledged);
      if (res.data.data.acknowledged) {
        toast.success("Department deleted successfully");
        setReload(!reload);
        setDeletePopup(false);
      }
    });
  };

  const changeStatus = (e, depid) => {
    const id = depid;
    console.log(id);
    const status = e.target.checked ? "active" : "inactive";

    UpdateDepartmentStatusApi(id, { status: status }).then((res) => {
      if (res) {
        toast.success("Status updated successfully");
        setReload(!reload);
      }
    });
  };

  return (
    <div className="manage-department">
      <SuperNavbar active={"settings"} />

      {openDeparmentModal && (
        <CreateDepartmentModal
          closeModal={() => setOpenDeparmentModal(false)}
          reload={() => setReload(!reload)}
        />
      )}

      {deletePopup && (
        <DeletePopup
          cancelButton={() => setDeletePopup(false)}
          handleDelete={handleDelete}
        />
      )}

      {editDepartment && (
        <CreateDepartmentModal
          departmentName={departmentName}
          closeModal={() => setEditDepartment(false)}
          reload={() => setReload(!reload)}
          edit
        />
      )}

      {openInterviewBoardModal && (
        <InviteInterviewBoardModal
          closeModal={() => setOpenInterviewBoardModal(false)}
        />
      )}

      <div className="manage-access-container">
        <div className="manage-access-left">
          <ManageAccessSideBar active={"managedepartment"} />
        </div>
        <div className="manage-access-right">
          <div className="manage-access-content">
            {/* <div className="manage-access-header">
              <div className="manage-access-title">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="24"
                    cy="24"
                    r="24"
                    fill="#00C49A"
                    fill-opacity="0.1"
                  />
                  <path
                    d="M15.5 33C15.1 33 14.75 32.85 14.45 32.55C14.15 32.25 14 31.9 14 31.5V19.275C14 18.875 14.15 18.525 14.45 18.225C14.75 17.925 15.1 17.775 15.5 17.775H20.25V15.5C20.25 15.1 20.4 14.75 20.7 14.45C21 14.15 21.35 14 21.75 14H26.25C26.65 14 27 14.15 27.3 14.45C27.6 14.75 27.75 15.1 27.75 15.5V17.775H32.5C32.9 17.775 33.25 17.925 33.55 18.225C33.85 18.525 34 18.875 34 19.275V31.5C34 31.9 33.85 32.25 33.55 32.55C33.25 32.85 32.9 33 32.5 33H15.5ZM21.75 17.775H26.25V15.5H21.75V17.775ZM32.5 26.725H26.275V28.225H21.775V26.725H15.5V31.5H32.5V26.725ZM23.275 26.725H24.775V25.225H23.275V26.725ZM15.5 25.225H21.775V23.725H26.275V25.225H32.5V19.275H15.5V25.225Z"
                    fill="#00C49A"
                  />
                </svg>

                <div className="manage-access-name">
                  <span>Create New Department</span>
                  <p>
                    Create departments for your organisation and assign invites
                  </p>
                </div>
              </div>
            </div> */}
            {/* <form onSubmit={(e) => handelonSubmit(e)}>
              <div className="manage-access-form-container">
                <div className="top-forms-container">
                  <div className="input-outer-container">
                    <span>
                      Department Name <span className="requeredclass">*</span>
                    </span>
                    <div className="input-inner-container name-input-box">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.625 15.75C2.325 15.75 2.0625 15.6375 1.8375 15.4125C1.6125 15.1875 1.5 14.925 1.5 14.625V5.45625C1.5 5.15625 1.6125 4.89375 1.8375 4.66875C2.0625 4.44375 2.325 4.33125 2.625 4.33125H6.1875V2.625C6.1875 2.325 6.3 2.0625 6.525 1.8375C6.75 1.6125 7.0125 1.5 7.3125 1.5H10.6875C10.9875 1.5 11.25 1.6125 11.475 1.8375C11.7 2.0625 11.8125 2.325 11.8125 2.625V4.33125H15.375C15.675 4.33125 15.9375 4.44375 16.1625 4.66875C16.3875 4.89375 16.5 5.15625 16.5 5.45625V14.625C16.5 14.925 16.3875 15.1875 16.1625 15.4125C15.9375 15.6375 15.675 15.75 15.375 15.75H2.625ZM7.3125 4.33125H10.6875V2.625H7.3125V4.33125ZM15.375 11.0438H10.7063V12.1688H7.33125V11.0438H2.625V14.625H15.375V11.0438ZM8.45625 11.0438H9.58125V9.91875H8.45625V11.0438ZM2.625 9.91875H7.33125V8.79375H10.7063V9.91875H15.375V5.45625H2.625V9.91875Z"
                          fill="black"
                        />
                      </svg>
                      <input
                        type="text"
                        placeholder="Enter Deparment Name"
                        name="department"
                        onChange={handelChange}
                      />
                    </div>
                    <span className="errorMsg">{errorMsg}</span>
                  </div>

                  <div className="send-invite-btn-container">
                    <button type="Submit">
                      <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10.5" cy="10.2449" r="10" fill="white" />
                        <path
                          d="M6.25 14.5C6.05 14.5 5.875 14.425 5.725 14.275C5.575 14.125 5.5 13.95 5.5 13.75V7.6375C5.5 7.4375 5.575 7.2625 5.725 7.1125C5.875 6.9625 6.05 6.8875 6.25 6.8875H8.625V5.75C8.625 5.55 8.7 5.375 8.85 5.225C9 5.075 9.175 5 9.375 5H11.625C11.825 5 12 5.075 12.15 5.225C12.3 5.375 12.375 5.55 12.375 5.75V6.8875H14.75C14.95 6.8875 15.125 6.9625 15.275 7.1125C15.425 7.2625 15.5 7.4375 15.5 7.6375V13.75C15.5 13.95 15.425 14.125 15.275 14.275C15.125 14.425 14.95 14.5 14.75 14.5H6.25ZM9.375 6.8875H11.625V5.75H9.375V6.8875ZM14.75 11.3625H11.6375V12.1125H9.3875V11.3625H6.25V13.75H14.75V11.3625ZM10.1375 11.3625H10.8875V10.6125H10.1375V11.3625ZM6.25 10.6125H9.3875V9.8625H11.6375V10.6125H14.75V7.6375H6.25V10.6125Z"
                          fill="#FF6812"
                        />
                      </svg>
                      Create Department
                    </button>
                  </div>
                </div>
              </div>
            </form> */}

            <div className="manage-access-table-content">
              <div className="table1-heading">
                <div className="table-header-container">
                  <span>Manage Department</span>
                  <div>
                    <div className="candidate-input">
                      <svg
                        style={{ cursor: "pointer" }}
                        width="12"
                        height="12"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M15.0225 16.0985L11.5314 12.6066C8.80893 14.7527 4.88556 14.4049 2.58295 11.8136C0.280299 9.22218 0.39631 5.28521 2.84757 2.83395C5.29883 0.38264 9.23585 0.266628 11.8272 2.56924C14.4186 4.87189 14.7663 8.79521 12.6203 11.5177L16.1114 15.0081C16.3365 15.1959 16.4362 15.4947 16.3689 15.78C16.3017 16.0653 16.079 16.2881 15.7937 16.3556C15.5085 16.423 15.2096 16.3235 15.0217 16.0985H15.0225ZM2.46881 7.4614C2.4681 9.4746 3.67365 11.2929 5.52884 12.0762C7.38399 12.8595 9.52788 12.4554 10.9706 11.0505C10.9848 11.0332 10.9999 11.0168 11.0159 11.0013C11.0319 10.9859 11.0482 10.9709 11.0647 10.9564C12.6401 9.33856 12.9379 6.86632 11.7917 4.92058C10.6454 2.97484 8.33872 2.03697 6.15992 2.63078C3.98111 3.22458 2.46928 5.20315 2.46876 7.4614H2.46881Z"
                          fill="#606D77"
                        />
                      </svg>
                      <input
                        // onKeyDown={handleKeyDown}
                        // onChange={(e) => {
                        //   setSearch(e.target.svalue);
                        //   searchInvitesOnChange(e);
                        // }}
                        // value={search}
                        placeholder="Search department"
                      />
                      {search !== "" ? (
                        <svg
                          onClick={() => {
                            setSearch("");
                          }}
                          style={{ right: 50, cursor: "pointer" }}
                          width="12"
                          height="12"
                          viewBox="0 0 14 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.0032 13.9813L7.00642 8.985L2.01082 13.9813C1.55102 14.441 0.805358 14.4412 0.345266 13.9815C-0.114825 13.5219 -0.115113 12.7761 0.344547 12.3161L5.34122 7.31986L0.344476 2.32245C-0.102534 1.86017 -0.0962087 1.12477 0.358851 0.670542C0.813839 0.216023 1.54922 0.210848 2.01082 0.658468L7.00642 5.65473L12.0032 0.658468C12.4666 0.222348 13.1925 0.233272 13.6426 0.683192C14.0927 1.13282 14.1041 1.85873 13.6684 2.32245L8.67162 7.31986L13.6684 12.3161C14.1157 12.7781 14.1098 13.5135 13.6551 13.968C13.2004 14.4228 12.4651 14.4286 12.0031 13.9813H12.0032Z"
                            fill="#99B2C6"
                          />
                        </svg>
                      ) : (
                        <></>
                      )}
                    </div>
                    {search !== "" ? (
                      <div className="search-container">
                        {searchData.length === 0 && searchLoading === false ? (
                          <p className="no-data">No data found.</p>
                        ) : null}
                        {searchLoading ? (
                          <p className="no-data">Loading...</p>
                        ) : (
                          searchData.map((data, index) => {
                            return (
                              <span
                                key={index}
                                onClick={() => {
                                  // setInvites([data]);
                                  setTotalDocs(1);
                                }}
                              >
                                {data.candidateName}
                              </span>
                            );
                          })
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* <div className="pagination">
                  <button
                    onClick={() => {
                      if (currentPage > 1) {
                        if (currentPage > 1) {
                          if (loading === false) {
                            setCurrentPage(currentPage - 1);
                          }
                        }
                      }
                    }}
                  >
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L2.41421 6Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                  <span>
                    {totalPages === 0 ? 0 : currentPage}/{totalPages}
                  </span>
                  <button
                    onClick={() => {
                      setCurrentPage(
                        currentPage === totalPages || totalPages === 0
                          ? currentPage
                          : loading === false
                          ? currentPage + 1
                          : currentPage
                      );
                    }}
                  >
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div> */}
                <div className="send-invite-btn-container">
                  <button onClick={() => setOpenDeparmentModal(true)}>
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="10.5" cy="10.2449" r="10" fill="white" />
                      <path
                        d="M6.25 14.5C6.05 14.5 5.875 14.425 5.725 14.275C5.575 14.125 5.5 13.95 5.5 13.75V7.6375C5.5 7.4375 5.575 7.2625 5.725 7.1125C5.875 6.9625 6.05 6.8875 6.25 6.8875H8.625V5.75C8.625 5.55 8.7 5.375 8.85 5.225C9 5.075 9.175 5 9.375 5H11.625C11.825 5 12 5.075 12.15 5.225C12.3 5.375 12.375 5.55 12.375 5.75V6.8875H14.75C14.95 6.8875 15.125 6.9625 15.275 7.1125C15.425 7.2625 15.5 7.4375 15.5 7.6375V13.75C15.5 13.95 15.425 14.125 15.275 14.275C15.125 14.425 14.95 14.5 14.75 14.5H6.25ZM9.375 6.8875H11.625V5.75H9.375V6.8875ZM14.75 11.3625H11.6375V12.1125H9.3875V11.3625H6.25V13.75H14.75V11.3625ZM10.1375 11.3625H10.8875V10.6125H10.1375V11.3625ZM6.25 10.6125H9.3875V9.8625H11.6375V10.6125H14.75V7.6375H6.25V10.6125Z"
                        fill="#FF6812"
                      />
                    </svg>
                    Create Department
                  </button>
                </div>
              </div>
              <table cellSpacing={0}>
                <tr>
                  <th>Sr. No.</th>
                  <th>Department</th>
                  <th>No Of Users</th>
                  <th>No Of Invites</th>
                  <th>Interview Board Login</th>
                  <th>Settings</th>
                </tr>
                {/* <tr>
                                    <td>1</td>
                                    <td>Marketing</td>
                                    <td>
                                        <button>
                                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_6599_7926)">
                                                    <path d="M5.2875 7.90508L8.75 4.44258L8.2125 3.90508L5.2875 6.83008L3.775 5.31758L3.25 5.84258L5.2875 7.90508ZM2.25 10.5176C2.04375 10.5176 1.86719 10.4441 1.72031 10.2973C1.57344 10.1504 1.5 9.97383 1.5 9.76758V2.26758C1.5 2.06133 1.57344 1.88477 1.72031 1.73789C1.86719 1.59102 2.04375 1.51758 2.25 1.51758H4.8125C4.85417 1.22591 4.9875 0.986328 5.2125 0.798828C5.4375 0.611328 5.7 0.517578 6 0.517578C6.3 0.517578 6.5625 0.611328 6.7875 0.798828C7.0125 0.986328 7.14583 1.22591 7.1875 1.51758H9.75C9.95625 1.51758 10.1328 1.59102 10.2797 1.73789C10.4266 1.88477 10.5 2.06133 10.5 2.26758V9.76758C10.5 9.97383 10.4266 10.1504 10.2797 10.2973C10.1328 10.4441 9.95625 10.5176 9.75 10.5176H2.25ZM2.25 9.76758H9.75V2.26758H2.25V9.76758ZM6 2.05508C6.11667 2.05508 6.21875 2.01133 6.30625 1.92383C6.39375 1.83633 6.4375 1.73424 6.4375 1.61758C6.4375 1.50091 6.39375 1.39883 6.30625 1.31133C6.21875 1.22383 6.11667 1.18008 6 1.18008C5.88333 1.18008 5.78125 1.22383 5.69375 1.31133C5.60625 1.39883 5.5625 1.50091 5.5625 1.61758C5.5625 1.73424 5.60625 1.83633 5.69375 1.92383C5.78125 2.01133 5.88333 2.05508 6 2.05508Z" fill="white" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_6599_7926">
                                                        <rect width="12" height="12" fill="white" transform="translate(0 0.0175781)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            Assign
                                        </button>
                                    </td>
                                    <td>
                                        <button>
                                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_6599_7926)">
                                                    <path d="M5.2875 7.90508L8.75 4.44258L8.2125 3.90508L5.2875 6.83008L3.775 5.31758L3.25 5.84258L5.2875 7.90508ZM2.25 10.5176C2.04375 10.5176 1.86719 10.4441 1.72031 10.2973C1.57344 10.1504 1.5 9.97383 1.5 9.76758V2.26758C1.5 2.06133 1.57344 1.88477 1.72031 1.73789C1.86719 1.59102 2.04375 1.51758 2.25 1.51758H4.8125C4.85417 1.22591 4.9875 0.986328 5.2125 0.798828C5.4375 0.611328 5.7 0.517578 6 0.517578C6.3 0.517578 6.5625 0.611328 6.7875 0.798828C7.0125 0.986328 7.14583 1.22591 7.1875 1.51758H9.75C9.95625 1.51758 10.1328 1.59102 10.2797 1.73789C10.4266 1.88477 10.5 2.06133 10.5 2.26758V9.76758C10.5 9.97383 10.4266 10.1504 10.2797 10.2973C10.1328 10.4441 9.95625 10.5176 9.75 10.5176H2.25ZM2.25 9.76758H9.75V2.26758H2.25V9.76758ZM6 2.05508C6.11667 2.05508 6.21875 2.01133 6.30625 1.92383C6.39375 1.83633 6.4375 1.73424 6.4375 1.61758C6.4375 1.50091 6.39375 1.39883 6.30625 1.31133C6.21875 1.22383 6.11667 1.18008 6 1.18008C5.88333 1.18008 5.78125 1.22383 5.69375 1.31133C5.60625 1.39883 5.5625 1.50091 5.5625 1.61758C5.5625 1.73424 5.60625 1.83633 5.69375 1.92383C5.78125 2.01133 5.88333 2.05508 6 2.05508Z" fill="white" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_6599_7926">
                                                        <rect width="12" height="12" fill="white" transform="translate(0 0.0175781)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            Assign
                                        </button>
                                    </td>
                                    <td>

                                        <button onClick={() => {
                                            setDeleteInvite(true)
                                        }}>
                                            <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.87969 9.12134C1.67344 9.12134 1.49688 9.0479 1.35 8.90103C1.20313 8.75415 1.12969 8.57759 1.12969 8.37134V1.24634H0.617188V0.496338H2.96719V0.121338H6.26719V0.496338H8.61719V1.24634H8.10469V8.37134C8.10469 8.57134 8.02969 8.74634 7.87969 8.89634C7.72969 9.04634 7.55469 9.12134 7.35469 9.12134H1.87969ZM7.35469 1.24634H1.87969V8.37134H7.35469V1.24634ZM3.20469 7.29634H3.95469V2.30884H3.20469V7.29634ZM5.27969 7.29634H6.02969V2.30884H5.27969V7.29634Z" fill="#333333" />
                                            </svg>
                                            Delete
                                        </button>
                                    </td>
                                </tr> */}
                {department.map((ele, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{ele.department}</td>
                      <td>
                        {result(ele?._id) == undefined ? (
                          <button onClick={() => navigate("/manageaccess")}>
                            <svg
                              width="12"
                              height="10"
                              viewBox="0 0 12 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.5 4.01758H10M10 4.01758H11.5M10 4.01758V2.51758M10 4.01758V5.51758M0.5 9.01758V8.51758C0.5 7.58932 0.868749 6.69908 1.52513 6.0427C2.1815 5.38633 3.07174 5.01758 4 5.01758M4 5.01758C4.92826 5.01758 5.8185 5.38633 6.47487 6.0427C7.13125 6.69908 7.5 7.58932 7.5 8.51758V9.01758M4 5.01758C4.53043 5.01758 5.03914 4.80686 5.41421 4.43179C5.78929 4.05672 6 3.54801 6 3.01758C6 2.48715 5.78929 1.97844 5.41421 1.60336C5.03914 1.22829 4.53043 1.01758 4 1.01758C3.46957 1.01758 2.96086 1.22829 2.58579 1.60336C2.21071 1.97844 2 2.48715 2 3.01758C2 3.54801 2.21071 4.05672 2.58579 4.43179C2.96086 4.80686 3.46957 5.01758 4 5.01758Z"
                                stroke="white"
                                stroke-width="0.75"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Assign
                          </button>
                        ) : (
                          result(ele?._id)
                        )}
                      </td>
                      <td>
                        {ele.inviteAssigned > 0 ? (
                          ele.inviteAssigned
                        ) : (
                          <button onClick={() => navigate("/manageinvite")}>
                            <svg
                              width="10"
                              height="11"
                              viewBox="0 0 10 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_6980_3402)">
                                <path
                                  d="M9.16732 0.85083L4.58398 5.43416"
                                  stroke="white"
                                  stroke-width="0.75"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M9.16732 0.85083L6.25065 9.18416L4.58398 5.43416L0.833984 3.7675L9.16732 0.85083Z"
                                  stroke="white"
                                  stroke-width="0.75"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_6980_3402">
                                  <rect
                                    width="10"
                                    height="10"
                                    fill="white"
                                    transform="translate(0 0.0175781)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            Assign
                          </button>
                        )}
                      </td>
                      <td>
                        {result(ele?._id) == undefined ? (
                          <button
                            onClick={() =>
                              setOpenInterviewBoardModal(
                                !openInterviewBoardModal
                              )
                            }
                          >
                            <svg
                              width="12"
                              height="10"
                              viewBox="0 0 12 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.5 4.01758H10M10 4.01758H11.5M10 4.01758V2.51758M10 4.01758V5.51758M0.5 9.01758V8.51758C0.5 7.58932 0.868749 6.69908 1.52513 6.0427C2.1815 5.38633 3.07174 5.01758 4 5.01758M4 5.01758C4.92826 5.01758 5.8185 5.38633 6.47487 6.0427C7.13125 6.69908 7.5 7.58932 7.5 8.51758V9.01758M4 5.01758C4.53043 5.01758 5.03914 4.80686 5.41421 4.43179C5.78929 4.05672 6 3.54801 6 3.01758C6 2.48715 5.78929 1.97844 5.41421 1.60336C5.03914 1.22829 4.53043 1.01758 4 1.01758C3.46957 1.01758 2.96086 1.22829 2.58579 1.60336C2.21071 1.97844 2 2.48715 2 3.01758C2 3.54801 2.21071 4.05672 2.58579 4.43179C2.96086 4.80686 3.46957 5.01758 4 5.01758Z"
                                stroke="white"
                                stroke-width="0.75"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Assign
                          </button>
                        ) : (
                          result(ele?._id)
                        )}
                      </td>
                      <td>
                        <label class="switch" data-tip="Access off/on">
                          <input
                            type="checkbox"
                            checked={ele.status === "active" ? true : false}
                            onChange={(e) => {
                              changeStatus(e, ele._id);
                            }}
                          />
                          <span class="slider round"></span>
                        </label>
                        <button
                          onClick={() => {
                            setEditDepartment(true);
                            setDepartmentName(ele);
                          }}
                        >
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.867188 8.87124H1.41719L6.95469 3.33374L6.40469 2.78374L0.867188 8.32124V8.87124ZM8.54219 2.79624L6.94219 1.19624L7.46719 0.67124C7.60885 0.529574 7.78385 0.45874 7.99219 0.45874C8.20052 0.45874 8.37552 0.529574 8.51719 0.67124L9.06719 1.22124C9.20885 1.36291 9.27969 1.53791 9.27969 1.74624C9.27969 1.95457 9.20885 2.12957 9.06719 2.27124L8.54219 2.79624ZM8.01719 3.32124L1.71719 9.62124H0.117188V8.02124L6.41719 1.72124L8.01719 3.32124ZM6.67969 3.05874L6.40469 2.78374L6.95469 3.33374L6.67969 3.05874Z"
                              fill="#333333"
                            />
                          </svg>
                          Edit
                        </button>
                        <button
                          onClick={() => {
                            setDeletePopup(true);
                            setId(ele._id);
                          }}
                        >
                          <svg
                            width="9"
                            height="10"
                            viewBox="0 0 9 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.87969 9.12134C1.67344 9.12134 1.49688 9.0479 1.35 8.90103C1.20313 8.75415 1.12969 8.57759 1.12969 8.37134V1.24634H0.617188V0.496338H2.96719V0.121338H6.26719V0.496338H8.61719V1.24634H8.10469V8.37134C8.10469 8.57134 8.02969 8.74634 7.87969 8.89634C7.72969 9.04634 7.55469 9.12134 7.35469 9.12134H1.87969ZM7.35469 1.24634H1.87969V8.37134H7.35469V1.24634ZM3.20469 7.29634H3.95469V2.30884H3.20469V7.29634ZM5.27969 7.29634H6.02969V2.30884H5.27969V7.29634Z"
                              fill="#333333"
                            />
                          </svg>
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </table>

              <div className="table-footer">
                <div className="pagination">
                  <button
                    onClick={() => {
                      if (currentPage > 1) {
                        if (currentPage > 1) {
                          if (loading === false) {
                            setCurrentPage(currentPage - 1);
                          }
                        }
                      }
                    }}
                  >
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L2.41421 6Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                  <span>
                    {totalPages === 0 ? 0 : currentPage}/{totalPages}
                  </span>
                  <button
                    onClick={() => {
                      setCurrentPage(
                        currentPage === totalPages || totalPages === 0
                          ? currentPage
                          : loading === false
                          ? currentPage + 1
                          : currentPage
                      );
                    }}
                  >
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageDepartments;
