import React, { useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Card = (props) => {
  const [inviteDetails, setinviteDetails] = useState({
    totalInvite: 0,
    appeared: 0,
    qualified: 0,
  });

  const navigate = useNavigate();
  const getInviteDetails = async () => {
    let qualifiedcnt = 0;
    let appearcnt = 0;
    let totalInvite = 0;

    if (props?.status !== "draft") {
      totalInvite = props.testInviteData.length;
      props.testInviteData.forEach((element) => {
        if (element.status === "hired") {
          qualifiedcnt = qualifiedcnt + 1;
        }
      });

      props.testInviteData.forEach((element) => {
        if (
          element.status === "appeared" ||
          element.status === "reviewed" ||
          element.status === "shortlisted" ||
          element.status === "hired" ||
          element.status === "rejected"
        ) {
          appearcnt = appearcnt + 1;
        }
      });
    }

    setinviteDetails((prev) => ({
      ...prev,
      totalInvite: totalInvite,
      appeared: appearcnt,
      qualified: qualifiedcnt,
    }));
  };
  // console.log(props._id, "+_+_+_+_+_+_+");
  return (
    <div className="superadmin-dashboard-card-container">
      <div className="first">
        <div className="left-side">
          <div className="header">
            <span>
              {props.name.length > 30
                ? props.name.slice(0, 30) + ". . ."
                : props.name}
            </span>
            {props.status == "published" ? (
              <button className="status-button">
                <div className="circle">
                  {/* Document Icon */}
                  <svg
                    width="8"
                    height="9"
                    viewBox="0 0 8 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.55997 1.31647L5.7115 0.468004C5.5635 0.319195 5.38747 0.201212 5.19358 0.120881C4.99968 0.0405496 4.79179 -0.000534645 4.58191 5.25282e-06H1.59727C1.1738 0.000512499 0.767824 0.168958 0.468388 0.468394C0.168953 0.767829 0.000507246 1.1738 0 1.59727V7.34742C0.000507246 7.77089 0.168953 8.17687 0.468388 8.4763C0.767824 8.77574 1.1738 8.94418 1.59727 8.94469H5.4307C5.85417 8.94418 6.26014 8.77574 6.55958 8.4763C6.85901 8.17687 7.02746 7.77089 7.02797 7.34742V2.44606C7.02851 2.23618 6.98742 2.02829 6.90709 1.8344C6.82676 1.6405 6.70878 1.46447 6.55997 1.31647ZM6.10826 1.76818C6.15341 1.81358 6.19397 1.86334 6.22933 1.91672H5.4307C5.34598 1.91672 5.26472 1.88307 5.20481 1.82316C5.1449 1.76325 5.11125 1.68199 5.11125 1.59727V0.798638C5.16465 0.834103 5.2144 0.874766 5.25979 0.92003L6.10826 1.76818ZM5.4307 8.30578H1.59727C1.34309 8.30578 1.09933 8.20481 0.919603 8.02509C0.739876 7.84536 0.638906 7.6016 0.638906 7.34742V1.59727C0.638906 1.3431 0.739876 1.09934 0.919603 0.919608C1.09933 0.739881 1.34309 0.638911 1.59727 0.638911H4.47234V1.59727C4.47234 1.85144 4.57331 2.09521 4.75304 2.27493C4.93277 2.45466 5.17653 2.55563 5.4307 2.55563H6.38906V7.34742C6.38906 7.6016 6.28809 7.84536 6.10836 8.02509C5.92864 8.20481 5.68487 8.30578 5.4307 8.30578Z"
                      fill="white"
                    />
                    <path
                      d="M1.91662 2.87523H3.19443C3.27916 2.87523 3.36041 2.84158 3.42032 2.78167C3.48023 2.72176 3.51389 2.64051 3.51389 2.55578C3.51389 2.47106 3.48023 2.3898 3.42032 2.32989C3.36041 2.26998 3.27916 2.23633 3.19443 2.23633H1.91662C1.8319 2.23633 1.75064 2.26998 1.69073 2.32989C1.63082 2.3898 1.59717 2.47106 1.59717 2.55578C1.59717 2.64051 1.63082 2.72176 1.69073 2.78167C1.75064 2.84158 1.8319 2.87523 1.91662 2.87523Z"
                      fill="white"
                    />
                    <path
                      d="M5.11115 3.51392H1.91662C1.8319 3.51392 1.75064 3.54757 1.69073 3.60748C1.63082 3.66739 1.59717 3.74864 1.59717 3.83337C1.59717 3.91809 1.63082 3.99935 1.69073 4.05926C1.75064 4.11917 1.8319 4.15282 1.91662 4.15282H5.11115C5.19588 4.15282 5.27713 4.11917 5.33704 4.05926C5.39695 3.99935 5.4306 3.91809 5.4306 3.83337C5.4306 3.74864 5.39695 3.66739 5.33704 3.60748C5.27713 3.54757 5.19588 3.51392 5.11115 3.51392Z"
                      fill="white"
                    />
                    <path
                      d="M5.11115 4.79175H1.91662C1.8319 4.79175 1.75064 4.8254 1.69073 4.88531C1.63082 4.94522 1.59717 5.02648 1.59717 5.1112C1.59717 5.19593 1.63082 5.27718 1.69073 5.33709C1.75064 5.397 1.8319 5.43065 1.91662 5.43065H5.11115C5.19588 5.43065 5.27713 5.397 5.33704 5.33709C5.39695 5.27718 5.4306 5.19593 5.4306 5.1112C5.4306 5.02648 5.39695 4.94522 5.33704 4.88531C5.27713 4.8254 5.19588 4.79175 5.11115 4.79175Z"
                      fill="white"
                    />
                    <path
                      d="M4.47225 6.06958H1.91662C1.8319 6.06958 1.75064 6.10324 1.69073 6.16315C1.63082 6.22305 1.59717 6.30431 1.59717 6.38903C1.59717 6.47376 1.63082 6.55501 1.69073 6.61492C1.75064 6.67483 1.8319 6.70849 1.91662 6.70849H4.47225C4.55697 6.70849 4.63822 6.67483 4.69813 6.61492C4.75804 6.55501 4.7917 6.47376 4.7917 6.38903C4.7917 6.30431 4.75804 6.22305 4.69813 6.16315C4.63822 6.10324 4.55697 6.06958 4.47225 6.06958Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <span>Published</span>
              </button>
            ) : (
              <button className="completed">
                <div className="circle">
                  {/* Document Icon */}
                  <svg
                    width="29"
                    height="30"
                    viewBox="0 0 29 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="14.5" cy="14.5514" r="14.5" fill="#00C49A" />
                    <path
                      d="M19.3341 9.92458L18.1268 8.71731C17.9162 8.50557 17.6658 8.33769 17.3899 8.22339C17.114 8.10909 16.8182 8.05063 16.5195 8.0514H12.2727C11.6702 8.05212 11.0925 8.2918 10.6665 8.71786C10.2404 9.14392 10.0007 9.72158 10 10.3241V18.5059C10.0007 19.1085 10.2404 19.6861 10.6665 20.1122C11.0925 20.5383 11.6702 20.7779 12.2727 20.7787H17.7273C18.3298 20.7779 18.9075 20.5383 19.3335 20.1122C19.7596 19.6861 19.9993 19.1085 20 18.5059V11.5319C20.0008 11.2332 19.9423 10.9374 19.828 10.6615C19.7137 10.3856 19.5458 10.1352 19.3341 9.92458ZM18.6914 10.5673C18.7556 10.6319 18.8133 10.7027 18.8636 10.7787H17.7273C17.6067 10.7787 17.4911 10.7308 17.4059 10.6455C17.3206 10.5603 17.2727 10.4447 17.2727 10.3241V9.18776C17.3487 9.23823 17.4195 9.29608 17.4841 9.36049L18.6914 10.5673ZM17.7273 19.8696H12.2727C11.9111 19.8696 11.5642 19.7259 11.3085 19.4702C11.0528 19.2144 10.9091 18.8676 10.9091 18.5059V10.3241C10.9091 9.96247 11.0528 9.61562 11.3085 9.35989C11.5642 9.10416 11.9111 8.96049 12.2727 8.96049H16.3636V10.3241C16.3636 10.6858 16.5073 11.0326 16.763 11.2884C17.0188 11.5441 17.3656 11.6878 17.7273 11.6878H19.0909V18.5059C19.0909 18.8676 18.9472 19.2144 18.6915 19.4702C18.4358 19.7259 18.0889 19.8696 17.7273 19.8696Z"
                      fill="white"
                    />
                    <path
                      d="M12.728 12.1421H14.5462C14.6667 12.1421 14.7823 12.0942 14.8676 12.009C14.9528 11.9237 15.0007 11.8081 15.0007 11.6876C15.0007 11.567 14.9528 11.4514 14.8676 11.3662C14.7823 11.2809 14.6667 11.233 14.5462 11.233H12.728C12.6074 11.233 12.4918 11.2809 12.4066 11.3662C12.3213 11.4514 12.2734 11.567 12.2734 11.6876C12.2734 11.8081 12.3213 11.9237 12.4066 12.009C12.4918 12.0942 12.6074 12.1421 12.728 12.1421Z"
                      fill="white"
                    />
                    <path
                      d="M17.2734 13.0514H12.728C12.6074 13.0514 12.4918 13.0993 12.4066 13.1845C12.3213 13.2698 12.2734 13.3854 12.2734 13.5059C12.2734 13.6265 12.3213 13.7421 12.4066 13.8273C12.4918 13.9126 12.6074 13.9605 12.728 13.9605H17.2734C17.394 13.9605 17.5096 13.9126 17.5948 13.8273C17.6801 13.7421 17.728 13.6265 17.728 13.5059C17.728 13.3854 17.6801 13.2698 17.5948 13.1845C17.5096 13.0993 17.394 13.0514 17.2734 13.0514Z"
                      fill="white"
                    />
                    <path
                      d="M17.2734 14.8696H12.728C12.6074 14.8696 12.4918 14.9175 12.4066 15.0028C12.3213 15.088 12.2734 15.2036 12.2734 15.3242C12.2734 15.4447 12.3213 15.5603 12.4066 15.6456C12.4918 15.7308 12.6074 15.7787 12.728 15.7787H17.2734C17.394 15.7787 17.5096 15.7308 17.5948 15.6456C17.6801 15.5603 17.728 15.4447 17.728 15.3242C17.728 15.2036 17.6801 15.088 17.5948 15.0028C17.5096 14.9175 17.394 14.8696 17.2734 14.8696Z"
                      fill="white"
                    />
                    <path
                      d="M16.3643 16.6881H12.728C12.6074 16.6881 12.4918 16.736 12.4066 16.8212C12.3213 16.9065 12.2734 17.0221 12.2734 17.1427C12.2734 17.2632 12.3213 17.3788 12.4066 17.4641C12.4918 17.5493 12.6074 17.5972 12.728 17.5972H16.3643C16.4849 17.5972 16.6005 17.5493 16.6858 17.4641C16.771 17.3788 16.8189 17.2632 16.8189 17.1427C16.8189 17.0221 16.771 16.9065 16.6858 16.8212C16.6005 16.736 16.4849 16.6881 16.3643 16.6881Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <span>Completed</span>
              </button>
            )}
          </div>

          <div className="info">
            <div className="recruiter-box">
              <svg
                width="13"
                height="14"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.89453 11.8927C1.67786 11.8927 1.48828 11.8115 1.32578 11.649C1.16328 11.4865 1.08203 11.2969 1.08203 11.0802V4.45837C1.08203 4.2417 1.16328 4.05212 1.32578 3.88962C1.48828 3.72712 1.67786 3.64587 1.89453 3.64587H4.46745V2.41357C4.46745 2.19691 4.5487 2.00732 4.7112 1.84482C4.8737 1.68232 5.06328 1.60107 5.27995 1.60107H7.71745C7.93411 1.60107 8.1237 1.68232 8.2862 1.84482C8.4487 2.00732 8.52995 2.19691 8.52995 2.41357V3.64587H11.1029C11.3195 3.64587 11.5091 3.72712 11.6716 3.88962C11.8341 4.05212 11.9154 4.2417 11.9154 4.45837V11.0802C11.9154 11.2969 11.8341 11.4865 11.6716 11.649C11.5091 11.8115 11.3195 11.8927 11.1029 11.8927H1.89453ZM5.27995 3.64587H7.71745V2.41357H5.27995V3.64587ZM11.1029 8.49378H7.73099V9.30628H5.29349V8.49378H1.89453V11.0802H11.1029V8.49378ZM6.10599 8.49378H6.91849V7.68128H6.10599V8.49378ZM1.89453 7.68128H5.29349V6.86878H7.73099V7.68128H11.1029V4.45837H1.89453V7.68128Z"
                  fill="#827C7C"
                />
              </svg>

              <span>
                {" "}
                {`Department: ${props.department || "Software Development"}`}
              </span>
            </div>
            <div className="recruiter-box">
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.6654 12.2677V11.101C11.6654 10.4822 11.4195 9.8887 10.9819 9.45112C10.5444 9.01353 9.95087 8.7677 9.33203 8.7677H4.66536C4.04653 8.7677 3.45303 9.01353 3.01545 9.45112C2.57786 9.8887 2.33203 10.4822 2.33203 11.101V12.2677"
                  stroke="#827C7C"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.0013 6.43437C8.28997 6.43437 9.33464 5.3897 9.33464 4.10103C9.33464 2.81237 8.28997 1.7677 7.0013 1.7677C5.71264 1.7677 4.66797 2.81237 4.66797 4.10103C4.66797 5.3897 5.71264 6.43437 7.0013 6.43437Z"
                  stroke="#827C7C"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <span> {`Recruiter: ${props.createdBy.fullName}`}</span>
            </div>
            <div className="status">
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4097_2272)">
                  <path
                    d="M10.709 3.89099C11.4431 4.62529 11.9429 5.56075 12.1453 6.5791C12.3478 7.59746 12.2437 8.65297 11.8463 9.61218C11.4489 10.5714 10.776 11.3912 9.91266 11.968C9.04934 12.5448 8.03437 12.8527 6.9961 12.8527C5.95782 12.8527 4.94285 12.5448 4.07953 11.968C3.21621 11.3912 2.5433 10.5714 2.14589 9.61218C1.74848 8.65297 1.64441 7.59746 1.84685 6.5791C2.04928 5.56075 2.54913 4.62529 3.28318 3.89099"
                    stroke="#827C7C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7 1.18433V7.01766"
                    stroke="#827C7C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4097_2272">
                    <rect
                      width="14"
                      height="14"
                      fill="white"
                      transform="translate(0 0.0177002)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span>Test Status: On</span>
            </div>
          </div>
          <div className="bottom-container">
            <div
              className="super-copy-box"
              onClick={() => {
                //Copy link from props to clipboard
                navigator.clipboard.writeText(props.link);
                toast.success("Link Copied to Clipboard");
              }}
              style={{ cursor: "pointer" }}
            >
              {/* Copy link Icon */}

              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1449 8.04901V11.764C11.1449 12.0924 11.0145 12.4074 10.7822 12.6396C10.55 12.8719 10.235 13.0023 9.90659 13.0023H3.09576C2.76733 13.0023 2.45235 12.8719 2.22012 12.6396C1.98789 12.4074 1.85742 12.0924 1.85742 11.764V4.95318C1.85742 4.62475 1.98789 4.30978 2.22012 4.07754C2.45235 3.84531 2.76733 3.71484 3.09576 3.71484H6.81075"
                  stroke="#3B3A39"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.28711 1.85742H13.0021V5.57242"
                  stroke="#3B3A39"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.19141 8.66825L13.0022 1.85742"
                  stroke="#3B3A39"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>Copy Link</span>
            </div>
            <div className="super-copy-box">
              {/* Clock Icon */}

              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1918_1330)">
                  <path
                    d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.5 3.75V7.5L10 8.75"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1918_1330">
                    <rect width="15" height="15" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>
                {" "}
                {props.totalTiming !== "" ? props.totalTiming : 0} mins
              </span>
            </div>
            <div className="super-copy-box">
              {/* Mail Icon */}
              <svg
                width="14"
                height="12"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.25 1H12.25C12.9375 1 13.5 1.5625 13.5 2.25V9.75C13.5 10.4375 12.9375 11 12.25 11H2.25C1.5625 11 1 10.4375 1 9.75V2.25C1 1.5625 1.5625 1 2.25 1Z"
                  stroke="black"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.5 2.25L7.25 6.625L1 2.25"
                  stroke="black"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <span>
                {props?.testType}
                {props?.testInviteOnly ? " - Invite Only" : ""}
              </span>
            </div>

            <button className="send-invite-btn">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4097_2343)">
                  <path
                    d="M13.3346 1.18433L6.91797 7.60099"
                    stroke="#384455"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.3346 1.18433L9.2513 12.851L6.91797 7.60099L1.66797 5.26766L13.3346 1.18433Z"
                    stroke="#384455"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4097_2343">
                    <rect
                      width="14"
                      height="14"
                      fill="white"
                      transform="translate(0.5 0.0177002)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Send Invites
            </button>
          </div>
        </div>

        <div className="attempted">
          <div style={{ textAlign: "left" }}>
            <span>{inviteDetails?.totalInvite}</span>
            <p>Candidates Attempted</p>
          </div>
        </div>

        <div className="shortlisted">
          <div style={{ textAlign: "left" }}>
            <span>{inviteDetails?.qualified}</span>
            <p>Candidates Shortlisted</p>
          </div>
        </div>

        <div className="manage">
          <button
            onClick={() => {
              navigate(`/candidateshortlisted/${props._id}`);
            }}
            style={{
              cursor: "pointer",
            }}
          >
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4097_2301)">
                <path
                  d="M10 13.5265V12.2765C10 11.6134 9.73661 10.9776 9.26777 10.5087C8.79893 10.0399 8.16304 9.77649 7.5 9.77649H3.125C2.46196 9.77649 1.82607 10.0399 1.35723 10.5087C0.888392 10.9776 0.625 11.6134 0.625 12.2765V13.5265"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.3125 7.27649C6.69321 7.27649 7.8125 6.1572 7.8125 4.77649C7.8125 3.39578 6.69321 2.27649 5.3125 2.27649C3.93179 2.27649 2.8125 3.39578 2.8125 4.77649C2.8125 6.1572 3.93179 7.27649 5.3125 7.27649Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.5 5.40149V9.15149"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.375 7.27649H10.625"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_4097_2301">
                  <rect
                    width="15"
                    height="15"
                    fill="white"
                    transform="translate(0 0.401489)"
                  />
                </clipPath>
              </defs>
            </svg>
            View Shortlisted
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
