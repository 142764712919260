import { Gauge } from "@ant-design/plots";

const GaugeChart = ({ departmentData }) => {
  console.log(departmentData);
  const config = {
    percent: departmentData?.inviteUsed / departmentData?.inviteAssigned,
    radius: 0.68,
    range: {
      color: "#00C49A",
    },

    startAngle: (-8 / 6) * Math.PI,
    endAngle: (2 / 6) * Math.PI,
    indicator: null,
    statistic: {
      title: {
        offsetY: -150,
        style: {
          fontSize: "1.2rem",
          fontFamily: "Roboto",
          // fontWeight: "bold",
          color: "#4B535E",
        },
        formatter: () => `Invitations Spent`,
      },
      content: {
        offsetY: -105,
        style: {
          fontSize: "18px",
          fontFamily: "Roboto",
          fontWeight: "bold",
          color: "#384455",
        },
        formatter: () => `${departmentData?.inviteUsed}`,
      },
    },
    gaugeStyle: {
      lineCap: "round",
    },
  };
  return <Gauge {...config} />;
};

export default GaugeChart;
