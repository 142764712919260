import React, { useContext, useState } from "react";
import "./index.css";
import { updateMemberApi, getMemberApi } from "../../apis";
import CreateTestContext from "../../store/CreateTestContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InviteInterviewBoardModal = (props) => {
  const [departmentId, setDepartmentId] = useState(
    props?.data?.departmentId?._id
  );
  const createTestContext = useContext(CreateTestContext);
  const [fromData, setFromData] = useState(props.data);
  const [errorName, setErrorName] = useState();
  const [errorEmail, setErrorEmail] = useState();
  const [errorDepartmentId, setErrorDepartmentId] = useState();
  const [errorAccess, setErrorAccess] = useState();
  const handelChange = (e) => {
    setFromData({ ...fromData, [e.target.name]: e.target.value });
    setErrorName();
    setErrorEmail();
    setErrorDepartmentId();
    setErrorAccess();
  };
  const handelonSubmit = async () => {
    let isValid = true;
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    const regex = /^[a-zA-Z0-9 ]*$/;
    // Perform validation
    if (fromData?.fullName == "") {
      setErrorName("Name is required!");
      isValid = false;
    } else {
      if (Number.isInteger(Number(fromData?.fullName))) {
        setErrorName("Name can't be Integer Only");
        isValid = false;
      }
      if (!regex.test(fromData?.fullName)) {
        setErrorName("Special characters are not allowed !");
        isValid = false;
      }
    }
    if (!fromData?.email) {
      setErrorEmail("Email is required");
      isValid = false;
    } else if (!isValidEmail(fromData?.email)) {
      setErrorEmail("Email is Invalid!");
      isValid = false;
    }
    if (fromData?.departmentId == "") {
      setErrorDepartmentId("Department is required");
      isValid = false;
    }
    if (fromData?.access == "") {
      setErrorAccess("Access is required");
      isValid = false;
    }

    if (isValid) {
      const res = await updateMemberApi(props.data._id, fromData);

      if (res.status == 200) {
        const resMember = await getMemberApi();
        props.setData(resMember.data.data);
        props.setCurrentPage(1);
        props.setEditInvite(false);
        toast(res.data.massage, {
          className: "toast-message",
        });
      }
    }
  };

  return (
    <div className="edit-invite-outer-container">
      <div className="edit-invite-inner-container">
        <div className="edit-invite-header-container">
          <span>Invite to interveiw board</span>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              props.closeModal();
            }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.0032 13.6551L7.00642 8.65882L2.01082 13.6551C1.55102 14.1148 0.805358 14.1151 0.345266 13.6554C-0.114825 13.1957 -0.115113 12.4499 0.344547 11.99L5.34122 6.99369L0.344476 1.99628C-0.102534 1.534 -0.0962087 0.798602 0.358851 0.34437C0.813839 -0.110149 1.54922 -0.115324 2.01082 0.332296L7.00642 5.32856L12.0032 0.332296C12.4666 -0.103824 13.1925 -0.0928997 13.6426 0.35702C14.0927 0.806652 14.1041 1.53256 13.6684 1.99628L8.67162 6.99369L13.6684 11.99C14.1157 12.4519 14.1098 13.1873 13.6551 13.6419C13.2004 14.0967 12.4651 14.1024 12.0031 13.6551H12.0032Z"
              fill="#99B2C6"
            />
          </svg>
        </div>
        <div className="manage-access-form-container">
          <div className="top-forms-container">
            <div className="input-outer-container">
              <span>Username</span>
              <div className="input-inner-container">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_6565_5921)">
                    <path
                      d="M15 15.2449V13.7449C15 12.9492 14.6839 12.1862 14.1213 11.6236C13.5587 11.0609 12.7957 10.7449 12 10.7449H6C5.20435 10.7449 4.44129 11.0609 3.87868 11.6236C3.31607 12.1862 3 12.9492 3 13.7449V15.2449"
                      stroke="#333333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 7.74487C10.6569 7.74487 12 6.40173 12 4.74487C12 3.08802 10.6569 1.74487 9 1.74487C7.34315 1.74487 6 3.08802 6 4.74487C6 6.40173 7.34315 7.74487 9 7.74487Z"
                      stroke="#333333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6565_5921">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(0 0.244873)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <input
                  type="text"
                  placeholder="Person Name Here"
                  value={fromData?.fullName}
                  name="fullName"
                  onChange={handelChange}
                />
              </div>
              <span className="errorMsg">{errorName}</span>
            </div>
            <div className="input-outer-container">
              <span>Email</span>
              <div className="input-inner-container">
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_6565_5929)">
                    <path
                      d="M3.48047 3.24487H15.4805C16.3055 3.24487 16.9805 3.91987 16.9805 4.74487V13.7449C16.9805 14.5699 16.3055 15.2449 15.4805 15.2449H3.48047C2.65547 15.2449 1.98047 14.5699 1.98047 13.7449V4.74487C1.98047 3.91987 2.65547 3.24487 3.48047 3.24487Z"
                      stroke="#333333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.9805 4.74487L9.48047 9.99487L1.98047 4.74487"
                      stroke="#333333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6565_5929">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(0.480469 0.244873)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <input
                  type="email"
                  placeholder="something@something.com"
                  value={fromData?.email}
                  name="email"
                  onChange={handelChange}
                />
              </div>
              <span className="errorMsg">{errorEmail}</span>
            </div>
          </div>
          <div className="top-forms-container">
            <div className="input-outer-container">
              <span>Password</span>
              <div className="input-inner-container">
                <input
                  type="password"
                  placeholder="Enter password here"
                  value={fromData?.password}
                  name="password"
                  onChange={handelChange}
                />
              </div>
              <span className="errorMsg">{errorName}</span>
            </div>
            <div className="input-outer-container">
              <span>Confirm Password</span>
              <div className="input-inner-container">
                <input
                  type="password"
                  placeholder="Confirm password"
                  value={fromData?.confirmPassword}
                  name="confirmPassword"
                  onChange={handelChange}
                />
              </div>
              <span className="errorMsg">{errorEmail}</span>
            </div>
          </div>

          <div className="send-invite-btn-container">
            <button onClick={handelonSubmit}>
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10" cy="10.2449" r="10" fill="white" />
                <g clip-path="url(#clip0_6565_5953)">
                  <path
                    d="M14.5 5.24487L9 10.7449"
                    stroke="#00c49a"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.5 5.24487L11 15.2449L9 10.7449L4.5 8.74487L14.5 5.24487Z"
                    stroke="#00c49a"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6565_5953">
                    <rect
                      width="12"
                      height="12"
                      fill="white"
                      transform="translate(3.5 4.24487)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Invite
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteInterviewBoardModal;
