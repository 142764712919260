import { useState,useEffect } from 'react'
import ReactTooltip from 'react-tooltip';
import {toast} from 'react-toastify'
import './index.css'

const ToggleButton = (props) => {
    const [checked,setchecked]=useState(props?.name!=="draft"?true:false)
    useEffect(()=>{
        setchecked(props?.name!=="draft"?props.status:false)
    },[props?.name])
    
    
    const onChangeToggle=(e)=>{
        if(props?.name!=="draft"){
            setchecked(e.target.checked);props.onClick(e.target.checked,e)
            
        }else{
            setchecked(false)
            toast("Please publish your test!")
        }
        
    }
    return (
        <>
        <label className='toggleSwitch' data-tip={props?.name==="draft"?"Please publish your test":`test off/on`} class="switch">
            <input className='toggleSwitch' checked={checked} onChange={onChangeToggle} type="checkbox"  />
            <span  class="slider round"></span>
        </label>
        <ReactTooltip />
        </>
        
    )
}

export default ToggleButton