import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./index.css";
import { backend_url } from "../../constant";
import axios from "axios";
import { getCookie } from "../../constant";
import jwtDecode from "jwt-decode";

function CreateDepartmentModal(props) {
  let navigate = useNavigate();
  const [departmentName, setDepartmentName] = useState(
    props.edit ? props.departmentName.department : ""
  );
  const [departmentNameError, setDepartmentNameError] = useState("");

  const createDepartment = async () => {
    try {
      const token = getCookie("Xh7ERL0G");
      // const decode = jwtDecode(token);
      let res;
      props.edit
        ? (res = await axios.put(
            `${backend_url}invites/editDepartment/${props.departmentName._id}`,
            {
              department: departmentName,
            },
            { headers: { token: token } }
          ))
        : (res = await axios.post(
            `${backend_url}invites/createDepartment`,
            {
              department: departmentName,
            },
            { headers: { token: token } }
          ));

      if (res) {
        props.reload();
        toast.success(
          !props.edit ? res.data.massage : "Department Updated Successfully"
        );
      }
      props.closeModal();
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className="candidate-invite-container">
      <div className="invite-box">
        <div className="header">
          <div className="title">
            <div>
              <svg
                width="40"
                height="40"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="24"
                  cy="24"
                  r="24"
                  fill="#00C49A"
                  fill-opacity="0.1"
                />
                <path
                  d="M15.5 33C15.1 33 14.75 32.85 14.45 32.55C14.15 32.25 14 31.9 14 31.5V19.275C14 18.875 14.15 18.525 14.45 18.225C14.75 17.925 15.1 17.775 15.5 17.775H20.25V15.5C20.25 15.1 20.4 14.75 20.7 14.45C21 14.15 21.35 14 21.75 14H26.25C26.65 14 27 14.15 27.3 14.45C27.6 14.75 27.75 15.1 27.75 15.5V17.775H32.5C32.9 17.775 33.25 17.925 33.55 18.225C33.85 18.525 34 18.875 34 19.275V31.5C34 31.9 33.85 32.25 33.55 32.55C33.25 32.85 32.9 33 32.5 33H15.5ZM21.75 17.775H26.25V15.5H21.75V17.775ZM32.5 26.725H26.275V28.225H21.775V26.725H15.5V31.5H32.5V26.725ZM23.275 26.725H24.775V25.225H23.275V26.725ZM15.5 25.225H21.775V23.725H26.275V25.225H32.5V19.275H15.5V25.225Z"
                  fill="#00C49A"
                />
              </svg>

              <span>
                {props.edit ? "Update Department" : "Create New Department"}
              </span>
            </div>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                props.closeModal();
              }}
              style={{ cursor: "pointer" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.0032 13.6551L7.00642 8.65882L2.01082 13.6551C1.55102 14.1148 0.805358 14.1151 0.345266 13.6554C-0.114825 13.1957 -0.115113 12.4499 0.344547 11.99L5.34122 6.99369L0.344476 1.99628C-0.102534 1.534 -0.0962087 0.798602 0.358851 0.34437C0.813839 -0.110149 1.54922 -0.115324 2.01082 0.332296L7.00642 5.32856L12.0032 0.332296C12.4666 -0.103824 13.1925 -0.0928997 13.6426 0.35702C14.0927 0.806652 14.1041 1.53256 13.6684 1.99628L8.67162 6.99369L13.6684 11.99C14.1157 12.4519 14.1098 13.1873 13.6551 13.6419C13.2004 14.0967 12.4651 14.1024 12.0031 13.6551H12.0032Z"
                fill="#99B2C6"
              />
            </svg>
          </div>
          <div className="header-border"></div>
        </div>

        <div className="name-field">
          <span>Department Name</span>
          <div className="input-field">
            <input
              onChange={(e) => {
                let letters = /^[A-Za-z\s]+$/;
                if (letters.test(e.target.value)) {
                  setDepartmentName(e.target.value);
                  setDepartmentNameError("");
                } else {
                  setDepartmentName(e.target.value);
                  setDepartmentNameError("Please Enter Alphabets Only");
                }
              }}
              value={departmentName}
              type="text"
              placeholder="Department Name Here"
              required
            />
          </div>
          <span
            className="error"
            style={{
              color: "red",
              fontSize: "12px",
              display: "block",
            }}
          >
            {departmentNameError}
          </span>
        </div>

        <div className="button-container">
          <button
            className="send-invite"
            onClick={() => {
              createDepartment();
            }}
          >
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10.5" cy="10.2449" r="10" fill="white" />
              <path
                d="M6.25 14.5C6.05 14.5 5.875 14.425 5.725 14.275C5.575 14.125 5.5 13.95 5.5 13.75V7.6375C5.5 7.4375 5.575 7.2625 5.725 7.1125C5.875 6.9625 6.05 6.8875 6.25 6.8875H8.625V5.75C8.625 5.55 8.7 5.375 8.85 5.225C9 5.075 9.175 5 9.375 5H11.625C11.825 5 12 5.075 12.15 5.225C12.3 5.375 12.375 5.55 12.375 5.75V6.8875H14.75C14.95 6.8875 15.125 6.9625 15.275 7.1125C15.425 7.2625 15.5 7.4375 15.5 7.6375V13.75C15.5 13.95 15.425 14.125 15.275 14.275C15.125 14.425 14.95 14.5 14.75 14.5H6.25ZM9.375 6.8875H11.625V5.75H9.375V6.8875ZM14.75 11.3625H11.6375V12.1125H9.3875V11.3625H6.25V13.75H14.75V11.3625ZM10.1375 11.3625H10.8875V10.6125H10.1375V11.3625ZM6.25 10.6125H9.3875V9.8625H11.6375V10.6125H14.75V7.6375H6.25V10.6125Z"
                fill="#FF6812"
              />
            </svg>
            {props.edit ? "Update Department" : "Create Department"}
          </button>
          <button
            className="candidate-invite-bulk-invite"
            onClick={props.closeModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateDepartmentModal;
