import React, { useState, useEffect, useContext, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { backend_url, getCookie } from "../../constant";
import axios from "axios";
import "./index.css";
import Card from "./Card";
import SuperNavbar from "../../component/SuperNavbar";
import jwtDecode from "jwt-decode";
import AssessmentSkeleton from "../../component/AssessmentSkeleton";
import { toast } from "react-toastify";
import { getDepartmentMemberApi, getMemberApi } from "../../apis";

let cancelToken;
const Assessments = () => {
  const [dashboardData, setdashboardData] = useState({
    invitesUsed: "",
    companyTest: "",
    candidateAppear: "",
    candidateSelected: "",
  });
  const [loadingforDashboard, setloadingforDashboard] = useState(true);
  const [loadingforAssessmentData, setloadingforAssessmentData] =
    useState(true);
  const [search, setSearch] = useState("");
  // const context = useContext(CreateTestContext)
  let startDate = new Date();
  let endDate = new Date();
  const createdBySelect = useRef();
  const testStatusBySelect = useRef();
  const creationDateSelect = useRef();
  const token = getCookie("Xh7ERL0G");
  const decode = jwtDecode(token);
  const [selectedCreatedBy, setselectedCreatedBy] = useState(decode.user_id);
  const [selectedTestStatus, setselectedTestStatus] = useState([]);
  const [selectedTestType, setselectedTestType] = useState([]);
  const [selectedInviteOnly, setselectedInviteOnly] = useState([]);
  const [sendInvitePopup, setSendInvitePopup] = useState(false);

  const [loadingArray] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const [loading, setloading] = useState(true);
  const [testData, settestData] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [searchLoading, setsearchLoading] = useState(false);
  const [searchData, setsearchData] = useState([]);
  const [searchFilter, setsearchFilter] = useState(false);
  const [testStatus, settestStatus] = useState([
    { name: "published", status: false, count: 0 },
    { name: "completed", status: false, count: 0 },
    { name: "draft", status: false, count: 0 },
  ]);
  const [testType, settestType] = useState([
    { name: "private", status: false, count: 0 },
    { name: "public", status: false, count: 0 },
  ]);
  const [inviteOnlyType, setinviteOnlyType] = useState([
    { name: "invite only", status: false, count: 0 },
  ]);
  const [testStatusAllSelected, settestStatusAllSelected] = useState(false);
  const [testTypeStatusAllSelected, settestTypeStatusAllSelected] =
    useState(false);
  const [createdBy, setcreatedBy] = useState([]);
  const [selectedCreationDate, setselectedCreationDate] = useState("");
  const [createAssessment, setCreateAssessment] = useState(false);
  const [myselfId, setmyselfId] = useState("");
  const [username, setusername] = useState("");
  const [searchPage, setsearchPage] = useState(1);
  const [paginationStopForSearch, setpaginationStopForSearch] = useState(true);
  const [page, setPage] = useState(1);
  const [paginationStop, setpaginationStop] = useState(true);
  const [totalTest, settotalTest] = useState(0);
  const [testInviteOnlyAllSelected, settestInviteOnlyAllSelected] = useState(0);
  const [canceled, setCanceled] = useState(false);

  //filteration state
  const [isfilter, setisfilter] = useState(false);
  const [filterTestData, setfilterTestData] = useState([]);
  const [paginationStopForFilter, setpaginationStopForFilter] = useState(true);
  const [isPaginationActive, setisPaginationActive] = useState(false);
  const [searchResultCount, setsearchResultCount] = useState("");
  const [filterResultCount, setfilterResultCount] = useState("");
  const [PaginationLoading, setPaginationLoading] = useState(false);
  const [testNonFilterCount, settestNonFilterCount] = useState(0);
  const [finishStatus, setfinishStatus] = useState(false);

  // const getDashboardData = async () => {
  //     setloadingforDashboard(true);
  //     try {
  //         const token = localStorage.getItem('token');
  //         const decode = jwtDecode(token);
  //         const res = await axios.get(`${backend_url}invites/superAdmin`, { headers: { "token": token } });

  //         let appearedCount = 0;
  //         let qualifiedCount = 0;
  //         console.log(res.data.invitesData, "787787887878");
  //         res.data.invitesData.forEach(data => {
  //             switch (data.status) {
  //                 case "appeared":
  //                 case "reviewed":
  //                 case "shortlisted":
  //                 case "hired":
  //                 case "rejected":
  //                     appearedCount++;
  //                     break;
  //                 case "hired":
  //                     qualifiedCount++;
  //                     break;
  //             }
  //         });
  //         setdashboardData((prev) => ({ ...prev, invitesUsed: res.data.invitesData.length, companyTest: res.data.assement.length, candidateAppear: appearedCount, candidateSelected: qualifiedCount }));

  //         setloadingforDashboard(false)
  //     } catch (error) {
  //         setloadingforDashboard(false);

  //     }
  // };
  const getDashboardData = async () => {
    setloadingforDashboard(true);
    try {
      const token = getCookie("Xh7ERL0G");
      const decode = jwtDecode(token);
      const response = await axios.get(
        `${backend_url}invites/statisticsSuper`,
        { headers: { token: token } }
      );
      setdashboardData(response.data);

      setloadingforDashboard(false);
    } catch (error) {
      setloadingforDashboard(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      setCreateAssessment(false);
      // context.clearState()
      window.history.pushState(null, null, window.location.pathname);
    }
  };

  useEffect(() => {
    getJobROle();
    getExperince();
    getAllTest();
    // getCreatedByUsers()
    getAllTestDetals();
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      if (cancelToken) {
        cancelToken.cancel("Operations cancelled due to new request");
      }
      cancelToken = axios.CancelToken.source();
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  const getAllTestDetals = async () => {
    setloading(true);
    try {
      const token = getCookie("Xh7ERL0G");
      const decoed = jwtDecode(token);
      const res = await axios.get(
        `${backend_url}test/getAllTestDetails/${decoed.user_id}`,
        { headers: { token: token } }
      );
      settotalTest(res.data.totalTest);
      const testStatusTestCount = testStatus.map((testStatus) => {
        if (testStatus.name === "published") {
          testStatus.count = res.data.publishedTest;
        }
        if (testStatus.name === "completed") {
          testStatus.count = res.data.completedTest;
        }
        if (testStatus.name === "draft") {
          testStatus.count = res.data.draftTest;
        }
        return testStatus;
      });
      settestStatus(testStatusTestCount);

      const testtypeTestCount = testType.map((testStatus) => {
        if (testStatus.name === "public") {
          testStatus.count = res.data.publicTest;
        }

        if (testStatus.name === "private") {
          testStatus.count = res.data.privateTest;
        }

        return testStatus;
      });

      settestType(testtypeTestCount);

      const inviteOnlyTestCount = inviteOnlyType.map((inviteOnly) => {
        if (inviteOnly.name === "invite only") {
          inviteOnly.count = res.data.inviteOnlyTest;
        }
        return inviteOnly;
      });
      setinviteOnlyType(inviteOnlyTestCount);
    } catch (error) {
      setloading(false);
      toast.error(error);
    }
  };

  const getJobROle = async () => {
    setloading(true);
    try {
      const token = getCookie("Xh7ERL0G");
      const res = await axios.get(`${backend_url}jobrole/getroleTable`, {
        headers: { token: token },
      });
      // context.setJobRoleData(res.data.roleTables)
    } catch (error) {
      console.log(error);
      setloading(false);
      toast(`${error}`, {
        className: "toast-message",
      });
    }
  };

  const getExperince = async () => {
    setloading(true);
    try {
      const token = getCookie("Xh7ERL0G");
      const res = await axios.get(`${backend_url}experience/getAll`, {
        headers: { token: token },
      });
      // context.setexprienceData(res.data.data)
    } catch (error) {
      setloading(false);
      toast(`${error}`, {
        className: "toast-message",
      });
    }
  };

  const getCreatedByUsers = async () => {
    try {
      setloading(true);
      const token = getCookie("Xh7ERL0G");
      const decoed = jwtDecode(token);
      setmyselfId(decoed.user_id);
      setusername(`Myself(${decoed.fullName})`);
      const res = await axios.get(
        `${backend_url}test/getTestAdmins/${decoed.user_id}`,
        { headers: { token: token } }
      );

      const createdByUsers = res.data.data.map((data) => {
        return data.createdBy;
      });
      const result = createdByUsers.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.email === value.email && decoed.fullName !== t.fullName
          )
      );
      setcreatedBy(result);
    } catch (error) {
      setloading(false);
      toast(error);
    }
  };

  // const getNoOfTestAsPerTestStatus = () => {
  //     const publishedCnt = (searchText !== "" && searchData.length ? searchData : testData).filter(element => element.status === "published")
  //     const completedCnt = (searchText !== "" && searchData.length ? searchData : testData).filter(element => element.status === "completed")
  //     const draftCnt = (searchText !== "" && searchData.length ? searchData : testData).filter(element => element.status === "draft")
  //     const inviteonlyCnt = (searchText !== "" && searchData.length ? searchData : testData).filter(element => element.testType === "invite only")
  //     const publicCnt = (searchText !== "" && searchData.length ? searchData : testData).filter(element => element.testType === "public")

  //     const testStatusTestCount = testStatus.map((testStatus) => {
  //         if (testStatus.name === "published") {
  //             testStatus.count = publishedCnt.length
  //         }
  //         if (testStatus.name === "completed") {
  //             testStatus.count = completedCnt.length
  //         }
  //         if (testStatus.name === "draft") {
  //             testStatus.count = draftCnt.length
  //         }
  //         return testStatus
  //     })
  //     settestStatus(testStatusTestCount)

  //     const testtypeTestCount = testType.map((testStatus) => {
  //         if (testStatus.name === "invite only") {
  //             testStatus.count = inviteonlyCnt.length
  //         }
  //         if (testStatus.name === "public") {
  //             testStatus.count = publicCnt.length
  //         }

  //         return testStatus
  //     })
  //     settestType(testtypeTestCount)

  // }

  const getAllTest = () => {
    return new Promise(async (resolve, reject) => {
      console.log("999999999999999");
      const token = getCookie("Xh7ERL0G");
      const decode = jwtDecode(token);
      setCanceled(false);
      setloading(true);
      if (cancelToken) {
        cancelToken.cancel("Operations cancelled due to new request");
      }
      cancelToken = axios.CancelToken.source();
      try {
        const res = await axios.get(
          `${backend_url}test/getAllTestSuper?page=${page}&limit=10`,
          { cancelToken: cancelToken.token, headers: { token: token } }
        );
        settestNonFilterCount(res.data.noOfRecord);
        // alert(res.data.data, "=-=-=-=-=-=-=");
        res.data.data.forEach((element) => {
          const found = testData.some((el) => el._id === element._id);
          if (!found) {
            settestData((prev) => [...prev, element]);
          }
        });
        if (
          selectedTestStatus.length !== 0 ||
          selectedTestType.length !== 0 ||
          selectedCreatedBy !== decode.user_id
        ) {
          setisfilter(true);
        } else {
          setisfilter(false);
        }

        if (res.data.data.length < 10) {
          setpaginationStop(false);
        }
        setPage(page + 1);
        setloading(false);
        setCanceled(false);
        resolve("done");
      } catch (error) {
        if (error.message === "Operations cancelled due to new request") {
          //setsearchLoading(false);
          //setsearchFilter(false);
          setloading(false);
          setCanceled(true);
        } else {
          setloading(false);
          reject(error);
        }
      }
    });
  };

  // useEffect(() => {
  //     getNoOfTestAsPerTestStatus()
  //     // eslint-disable-next-line
  // }, [(searchText !== "" && searchData.length ? searchData : testData)])

  const searchTest = () => {
    return new Promise(async (resolve, reject) => {
      setsearchLoading(true);
      setCanceled(false);
      if (cancelToken) {
        cancelToken.cancel("Operations cancelled due to new request");
      }
      cancelToken = axios.CancelToken.source();
      try {
        if (selectedCreationDate === "Last 1 Week") {
          endDate.setDate(endDate.getDate() - 7);
        }
        if (selectedCreationDate === "Last 1 Month") {
          endDate.setDate(endDate.getDate() - 30);
        }
        if (selectedCreationDate === "Last Quarter") {
          endDate.setDate(endDate.getDate() - 120);
        }
        if (selectedCreationDate === "Last Year") {
          endDate.setDate(endDate.getDate() - 365);
        }
        if (searchText === "") {
          toast("Please enter search assessment");
          setsearchLoading(false);
          setCanceled(false);
          reject("Please enter search assessment");
        } else {
          const token = getCookie("Xh7ERL0G");
          const decoed = jwtDecode(token);
          let res = [];
          res = await axios.post(
            `${backend_url}test/searchTest?page=${searchPage}&limit=10`,
            {
              searchText: searchText.toLowerCase(),
              status: selectedTestStatus.length ? selectedTestStatus : [],
              testInviteOnly: selectedInviteOnly.length ? true : undefined,
              testType: selectedTestType.length ? selectedTestType : [],
              startDate:
                selectedCreationDate !== ""
                  ? `${startDate.getFullYear()}-${
                      startDate.getMonth() + 1
                    }-${startDate.getDate()}`
                  : undefined,
              endDate:
                selectedCreationDate !== ""
                  ? `${endDate.getFullYear()}-${
                      endDate.getMonth() + 1
                    }-${endDate.getDate()}`
                  : undefined,
              selectedCreatedBy:
                selectedCreatedBy !== ""
                  ? selectedCreatedBy === decoed.user_id
                    ? decoed.user_id
                    : selectedCreatedBy
                  : undefined,
              userId: decoed.user_id,
            },
            { cancelToken: cancelToken.token, headers: { token } }
          );
          setsearchResultCount(res.data.NoOfRecord);
          //if (res.data.data.length) {
          if (res.data.data.length < 10) {
            setpaginationStopForSearch(false);
          }
          setsearchFilter(true);
          setsearchLoading(false);
          setCanceled(false);
          setsearchPage((prev) => prev + 1);

          if (searchPage > 1) {
            res.data.data.forEach((data) => {
              const found = searchData.some((el) => el._id === data._id);
              if (!found) {
                setsearchData((prev) => [...prev, data]);
              }
            });
          } else {
            setsearchData(res.data.data);
          }
          // } else {
          //     setsearchData([]);
          //     setsearchLoading(false);
          //     setsearchFilter(true);
          // }
          resolve("done");
        }
      } catch (error) {
        if (error.message === "Operations cancelled due to new request") {
          //setsearchLoading(false);
          //setsearchFilter(false);
          setCanceled(true);
          setsearchLoading(false);
        } else {
          setsearchLoading(false);
          setsearchFilter(false);
        }
        reject(error);
      }
    });
  };

  const onClickSearchIcon = () => {
    return new Promise(async (resolve, reject) => {
      setsearchLoading(true);
      setCanceled(false);
      if (cancelToken) {
        cancelToken.cancel("Operations cancelled due to new request");
      }
      cancelToken = axios.CancelToken.source();
      try {
        if (selectedCreationDate === "Last 1 Week") {
          endDate.setDate(endDate.getDate() - 7);
        }
        if (selectedCreationDate === "Last 1 Month") {
          endDate.setDate(endDate.getDate() - 30);
        }
        if (selectedCreationDate === "Last Quarter") {
          endDate.setDate(endDate.getDate() - 120);
        }
        if (selectedCreationDate === "Last Year") {
          endDate.setDate(endDate.getDate() - 365);
        }
        if (searchText === "") {
          toast("Please enter search assessment");
          setsearchLoading(false);
          setCanceled(false);
          reject("Please enter search assessment");
        } else {
          const token = getCookie("Xh7ERL0G");
          const decoed = jwtDecode(token);
          let res = [];
          res = await axios.post(
            `${backend_url}test/searchTest?page=${1}&limit=10`,
            {
              searchText: searchText.toLowerCase(),
              status: selectedTestStatus.length ? selectedTestStatus : [],
              testInviteOnly: selectedInviteOnly.length ? true : undefined,
              testType: selectedTestType.length ? selectedTestType : [],
              startDate:
                selectedCreationDate !== ""
                  ? `${startDate.getFullYear()}-${
                      startDate.getMonth() + 1
                    }-${startDate.getDate()}`
                  : undefined,
              endDate:
                selectedCreationDate !== ""
                  ? `${endDate.getFullYear()}-${
                      endDate.getMonth() + 1
                    }-${endDate.getDate()}`
                  : undefined,
              selectedCreatedBy:
                selectedCreatedBy !== ""
                  ? selectedCreatedBy === decoed.user_id
                    ? decoed.user_id
                    : selectedCreatedBy
                  : undefined,
              userId: decoed.user_id,
            },
            { cancelToken: cancelToken.token, headers: { token } }
          );
          setsearchResultCount(res.data.NoOfRecord);
          if (res.data.data.length) {
            if (res.data.data.length < 10) {
              setpaginationStopForSearch(false);
            }
            setsearchFilter(true);
            setsearchLoading(false);
            setCanceled(false);
            setsearchPage(2);

            // if (searchPage > 1) {
            //     res.data.data.forEach((data) => {
            //         const found = searchData.some((el) => el._id === data._id);
            //         if (!found) {
            //             setsearchData((prev) => [...prev, data]);
            //         }
            //     });
            // } else {
            setsearchData(res.data.data);
            //}
          } else {
            setsearchData([]);
            setsearchLoading(false);
            setCanceled(false);
            setsearchFilter(true);
          }
          resolve("done");
        }
      } catch (error) {
        if (error.message === "Operations cancelled due to new request") {
          //setsearchLoading(false);
          //setsearchFilter(false);
          setCanceled(true);
          setsearchLoading(false);
        } else {
          setsearchLoading(false);
          setsearchFilter(false);
        }
        reject(error);
      }
    });
  };

  const onClickAllType = (status) => {
    settestTypeStatusAllSelected(status);
    if (status) {
      const res = testType.map((testStatus) => {
        testStatus.status = true;
        return testStatus;
      });
      setPage(1);
      setsearchFilter(false);
      if (isfilter) {
        setpaginationStopForFilter(true);
        setisPaginationActive(false);
      } else {
        setpaginationStop(true);
        setisPaginationActive(false);
      }
      settestType(res);

      let filterData = testType.filter((data) => data.status === true);
      filterData = filterData.map((data) => {
        return data.name;
      });
      setselectedTestType(filterData);
    } else {
      if (searchText !== "") {
        setsearchFilter(true);
      }
      const res = testType.map((testStatus) => {
        testStatus.status = false;
        return testStatus;
      });
      settestType(res);
      setselectedTestType([]);
    }
  };

  const onClickAllStatus = (status) => {
    settestStatusAllSelected(status);
    if (status) {
      const res = testStatus.map((testStatus) => {
        testStatus.status = true;
        return testStatus;
      });
      setsearchFilter(false);
      settestStatus(res);
      let filterData = testStatus.filter((data) => data.status === true);
      filterData = filterData.map((data) => {
        return data.name;
      });
      setselectedTestStatus(filterData);
      setPage(1);
      if (isfilter) {
        setpaginationStopForFilter(true);
        setisPaginationActive(false);
      } else {
        setpaginationStop(true);
        setisPaginationActive(false);
      }
    } else {
      searchText !== "" && setsearchFilter(true);
      const res = testStatus.map((testStatus) => {
        testStatus.status = false;
        return testStatus;
      });
      settestStatus(res);
      setselectedTestStatus([]);
    }
  };

  const onClickTestStatus = (e) => {
    // const data = e.target.value
    const data = testStatus.filter((ele) => ele.name == e.target.value);
    // const data = testStatus?.filter((ele) => {
    //     ele.name == e.target.value
    // })
    console.log(data);
    const res = testStatus.map((testStatus) => {
      if (testStatus.name === e.target.value) {
        console.log("fddsfsdfdfsdf");
        if (data.status === false) {
          setPage(1);
          setsearchFilter(false);
          if (isfilter) {
            setpaginationStopForFilter(true);
            setisPaginationActive(false);
          } else {
            setpaginationStop(true);
            setisPaginationActive(false);
          }
        } else {
          setPage(1);
          searchText !== "" && setsearchFilter(true);
          if (isfilter) {
            setpaginationStopForFilter(true);
            setisPaginationActive(false);
          } else {
            setpaginationStop(true);
            setisPaginationActive(false);
          }
        }
        testStatus.status = !data.status;
        settestStatusAllSelected(false);
      }
      return testStatus;
    });
    settestStatus(res);

    let filterData = testStatus.filter((data) => data.status === true);
    filterData = filterData.map((data) => {
      return data.name;
    });
    setselectedTestStatus(filterData);
  };

  const onClickTestType = (e) => {
    const data = testStatus.filter((ele) => ele.name == e.target.value);
    const res = testType.map((testType) => {
      if (testType.name === e.target.value) {
        if (data.status === false) {
          setPage(1);
          setsearchFilter(false);
          if (isfilter) {
            setpaginationStopForFilter(true);
            setisPaginationActive(false);
          } else {
            setpaginationStop(true);
            setisPaginationActive(false);
          }
        } else {
          setPage(1);
          searchText !== "" && setsearchFilter(true);
          if (isfilter) {
            setpaginationStopForFilter(true);
            setisPaginationActive(false);
          } else {
            setpaginationStop(true);
            setisPaginationActive(false);
          }
        }
        testType.status = !data.status;
        settestTypeStatusAllSelected(false);
      }
      return testType;
    });
    settestType(res);

    let filterData = testType.filter((data) => data.status === true);
    filterData = filterData.map((data) => {
      return data.name;
    });
    setselectedTestType(filterData);
  };

  const checkSearchResultData = (data, index) => {
    return (
      <Card
        open={() => {
          setSendInvitePopup(true);
        }}
        {...data}
      />
    );
  };
  const checkData = (data, index) => {
    return (
      <Card
        open={() => {
          setSendInvitePopup(true);
        }}
        {...data}
      />
    );
  };

  const filterCreationDate = async (creationDatDropdown, pageNo) => {
    try {
      setloading(true);
      setisfilter(true);
      const token = getCookie("Xh7ERL0G");
      const decoed = jwtDecode(token);

      if (creationDatDropdown === "Last 1 Week") {
        endDate.setDate(endDate.getDate() - 7);
      }
      if (creationDatDropdown === "Last 1 Month") {
        endDate.setDate(endDate.getDate() - 30);
      }
      if (creationDatDropdown === "Last Quarter") {
        endDate.setDate(endDate.getDate() - 120);
      }
      if (creationDatDropdown === "Last Year") {
        endDate.setDate(endDate.getDate() - 365);
      }

      if (creationDatDropdown !== "") {
        const res = await axios.post(
          `${backend_url}test/getTestAsPerCreationDate/${decoed.user_id}?page=${pageNo}&limit=10`,
          {
            startDate: `${startDate.getFullYear()}-${
              startDate.getMonth() + 1
            }-${startDate.getDate()}`,
            endDate: `${endDate.getFullYear()}-${
              endDate.getMonth() + 1
            }-${endDate.getDate()}`,
            status: selectedTestStatus.length ? selectedTestStatus : [],
            testInviteOnly: selectedInviteOnly.length ? true : undefined,
            testType: selectedTestType.length ? selectedTestType : [],
            selectedCreatedBy:
              selectedCreatedBy !== ""
                ? selectedCreatedBy === decoed.user_id
                  ? decoed.user_id
                  : selectedCreatedBy
                : undefined,
            searchText:
              searchText !== "" ? searchText.toLowerCase() : undefined,
          },
          { headers: { token: token } }
        );
        if (searchFilter && searchText !== "") {
          setsearchResultCount(res.data.NoOfRecord);
        } else {
          setfilterResultCount(res.data.NoOfRecord);
        }
        setloading(false);
        if (res.data.data.length) {
          setPage(2);

          if (res.data.data.length < 10) {
            setpaginationStopForFilter(false);
            // setisPaginationActive(false)
          }

          if (searchFilter && searchText !== "") {
            setsearchData(res.data.data);
          } else {
            setfilterTestData(res.data.data);
          }
        } else {
          setfilterTestData([]);
        }
      } else {
        if (
          selectedTestStatus.length !== 0 ||
          selectedTestType.length !== 0 ||
          selectedCreatedBy !== decode.user_id
        ) {
          setisfilter(true);
          if (creationDatDropdown === "Last 1 Week") {
            endDate.setDate(endDate.getDate() - 7);
          }
          if (creationDatDropdown === "Last 1 Month") {
            endDate.setDate(endDate.getDate() - 30);
          }
          if (creationDatDropdown === "Last Quarter") {
            endDate.setDate(endDate.getDate() - 120);
          }
          if (creationDatDropdown === "Last Year") {
            endDate.setDate(endDate.getDate() - 365);
          }
          const token = getCookie("Xh7ERL0G");
          const decode = jwtDecode(token);
          const currentDate = new Date(startDate);
          let newDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate()
          );
          newDate = new Date(newDate.getTime() + 24 * 60 * 60 * 1000);
          const res = await axios.post(
            `${backend_url}test/filterAssessemntAsPerParameterOptimise/${
              decode.user_id
            }?page=${1}&limit=10`,
            {
              status: selectedTestStatus.length ? selectedTestStatus : [],
              testInviteOnly: selectedInviteOnly.length ? true : undefined,
              testType: selectedTestType.length ? selectedTestType : [],
              startDate: creationDatDropdown !== "" ? newDate : undefined,
              endDate:
                creationDatDropdown !== ""
                  ? `${endDate.getFullYear()}-${
                      endDate.getMonth() + 1
                    }-${endDate.getDate()}`
                  : undefined,
              selectedCreatedBy:
                selectedCreatedBy !== ""
                  ? selectedCreatedBy === decode.user_id
                    ? decode.user_id
                    : selectedCreatedBy
                  : undefined,
              searchText:
                searchText !== "" ? searchText.toLowerCase() : undefined,
            },
            { headers: { token: token } }
          );
          if (searchFilter && searchText !== "") {
            setsearchResultCount(res.data.NoOfRecord);
          } else {
            setfilterResultCount(res.data.NoOfRecord);
          }
          setloading(false);
          if (res.data.data.length < 10) {
            setpaginationStopForFilter(false);
            //setisPaginationActive(false)
          }
          setPage((prev) => prev + 1);
          if (searchFilter && searchText !== "") {
            if (page > 1) {
              res.data.data.forEach((data) => {
                const found = searchData.some((el) => el._id === data._id);
                if (!found) {
                  setsearchData((prev) => [...prev, data]);
                }
              });
            } else {
              setsearchData(res.data.data);
            }
          } else {
            if (page > 1) {
              res.data.data.forEach((data) => {
                const found = filterTestData.some((el) => el._id === data._id);
                if (!found) {
                  setfilterTestData((prev) => [...prev, data]);
                }
              });
            } else {
              setfilterTestData(res.data.data);
            }
          }
        } else {
          setisfilter(false);
          const token = getCookie("Xh7ERL0G");
          const decode = jwtDecode(token);

          if (creationDatDropdown === "Last 1 Week") {
            endDate.setDate(endDate.getDate() - 7);
          }
          if (creationDatDropdown === "Last 1 Month") {
            endDate.setDate(endDate.getDate() - 30);
          }
          if (creationDatDropdown === "Last Quarter") {
            endDate.setDate(endDate.getDate() - 120);
          }
          if (creationDatDropdown === "Last Year") {
            endDate.setDate(endDate.getDate() - 365);
          }
          const currentDate = new Date(startDate);
          let newDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate()
          );
          newDate = new Date(newDate.getTime() + 24 * 60 * 60 * 1000);
          const res = await axios.post(
            `${backend_url}test/filterAssessemntAsPerParameterOptimise/${
              decode.user_id
            }?page=${1}&limit=10`,
            {
              status: selectedTestStatus.length ? selectedTestStatus : [],
              testInviteOnly: selectedInviteOnly.length ? true : undefined,
              testType: selectedTestType.length ? selectedTestType : [],
              startDate: creationDatDropdown !== "" ? newDate : undefined,
              endDate:
                creationDatDropdown !== ""
                  ? `${endDate.getFullYear()}-${
                      endDate.getMonth() + 1
                    }-${endDate.getDate()}`
                  : undefined,
              selectedCreatedBy:
                selectedCreatedBy !== ""
                  ? selectedCreatedBy === decode.user_id
                    ? decode.user_id
                    : selectedCreatedBy
                  : undefined,
              searchText:
                searchText !== "" ? searchText.toLowerCase() : undefined,
            },
            { headers: { token: token } }
          );
          setloading(false);
          if (searchFilter && searchText !== "") {
            setsearchFilter(true);
            setsearchResultCount(res.data.NoOfRecord);
            setsearchData(res.data.data);
          } else {
            setisfilter(false);
            settestData(res.data.data);
            settotalTest(res.data.NoOfRecord);
          }
        }
        // getCreatedByUsers()
      }
    } catch (error) {
      setloading(false);
      toast(error);
    }
  };

  const getFilterCount = () => {
    let searchCount = 0;

    if (searchFilter && searchText !== "") {
      searchData.map((data) => {
        searchCount += 1;
      });
    } else {
      (isfilter ? filterTestData : testData).map((data) => {
        searchCount += 1;
      });
    }
    return searchCount;
  };

  const closeAssessmentModel = () => {
    setCreateAssessment(false);
  };

  const onHandleScroll = (e) => {
    if (
      Math.abs(
        e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)
      ) <= 1
    ) {
      setisPaginationActive(true);
      if (searchFilter && searchText !== "") {
        if (paginationStopForSearch) {
          setPaginationLoading(true);
          searchTest()
            .then((res) => {
              setPaginationLoading(false);
            })
            .catch((err) => {
              setPaginationLoading(false);
            });
        }
      } else {
        if (isfilter) {
          if (paginationStopForFilter) {
            setPaginationLoading(true);
            filterAssessementAsPerParameter()
              .then((data) => {
                setPaginationLoading(false);
                // Do something with the returned data
              })
              .catch((error) => {
                setPaginationLoading(false);
                // Handle the error
              });
          }
        } else {
          if (paginationStop) {
            setPaginationLoading(true);
            getAllTest()
              .then((data) => {
                setPaginationLoading(false);
              })
              .catch((error) => {
                setPaginationLoading(false);
              });
          }
        }
      }
      // if (searchFilter && searchText) {
      //     setsearchPage(prev => prev + 1)
      //     setsearchLoading(false)
      // } else {
      //     if (isfilter) {
      //         setPage(prev => prev + 1)
      //     } else {
      //         setPage(prev => prev + 1)
      //         setloading(false)
      //     }
      // }
    } else {
      return;
    }
  };

  const closePopup = (e) => {
    e.stopPropagation();
    setCreateAssessment(false);
  };

  const clickAssessment = (e) => {
    e.stopPropagation();
    setCreateAssessment(true);
  };

  useEffect(() => {
    if (
      selectedTestStatus.length !== 0 ||
      selectedInviteOnly.length !== 0 ||
      selectedTestType.length !== 0 ||
      selectedCreatedBy !== decode.user_id
    ) {
      setisfilter(true);
      filterAssessementAsPerParameter();
    } else {
      setisfilter(false);
      getAllTest();
    }
  }, [
    selectedTestStatus,
    selectedInviteOnly,
    selectedTestType,
    selectedCreatedBy,
  ]);

  const filterAssessementAsPerParameter = () => {
    console.log("jhgjhjhjhgjg");
    return new Promise(async (resolve, reject) => {
      setCanceled(false);
      setloading(true);
      if (cancelToken) {
        cancelToken.cancel("Operations cancelled due to new request");
      }
      cancelToken = axios.CancelToken.source();
      try {
        if (selectedCreationDate === "Last 1 Week") {
          endDate.setDate(endDate.getDate() - 7);
        }
        if (selectedCreationDate === "Last 1 Month") {
          endDate.setDate(endDate.getDate() - 30);
        }
        if (selectedCreationDate === "Last Quarter") {
          endDate.setDate(endDate.getDate() - 120);
        }
        if (selectedCreationDate === "Last Year") {
          endDate.setDate(endDate.getDate() - 365);
        }
        const token = getCookie("Xh7ERL0G");
        const decode = jwtDecode(token);
        const currentDate = new Date(startDate);
        let newDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );
        newDate = new Date(newDate.getTime() + 24 * 60 * 60 * 1000);

        const res = await axios.post(
          `${backend_url}test/filterAssessemntAsPerParameterOptimise/${decode.user_id}?page=${page}&limit=10`,
          {
            status: selectedTestStatus.length ? selectedTestStatus : [],
            testInviteOnly: selectedInviteOnly.length ? true : undefined,
            testType: selectedTestType.length ? selectedTestType : [],
            startDate: selectedCreationDate !== "" ? newDate : undefined,
            endDate:
              selectedCreationDate !== ""
                ? `${endDate.getFullYear()}-${
                    endDate.getMonth() + 1
                  }-${endDate.getDate()}`
                : undefined,
            selectedCreatedBy:
              selectedCreatedBy !== ""
                ? selectedCreatedBy === decode.user_id
                  ? decode.user_id
                  : selectedCreatedBy
                : undefined,
            searchText:
              searchText !== "" ? searchText.toLowerCase() : undefined,
          },
          { cancelToken: cancelToken.token, headers: { token: token } }
        );
        if (searchText !== "" && searchFilter) {
          setsearchResultCount(res.data.NoOfRecord);
        } else {
          setfilterResultCount(res.data.NoOfRecord);
        }
        if (res.data.data.length < 10) {
          setpaginationStopForFilter(false);
          //setisPaginationActive(false)
        }
        setPage((prev) => prev + 1);
        if (searchFilter && searchText !== "") {
          if (page > 1) {
            res.data.data.forEach((data) => {
              const found = searchData.some((el) => el._id === data._id);
              if (!found) {
                setsearchData((prev) => [...prev, data]);
              }
            });
          } else {
            setsearchData(res.data.data);
          }
        } else {
          if (page > 1) {
            res.data.data.forEach((data) => {
              const found = filterTestData.some((el) => el._id === data._id);
              if (!found) {
                setfilterTestData((prev) => [...prev, data]);
              }
            });
          } else {
            setfilterTestData(res.data.data);
          }
        }
        setloading(false);
        setCanceled(false);
        resolve("done"); // Return the data
      } catch (error) {
        if (error.message === "Operations cancelled due to new request") {
          setloading(false);
          setCanceled(true);
        } else {
          setloading(false);
        }
        toast.error(error);
        reject(error); // Reject with the error
      } finally {
        setloading(false); // Ensure that the loading state is always set to false
      }
    });
  };

  const handleCreationDateDropdown = (e) => {
    setPage(() => 1);
    if (isfilter) {
      setpaginationStopForFilter(true);
    } else {
      setpaginationStop(true);
    }
    setselectedCreationDate(e.target.value);
    filterCreationDate(e.target.value, 1);
  };

  const handleCreatedByDropdown = async (e) => {
    setPage(() => 1);
    setselectedCreatedBy(e.target.value);
    if (e.target.value !== "") {
      setsearchFilter(false);
    } else {
      if (searchText !== "") {
        setsearchFilter(true);
      }
      if (
        selectedTestStatus.length !== 0 ||
        selectedTestType.length !== 0 ||
        e.target.value !== ""
      ) {
        setisfilter(true);
      } else {
        setisfilter(false);
      }
      setloading(true);

      if (
        selectedTestStatus.length !== 0 ||
        selectedTestType.length !== 0 ||
        e.target.value !== ""
      ) {
        setisfilter(true);
        if (selectedCreationDate === "Last 1 Week") {
          endDate.setDate(endDate.getDate() - 7);
        }
        if (selectedCreationDate === "Last 1 Month") {
          endDate.setDate(endDate.getDate() - 30);
        }
        if (selectedCreationDate === "Last Quarter") {
          endDate.setDate(endDate.getDate() - 120);
        }
        if (selectedCreationDate === "Last Year") {
          endDate.setDate(endDate.getDate() - 365);
        }

        const token = getCookie("Xh7ERL0G");
        const decode = jwtDecode(token);
        const currentDate = new Date(startDate);
        let newDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );
        newDate = new Date(newDate.getTime() + 24 * 60 * 60 * 1000);
        const res = await axios.post(
          `${backend_url}test/filterAssessemntAsPerParameterOptimise/${
            decode.user_id
          }?page=${1}&limit=10`,
          {
            status: selectedTestStatus.length ? selectedTestStatus : [],
            testInviteOnly: selectedInviteOnly.length ? true : undefined,
            testType: selectedTestType.length ? selectedTestType : [],
            startDate: selectedCreationDate !== "" ? newDate : undefined,
            endDate:
              selectedCreationDate !== ""
                ? `${endDate.getFullYear()}-${
                    endDate.getMonth() + 1
                  }-${endDate.getDate()}`
                : undefined,
            selectedCreatedBy:
              e.target.value !== ""
                ? e.target.value === decode.user_id
                  ? decode.user_id
                  : e.target.value
                : undefined,
            searchText:
              searchText !== "" ? searchText.toLowerCase() : undefined,
          },
          { headers: { token: token } }
        );
        setloading(false);
        setfilterResultCount(res.data.NoOfRecord);
        if (res.data.data.length < 10) {
          setpaginationStopForFilter(false);
          //setisPaginationActive(false)
        }

        if (searchFilter && searchText !== "") {
          if (page > 1) {
            res.data.data.forEach((data) => {
              const found = searchData.some((el) => el._id === data._id);
              if (!found) {
                setsearchData((prev) => [...prev, data]);
              }
            });
          } else {
            setsearchData(res.data.data);
          }
          setisfilter(true);
        } else {
          if (page > 1) {
            res.data.data.forEach((data) => {
              const found = filterTestData.some((el) => el._id === data._id);

              if (!found) {
                setfilterTestData((prev) => [...prev, data]);
              }
            });
          } else {
            setfilterTestData(res.data.data);
          }
          setisfilter(true);
        }
        setPage((prev) => prev + 1);
      } else {
        setisfilter(false);
        const token = getCookie("Xh7ERL0G");
        const decode = jwtDecode(token);

        if (selectedCreationDate === "Last 1 Week") {
          endDate.setDate(endDate.getDate() - 7);
        }
        if (selectedCreationDate === "Last 1 Month") {
          endDate.setDate(endDate.getDate() - 30);
        }
        if (selectedCreationDate === "Last Quarter") {
          endDate.setDate(endDate.getDate() - 120);
        }
        if (selectedCreationDate === "Last Year") {
          endDate.setDate(endDate.getDate() - 365);
        }
        const currentDate = new Date(startDate);
        let newDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );
        newDate = new Date(newDate.getTime() + 24 * 60 * 60 * 1000);
        const res = await axios.post(
          `${backend_url}test/filterAssessemntAsPerParameterOptimise/${
            decode.user_id
          }?page=${1}&limit=10`,
          {
            status: selectedTestStatus.length ? selectedTestStatus : [],
            testInviteOnly: selectedInviteOnly.length ? true : undefined,
            testType: selectedTestType.length ? selectedTestType : [],
            startDate: selectedCreationDate !== "" ? newDate : undefined,
            endDate:
              selectedCreationDate !== ""
                ? `${endDate.getFullYear()}-${
                    endDate.getMonth() + 1
                  }-${endDate.getDate()}`
                : undefined,
            selectedCreatedBy:
              e.target.value !== ""
                ? e.target.value === decode.user_id
                  ? decode.user_id
                  : e.target.value
                : undefined,
            searchText:
              searchText !== "" ? searchText.toLowerCase() : undefined,
          },
          { headers: { token: token } }
        );
        setloading(false);
        if (searchFilter && searchText !== "") {
          setsearchFilter(true);
          setsearchResultCount(res.data.NoOfRecord);
          setsearchData(res.data.data);
        } else {
          setisfilter(false);
          settestData(res.data.data);
          settotalTest(res.data.NoOfRecord);
        }
      }
    }

    if (isfilter) {
      setpaginationStopForFilter(true);
    } else {
      setpaginationStop(true);
    }
  };

  useEffect(() => {
    setsearchPage(1);
    setpaginationStopForSearch(true);
    setisPaginationActive(false);
    cancelToken = axios.CancelToken.source();
    // console.log(searchText === "")
    // if (searchText === "") {
    //     filterAssessementAsPerParameter()
    // }
  }, [searchText]);

  const handleSearchChange = async (e) => {
    setsearchLoading(true);
    setCanceled(false);
    onChangeSearchSkillText(e.target.value);
    const value = e.target.value;
    if (cancelToken) {
      cancelToken.cancel("Operations cancelled due to new request");
    }
    cancelToken = axios.CancelToken.source();

    try {
      if (selectedCreationDate === "Last 1 Week") {
        endDate.setDate(endDate.getDate() - 7);
      }
      if (selectedCreationDate === "Last 1 Month") {
        endDate.setDate(endDate.getDate() - 30);
      }
      if (selectedCreationDate === "Last Quarter") {
        endDate.setDate(endDate.getDate() - 120);
      }
      if (selectedCreationDate === "Last Year") {
        endDate.setDate(endDate.getDate() - 365);
      }
      const token = getCookie("Xh7ERL0G");
      const decoed = jwtDecode(token);
      let res = [];
      res = await axios.post(
        `${backend_url}test/searchTest?page=1&limit=10`,
        {
          searchText: value.toLowerCase(),
          status: selectedTestStatus.length ? selectedTestStatus : [],
          testInviteOnly: selectedInviteOnly.length ? true : undefined,
          testType: selectedTestType.length ? selectedTestType : [],
          startDate:
            selectedCreationDate !== ""
              ? `${startDate.getFullYear()}-${
                  startDate.getMonth() + 1
                }-${startDate.getDate()}`
              : undefined,
          endDate:
            selectedCreationDate !== ""
              ? `${endDate.getFullYear()}-${
                  endDate.getMonth() + 1
                }-${endDate.getDate()}`
              : undefined,
          selectedCreatedBy:
            selectedCreatedBy !== ""
              ? selectedCreatedBy === decoed.user_id
                ? decoed.user_id
                : selectedCreatedBy
              : undefined,
          userId: decoed.user_id,
        },
        { cancelToken: cancelToken.token, headers: { token: token } }
      );

      if (value !== "") {
        setsearchResultCount(res.data.NoOfRecord);
      } else {
        setfilterResultCount(res.data.NoOfRecord);
      }
      if (
        selectedTestStatus.length !== 0 ||
        selectedTestType.length !== 0 ||
        selectedCreatedBy !== decode.user_id
      ) {
        setisfilter(true);
      } else {
        setisfilter(false);
      }

      setSkillData(res.data.data, value);
      setsearchLoading(false);
      setCanceled(false);
    } catch (error) {
      if (error.message === "Operations cancelled due to new request") {
        setCanceled(true);
        setsearchLoading(false);
      } else {
        setsearchLoading(false);
      }
      toast.error(error);
    } finally {
      setsearchLoading(false); // Ensure that the loading state is always set to false
    }
  };

  const setSkillData = (data, text) => {
    if (data.length) {
      if (data.length < 10) {
        setpaginationStopForSearch(false);
      }
      setsearchFilter(true);
      setsearchLoading(false);
      if (text !== "") {
        if (searchPage > 1) {
          data.forEach((data) => {
            setsearchData((prev) => [...prev, data]);
          });
        } else {
          setsearchData(data);
        }
      } else {
        setisfilter(true);
        setfilterTestData(data);
      }
    } else {
      setsearchData([]);
      setsearchLoading(false);
      setsearchFilter(true);
    }
  };

  const onChangeSearchSkillText = (text) => {
    setsearchText(text);
  };

  const clearSearchText = async () => {
    setsearchText("");
    setsearchFilter(false);
    setloading(true);
    try {
      if (selectedCreationDate === "Last 1 Week") {
        endDate.setDate(endDate.getDate() - 7);
      }
      if (selectedCreationDate === "Last 1 Month") {
        endDate.setDate(endDate.getDate() - 30);
      }
      if (selectedCreationDate === "Last Quarter") {
        endDate.setDate(endDate.getDate() - 120);
      }
      if (selectedCreationDate === "Last Year") {
        endDate.setDate(endDate.getDate() - 365);
      }
      const token = getCookie("Xh7ERL0G");
      const decode = jwtDecode(token);
      const currentDate = new Date(startDate);
      let newDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      newDate = new Date(newDate.getTime() + 24 * 60 * 60 * 1000);
      const res = await axios.post(
        `${backend_url}test/filterAssessemntAsPerParameterOptimise/${decode.user_id}?page=1&limit=10`,
        {
          status: selectedTestStatus.length ? selectedTestStatus : [],
          testInviteOnly: selectedInviteOnly.length ? true : undefined,
          testType: selectedTestType.length ? selectedTestType : [],
          startDate: selectedCreationDate !== "" ? newDate : undefined,
          endDate:
            selectedCreationDate !== ""
              ? `${endDate.getFullYear()}-${
                  endDate.getMonth() + 1
                }-${endDate.getDate()}`
              : undefined,
          selectedCreatedBy:
            selectedCreatedBy !== ""
              ? selectedCreatedBy === decode.user_id
                ? decode.user_id
                : selectedCreatedBy
              : undefined,
        },
        { headers: { token: token } }
      );
      // console.log(searchFilter && searchText !== "")
      // if (searchFilter && searchText !== "") {
      //     setsearchResultCount(res.data.NoOfRecord)
      // } else {
      setfilterResultCount(res.data.NoOfRecord);
      //}
      if (res.data.data.length < 10) {
        setpaginationStopForFilter(false);
        setisPaginationActive(false);
      }
      if (
        selectedTestStatus.length !== 0 ||
        selectedTestType.length !== 0 ||
        selectedCreatedBy !== decode.user_id
      ) {
        setisfilter(true);
      } else {
        setisfilter(false);
      }

      setPage((prev) => prev + 1);
      if (
        selectedTestStatus.length !== 0 ||
        selectedTestType.length !== 0 ||
        selectedCreatedBy !== decode.user_id
      ) {
        setfilterTestData(res.data.data);
      } else {
        settestData(res.data.data);
      }
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  return (
    <div>
      <ReactTooltip />
      <SuperNavbar active="assessment" />

      <div
        onScroll={(e) => onHandleScroll(e)}
        className="assessment-super-admin-container"
      >
        <div className="banner">
          <div className="total">
            <svg
              width="40"
              height="40"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="48" height="48" rx="24" fill="#00C49A" />
              <path
                d="M32.6682 15.7464L30.2536 13.3318C29.8325 12.9084 29.3315 12.5726 28.7797 12.344C28.228 12.1154 27.6363 11.9985 27.0391 12H18.5455C17.3404 12.0015 16.1851 12.4808 15.3329 13.3329C14.4808 14.1851 14.0014 15.3404 14 16.5455V32.9091C14.0014 34.1142 14.4808 35.2695 15.3329 36.1216C16.1851 36.9737 17.3404 37.4531 18.5455 37.4545H29.4545C30.6596 37.4531 31.8149 36.9737 32.6671 36.1216C33.5192 35.2695 33.9985 34.1142 34 32.9091V18.9609C34.0015 18.3637 33.8846 17.772 33.656 17.2203C33.4274 16.6685 33.0916 16.1675 32.6682 15.7464ZM31.3827 17.0318C31.5112 17.161 31.6266 17.3026 31.7273 17.4546H29.4545C29.2134 17.4546 28.9822 17.3588 28.8117 17.1883C28.6412 17.0178 28.5454 16.7866 28.5454 16.5455V14.2727C28.6974 14.3737 28.839 14.4894 28.9682 14.6182L31.3827 17.0318ZM29.4545 35.6364H18.5455C17.8221 35.6364 17.1284 35.349 16.617 34.8376C16.1055 34.3261 15.8182 33.6324 15.8182 32.9091V16.5455C15.8182 15.8221 16.1055 15.1285 16.617 14.617C17.1284 14.1055 17.8221 13.8182 18.5455 13.8182H26.7273V16.5455C26.7273 17.2688 27.0146 17.9625 27.5261 18.4739C28.0375 18.9854 28.7312 19.2727 29.4545 19.2727H32.1818V32.9091C32.1818 33.6324 31.8945 34.3261 31.383 34.8376C30.8715 35.349 30.1779 35.6364 29.4545 35.6364Z"
                fill="white"
              />
              <path
                d="M19.4482 20.1815H23.0845C23.3256 20.1815 23.5568 20.0857 23.7273 19.9152C23.8978 19.7447 23.9936 19.5135 23.9936 19.2724C23.9936 19.0313 23.8978 18.8 23.7273 18.6295C23.5568 18.4591 23.3256 18.3633 23.0845 18.3633H19.4482C19.207 18.3633 18.9758 18.4591 18.8053 18.6295C18.6348 18.8 18.5391 19.0313 18.5391 19.2724C18.5391 19.5135 18.6348 19.7447 18.8053 19.9152C18.9758 20.0857 19.207 20.1815 19.4482 20.1815Z"
                fill="white"
              />
              <path
                d="M28.5391 22H19.4482C19.207 22 18.9758 22.0958 18.8053 22.2663C18.6348 22.4368 18.5391 22.668 18.5391 22.9091C18.5391 23.1502 18.6348 23.3814 18.8053 23.5519C18.9758 23.7224 19.207 23.8182 19.4482 23.8182H28.5391C28.7802 23.8182 29.0114 23.7224 29.1819 23.5519C29.3524 23.3814 29.4481 23.1502 29.4481 22.9091C29.4481 22.668 29.3524 22.4368 29.1819 22.2663C29.0114 22.0958 28.7802 22 28.5391 22Z"
                fill="white"
              />
              <path
                d="M28.5391 25.6367H19.4482C19.207 25.6367 18.9758 25.7325 18.8053 25.903C18.6348 26.0735 18.5391 26.3047 18.5391 26.5458C18.5391 26.7869 18.6348 27.0181 18.8053 27.1886C18.9758 27.3591 19.207 27.4549 19.4482 27.4549H28.5391C28.7802 27.4549 29.0114 27.3591 29.1819 27.1886C29.3524 27.0181 29.4481 26.7869 29.4481 26.5458C29.4481 26.3047 29.3524 26.0735 29.1819 25.903C29.0114 25.7325 28.7802 25.6367 28.5391 25.6367Z"
                fill="white"
              />
              <path
                d="M26.7209 29.2734H19.4482C19.207 29.2734 18.9758 29.3692 18.8053 29.5397C18.6348 29.7102 18.5391 29.9414 18.5391 30.1825C18.5391 30.4236 18.6348 30.6549 18.8053 30.8254C18.9758 30.9958 19.207 31.0916 19.4482 31.0916H26.7209C26.962 31.0916 27.1932 30.9958 27.3637 30.8254C27.5342 30.6549 27.63 30.4236 27.63 30.1825C27.63 29.9414 27.5342 29.7102 27.3637 29.5397C27.1932 29.3692 26.962 29.2734 26.7209 29.2734Z"
                fill="white"
              />
            </svg>
            <div className="detail">
              <span>Total Assesments</span>
              {loadingforDashboard ? (
                <div class="loader"></div>
              ) : (
                <p>{dashboardData.totalAssesment}</p>
              )}
            </div>
          </div>
          <div className="candidates-invited">
            <svg
              width="40"
              height="40"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="48" height="48" rx="24" fill="#00C49A" />
              <path
                d="M33 17L22 28"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M33 17L26 37L22 28L13 24L33 17Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div className="detail">
              <span>Total Invited</span>
              {loadingforDashboard ? (
                <div class="loader"></div>
              ) : (
                <p>{dashboardData.candidatesInvites}</p>
              )}
            </div>
          </div>
          <div className="candidates-appeared">
            <svg
              width="40"
              height="40"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="48" height="48" rx="24" fill="#00C49A" />
              <path
                d="M32 33V31C32 29.9391 31.5786 28.9217 30.8284 28.1716C30.0783 27.4214 29.0609 27 28 27H20C18.9391 27 17.9217 27.4214 17.1716 28.1716C16.4214 28.9217 16 29.9391 16 31V33"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M24 23C26.2091 23 28 21.2091 28 19C28 16.7909 26.2091 15 24 15C21.7909 15 20 16.7909 20 19C20 21.2091 21.7909 23 24 23Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div className="detail">
              <span>Total Appeared</span>
              {loadingforDashboard ? (
                <div class="loader"></div>
              ) : (
                <p>{dashboardData.candidatesAppeared}</p>
              )}
            </div>
          </div>
          <div className="candidates-shortlisted">
            <svg
              width="40"
              height="40"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="48" height="48" rx="24" fill="#00C49A" />
              <path
                d="M30 33V31C30 29.9391 29.5786 28.9217 28.8284 28.1716C28.0783 27.4214 27.0609 27 26 27H19C17.9391 27 16.9217 27.4214 16.1716 28.1716C15.4214 28.9217 15 29.9391 15 31V33"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22.5 23C24.7091 23 26.5 21.2091 26.5 19C26.5 16.7909 24.7091 15 22.5 15C20.2909 15 18.5 16.7909 18.5 19C18.5 21.2091 20.2909 23 22.5 23Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M31 23L33 25L37 21"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div className="detail">
              <span>Total Hired</span>
              {loadingforDashboard ? (
                <div class="loader"></div>
              ) : (
                <p>{dashboardData.candidatesHired}</p>
              )}
            </div>
          </div>
        </div>

        <div className="buttonContainer">
          <div>
            <span>
              Assessments{" "}
              {(loading || searchLoading) && PaginationLoading === false ? (
                <div className="loader"></div>
              ) : (
                <>
                  {canceled ? (
                    <div className="loader"></div>
                  ) : (
                    <>
                      (
                      {searchFilter && searchText !== ""
                        ? searchResultCount !== ""
                          ? searchResultCount
                          : 0
                        : selectedTestStatus.length !== 0 ||
                          selectedInviteOnly.length !== 0 ||
                          selectedTestType.length !== 0 ||
                          selectedCreatedBy !== decode.user_id
                        ? filterResultCount !== ""
                          ? filterResultCount
                          : 0
                        : testNonFilterCount}
                      )
                    </>
                  )}{" "}
                </>
              )}{" "}
            </span>
            <div className="dashboard-search-container">
              <svg
                width="12"
                height="12"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.0216 16.099L11.5304 12.6071C8.80795 14.7531 4.88459 14.4054 2.58198 11.8141C0.279322 9.22267 0.395334 5.2857 2.84659 2.83444C5.29785 0.383128 9.23487 0.267116 11.8262 2.56973C14.4176 4.87238 14.7653 8.7957 12.6193 11.5182L16.1104 15.0086C16.3355 15.1964 16.4352 15.4952 16.368 15.7805C16.3007 16.0658 16.078 16.2886 15.7928 16.356C15.5075 16.4235 15.2087 16.324 15.0208 16.099H15.0216ZM2.4685 7.46203C2.46779 9.47522 3.67335 11.2935 5.52854 12.0768C7.38368 12.8601 9.52758 12.456 10.9703 11.0512C10.9845 11.0338 10.9996 11.0174 11.0156 11.0019C11.0316 10.9865 11.0479 10.9715 11.0644 10.9571C12.6398 9.33918 12.9376 6.86695 11.7914 4.92121C10.6451 2.97547 8.33841 2.0376 6.15961 2.6314C3.98081 3.2252 2.46897 5.20377 2.46845 7.46203H2.4685Z"
                  fill="#606D77"
                />
              </svg>
              <input
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    searchTest();
                  }
                }}
                onChange={handleSearchChange}
                value={searchText}
                placeholder="e.g. Full Stack Developer Test"
              />
              {/* Cancel Icon */}
              {searchText !== "" ? (
                <svg
                  onClick={() => clearSearchText()}
                  style={{ right: 50, cursor: "pointer" }}
                  width="12"
                  height="12"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.0032 13.9813L7.00642 8.985L2.01082 13.9813C1.55102 14.441 0.805358 14.4412 0.345266 13.9815C-0.114825 13.5219 -0.115113 12.7761 0.344547 12.3161L5.34122 7.31986L0.344476 2.32245C-0.102534 1.86017 -0.0962087 1.12477 0.358851 0.670542C0.813839 0.216023 1.54922 0.210848 2.01082 0.658468L7.00642 5.65473L12.0032 0.658468C12.4666 0.222348 13.1925 0.233272 13.6426 0.683192C14.0927 1.13282 14.1041 1.85873 13.6684 2.32245L8.67162 7.31986L13.6684 12.3161C14.1157 12.7781 14.1098 13.5135 13.6551 13.968C13.2004 14.4228 12.4651 14.4286 12.0031 13.9813H12.0032Z"
                    fill="#99B2C6"
                  />
                </svg>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div>
            <div className="recruiter  name-input-box">
              <select name="" id="" onChange={(e) => onClickTestType(e)}>
                <option value={""}>Test Type </option>
                {testType.map((data, index) => {
                  return (
                    <option value={data.name}>
                      {data.name} ({data.count})
                    </option>
                  );
                })}
              </select>
            </div>

            {/* <div className="thisMonth">
                            <select name="" id="">
                                <option value="This  Month">This  Month</option>
                            </select>
                        </div> */}

            <div className="createdby">
              <select
                ref={testStatusBySelect}
                style={{ cursor: "pointer" }}
                value={selectedCreatedBy}
                onChange={(e) => onClickTestStatus(e)}
              >
                <option label="Test Status" value={""} />

                {testStatus?.map((data) => {
                  return (
                    <option value={data.name}>
                      {data.name} ({data.count}){" "}
                    </option>
                  );
                })}
              </select>
              {/* onClick={() => onClickTestStatus(data)} */}
              {/* <select required name="memberName"  >
                                <option> Test Status</option>
                                {testStatus.map((data, index) => {
                                    return <option  >{data.name} ({data.count})</option>
                                })}   </select> */}
            </div>

            <div className="creationdate">
              <select
                ref={createdBySelect}
                style={{ cursor: "pointer" }}
                value={selectedCreatedBy}
                onChange={handleCreatedByDropdown}
              >
                <option label="All" value="all" />
                <option label="Reacently Created" value="Recently Created" />
                {createdBy?.map((data) => {
                  return <option label={data.fullName} value={data._id} />;
                })}
              </select>
            </div>
            <button className="btn-createAssesment">
              <svg
                width="15"
                height="15"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10" cy="10" r="10" fill="white"></circle>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.10343 14.2434L9.0947 14.1477V11.2798H5.90789C5.4315 11.2818 5.0352 10.9523 5.00221 10.5268C4.96918 10.1012 5.31079 9.72574 5.78291 9.66877L5.891 9.66095H9.09405V6.8071C9.09657 6.38242 9.46434 6.03135 9.93741 6.00198C10.4105 5.97261 10.8288 6.27487 10.8968 6.69514L10.9049 6.79197V9.65815H14.0917C14.5683 9.65624 14.9648 9.98596 14.9978 10.4118C15.0308 10.8376 14.6891 11.2132 14.2167 11.2703L14.108 11.277H10.9049V14.1309C10.9046 14.5566 10.5365 14.9096 10.0622 14.9391C9.58791 14.9686 9.16888 14.6645 9.10286 14.2428L9.10343 14.2434Z"
                  fill="#FF6812"
                ></path>
              </svg>
              <span>Create Assessments</span>
            </button>
          </div>
        </div>
        <div className="assessment-card">
          {(loading || searchLoading) && PaginationLoading === false ? (
            <>
              {loadingArray.map((index) => (
                <AssessmentSkeleton key={index} />
              ))}
            </>
          ) : (
            <>
              {canceled ? (
                <>
                  {loadingArray.map((index) => (
                    <AssessmentSkeleton key={index} />
                  ))}
                </>
              ) : (
                <>
                  {searchFilter && searchText !== "" ? (
                    <>
                      {searchData ? (
                        <>
                          {searchData.length ? (
                            <>
                              {searchData.map((data, index) => {
                                return checkSearchResultData(data);
                              })}
                            </>
                          ) : (
                            <div style={{ textAlign: "center" }}>
                              No assessment available
                            </div>
                          )}
                        </>
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          "No assessment available "
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {(isfilter ? filterTestData : testData) ? (
                        <>
                          {(isfilter ? filterTestData : testData).length ? (
                            <>
                              {(isfilter ? filterTestData : testData).map(
                                (data) => {
                                  return checkData(data);
                                }
                              )}
                            </>
                          ) : (
                            <div style={{ textAlign: "center" }}>
                              No assessment available
                            </div>
                          )}
                        </>
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          No assessment available
                        </div>
                      )}
                    </>
                  )}

                  {PaginationLoading ? (
                    <>
                      {loadingArray.map((index) => (
                        <AssessmentSkeleton key={index} />
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Assessments;
