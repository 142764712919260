import React, { useState, useContext, useEffect } from "react";
import "./index.css";
import SuperNavbar from "../../component/SuperNavbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ManageAccessSideBar from "../../component/ManageAccessSidebar";
import DeleteInvitePopup from "../../component/DeleteInvitePopup";
import EditInviteMember from "../../component/EditInviteMember";
import CreateTestContext from "../../store/CreateTestContext";
import { AssignInvitesApi } from "../../apis";
import { useNavigate } from "react-router-dom";
import {
  deleteDepartmentApi,
  addDepartmentApi,
  getDepartmentApi,
  getvalidityUserApi,
} from "../../apis";

const ManageSeniority = () => {
  let navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [deleteInvite, setDeleteInvite] = useState(false);
  const [validity, setValidity] = useState();
  const [depMemCount, setDepMemCount] = useState([]);
  const [editInvite, setEditInvite] = useState(false);
  const [id, setId] = useState();
  const { deleteDepartment } = useContext(CreateTestContext);
  const [department, setDepartment] = useState([
    {
      "Sr. No.": 1,
      "Job Role": "Software Engineer",
      Name: "John Doe",
      Experience: "5 years",
      Settings: {
        Edit: true,
        Delete: true,
      },
    },
    {
      "Sr. No.": 2,
      "Job Role": "Product Manager",
      Name: "Jane Smith",
      Experience: "8 years",
      Settings: {
        Edit: true,
        Delete: true,
      },
    },
    {
      "Sr. No.": 3,
      "Job Role": "Data Analyst",
      Name: "Mike Johnson",
      Experience: "3 years",
      Settings: {
        Edit: true,
        Delete: true,
      },
    },
    {
      "Sr. No.": 4,
      "Job Role": "Graphic Designer",
      Name: "Sarah Adams",
      Experience: "6 years",
      Settings: {
        Edit: true,
        Delete: true,
      },
    },
    {
      "Sr. No.": 5,
      "Job Role": "Marketing Specialist",
      Name: "David Wilson",
      Experience: "4 years",
      Settings: {
        Edit: true,
        Delete: true,
      },
    },
  ]);
  const [errorDepartmentId, setErrorDepartmentId] = useState();
  const [errorInviteAssigned, setErrorInviteAssigned] = useState();

  const [fromData, setFromData] = useState({
    inviteAssigned: 0,
  });

  const handelChange = (e) => {
    setFromData({ ...fromData, [e.target.name]: e.target.value });
    setErrorDepartmentId();
    setErrorInviteAssigned();
  };
  const handelonSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;
    if (id == undefined) {
      setErrorDepartmentId("Department is required");
      isValid = false;
    }
    if (parseInt(fromData.inviteAssigned) <= 0) {
      setErrorInviteAssigned(" Must Be Greater Then 0");
      isValid = false;
    }
    if (parseInt(fromData.inviteAssigned) > validity?.packageId.noOfInvites) {
      setErrorInviteAssigned(" Must Be Greater Then 0");
      isValid = false;
    }

    if (isValid) {
      const res = await AssignInvitesApi(id, fromData);
      if (res.status == 200) {
        memberData();
        fromData.inviteAssigned = 0;
        e.target.reset();
        toast(res.data.msg, {
          className: "toast-message",
        });
      }
    }
  };
  const handleDeleteInvitePopup = () => {
    setDeleteInvite(false);
  };
  const handleEditInvitePopup = () => {
    setEditInvite(false);
  };
  const memberData = async () => {
    try {
      const resValidity = await getvalidityUserApi();
      setValidity(resValidity.data.data);
      const resDepartment = await getDepartmentApi(
        `page=${currentPage}&limit=10`
      );
      // setDepartment(resDepartment.data.data);
      setDepMemCount(resDepartment.data.countNo);
      setloading(false);
      setCurrentPage(parseInt(resDepartment.data.currentPage));
      setTotalPages(parseInt(resDepartment.data.totalPages));
      setTotalDocs(parseInt(resDepartment.data.totalDocs));
    } catch (error) {
      setTimeout(() => {
        setloading(false);
      }, 2000);
    }
  };

  const result = (id) => {
    const aa = depMemCount.filter((item) => item?._id === id);
    return aa[0]?.count;
  };

  useEffect(() => {
    memberData();
  }, [currentPage]);
  console.log(validity, "=--=-=-=-=");

  return (
    <div className="manage-seniority-main-container">
      <SuperNavbar active={"settings"} />
      {deleteInvite ? (
        <DeleteInvitePopup
          cancelButton={handleDeleteInvitePopup}
          onClickYes={async () => {
            const res = await deleteDepartmentApi(id);
            if (res.status == 200) {
              deleteDepartment(id);
              memberData();
              setDeleteInvite(false);
              toast("Deleted successfully", {
                className: "toast-message",
              });
            }
          }}
        />
      ) : null}
      {editInvite ? (
        <EditInviteMember
          cancelButton={handleEditInvitePopup}
          data={id}
          setEditInvite={setEditInvite}
          department={department}
          // setDepartment={setDepartment}
        />
      ) : null}
      <div className="manage-invites-outer-container">
        <div className="manage-invites-left">
          <ManageAccessSideBar active={"manageSeniority"} />
        </div>
        <div className="manage-invite-right">
          <div className="manage-invites-form-container">
            <div className="manage-access-content">
              <div className="manage-access-header">
                <div className="manage-access-title">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="24"
                      cy="24"
                      r="24"
                      fill="#00C49A"
                      fillOpacity="0.1"
                    />
                    <path
                      d="M9 1.5C4.8675 1.5 1.5 4.8675 1.5 9C1.5 13.1325 4.8675 16.5 9 16.5C13.1325 16.5 16.5 13.1325 16.5 9C16.5 4.8675 13.1325 1.5 9 1.5ZM9 3C11.4853 3 13.5 5.01472 13.5 7.5C13.5 9.98528 11.4853 12 9 12C6.51472 12 4.5 9.98528 4.5 7.5C4.5 5.01472 6.51472 3 9 3ZM9 6C8.17157 6 7.5 6.67157 7.5 7.5C7.5 8.32843 8.17157 9 9 9C9.82843 9 10.5 8.32843 10.5 7.5C10.5 6.67157 9.82843 6 9 6ZM9 10.5C7.61929 10.5 6.5 11.6193 6.5 13C6.5 14.3807 7.61929 15.5 9 15.5C10.3807 15.5 11.5 14.3807 11.5 13C11.5 11.6193 10.3807 10.5 9 10.5Z"
                      fill="#00C49A"
                      transform="translate(15 15)"
                    />
                  </svg>
                  <div className="manage-access-name">
                    <span>Add Senior </span>
                    {/* <p>Add interviewer for particular job role</p> */}
                  </div>
                </div>
              </div>
              <form onSubmit={(e) => handelonSubmit(e)}>
                <div className="manage-access-form-container">
                  <div className="top-forms-container">
                    <div
                      className="input-outer-container"
                      style={{
                        width: "25%",
                      }}
                    >
                      <span>
                        Select Department
                        <span className="requeredclass">*</span>
                      </span>
                      <div className="input-inner-container name-input-box">
                        <select
                          name="id"
                          id=""
                          onChange={(e) => {
                            setId(e.target.value);
                          }}
                        >
                          <option>Select</option>
                          <option>Human Resourses</option>
                          <option>Marketing</option>
                          <option>Finance</option>
                          <option>IT</option>
                          <option>Sales</option>
                        </select>
                      </div>
                      <span className="errorMsg">{errorDepartmentId}</span>
                    </div>

                    <div className="input-outer-container">
                      <span>
                        Seniority Name <span className="requeredclass">*</span>
                      </span>
                      <div className="input-inner-container name-input-box">
                        {/* <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 1.5L8.25 9.75"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M16.5 1.5L11.25 16.5L8.25 9.75L1.5 6.75L16.5 1.5Z"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg> */}

                        <input
                          type="text"
                          placeholder="Enter name to add"
                          name="inviteAssigned"
                          onChange={handelChange}
                        />
                      </div>
                      <span className="errorMsg">{errorInviteAssigned}</span>
                    </div>

                    <div className="send-invite-btn-container">
                      <button>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="10" cy="10" r="10" fill="white"></circle>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.10343 14.2434L9.0947 14.1477V11.2798H5.90789C5.4315 11.2818 5.0352 10.9523 5.00221 10.5268C4.96918 10.1012 5.31079 9.72574 5.78291 9.66877L5.891 9.66095H9.09405V6.8071C9.09657 6.38242 9.46434 6.03135 9.93741 6.00198C10.4105 5.97261 10.8288 6.27487 10.8968 6.69514L10.9049 6.79197V9.65815H14.0917C14.5683 9.65624 14.9648 9.98596 14.9978 10.4118C15.0308 10.8376 14.6891 11.2132 14.2167 11.2703L14.108 11.277H10.9049V14.1309C10.9046 14.5566 10.5365 14.9096 10.0622 14.9391C9.58791 14.9686 9.16888 14.6645 9.10286 14.2428L9.10343 14.2434Z"
                            fill="#FF6812"
                          ></path>
                        </svg>
                        Add Senior
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <div className="manage-access-table-content">
                {/* <div className="table-header-container">
                                    <span>Manage Department & Invites</span>
                                </div> */}
                <div className="table1-heading">
                  <div className="table-header-container">
                    <span>Manage Seniority</span>
                  </div>

                  <div className="pagination">
                    <button
                      onClick={() => {
                        if (currentPage > 1) {
                          if (currentPage > 1) {
                            if (loading === false) {
                              setCurrentPage(currentPage - 1);
                            }
                          }
                        }
                      }}
                    >
                      <svg
                        width="7"
                        height="12"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L2.41421 6Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    <span>
                      {totalPages === 0 ? 0 : currentPage}/{totalPages}
                    </span>
                    <button
                      onClick={() => {
                        setCurrentPage(
                          currentPage === totalPages || totalPages === 0
                            ? currentPage
                            : loading === false
                            ? currentPage + 1
                            : currentPage
                        );
                      }}
                    >
                      <svg
                        width="7"
                        height="12"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <table cellSpacing={0}>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Job Role</th>
                    <th>Name</th>
                    <th>Experience</th>
                    <th>Settings</th>
                  </tr>
                  {/* {department.map((ele, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{ele.department}</td>
                        <td>Dnyaneshwar</td>
                        <td>
                          {result(ele?._id) == undefined ? (
                            <button onClick={() => navigate("/manageaccess")}>
                              <svg
                                width="10"
                                height="11"
                                viewBox="0 0 10 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_6980_3395)">
                                  <path
                                    d="M9.16732 0.85083L4.58398 5.43416"
                                    stroke="white"
                                    stroke-width="0.75"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M9.16732 0.85083L6.25065 9.18416L4.58398 5.43416L0.833984 3.7675L9.16732 0.85083Z"
                                    stroke="white"
                                    stroke-width="0.75"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_6980_3395">
                                    <rect
                                      width="10"
                                      height="10"
                                      fill="white"
                                      transform="translate(0 0.0175781)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              Assign
                            </button>
                          ) : (
                            result(ele?._id)
                          )}
                        </td>

                        <td className="manage-role-settings">
                          <button
                            onClick={() => {
                              setEditInvite(true);
                              setId(ele);
                            }}
                          >
                            <svg
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.867188 8.87124H1.41719L6.95469 3.33374L6.40469 2.78374L0.867188 8.32124V8.87124ZM8.54219 2.79624L6.94219 1.19624L7.46719 0.67124C7.60885 0.529574 7.78385 0.45874 7.99219 0.45874C8.20052 0.45874 8.37552 0.529574 8.51719 0.67124L9.06719 1.22124C9.20885 1.36291 9.27969 1.53791 9.27969 1.74624C9.27969 1.95457 9.20885 2.12957 9.06719 2.27124L8.54219 2.79624ZM8.01719 3.32124L1.71719 9.62124H0.117188V8.02124L6.41719 1.72124L8.01719 3.32124ZM6.67969 3.05874L6.40469 2.78374L6.95469 3.33374L6.67969 3.05874Z"
                                fill="#333333"
                              />
                            </svg>
                            Edit
                          </button>
                          <button
                            onClick={() => {
                              setDeleteInvite(true);
                              setId(ele._id);
                            }}
                          >
                            <svg
                              width="9"
                              height="10"
                              viewBox="0 0 9 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.87969 9.12134C1.67344 9.12134 1.49688 9.0479 1.35 8.90103C1.20313 8.75415 1.12969 8.57759 1.12969 8.37134V1.24634H0.617188V0.496338H2.96719V0.121338H6.26719V0.496338H8.61719V1.24634H8.10469V8.37134C8.10469 8.57134 8.02969 8.74634 7.87969 8.89634C7.72969 9.04634 7.55469 9.12134 7.35469 9.12134H1.87969ZM7.35469 1.24634H1.87969V8.37134H7.35469V1.24634ZM3.20469 7.29634H3.95469V2.30884H3.20469V7.29634ZM5.27969 7.29634H6.02969V2.30884H5.27969V7.29634Z"
                                fill="#333333"
                              />
                            </svg>
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })} */}

                  {department.map((item) => (
                    <tr key={item["Sr. No."]}>
                      <td>{item["Sr. No."]}</td>
                      <td>{item["Job Role"]}</td>
                      <td>{item["Name"]}</td>
                      <td>{item["Experience"]}</td>
                      <td className="manage-role-settings">
                        <button>Edit</button>
                        <button>Delete</button>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSeniority;
