import "./App.css";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Assessments from "./screen/Assessments";
import AssessmentOverview from "./screen/AssessmentOverview";
import AssementQuestion from "./screen/AssessmentQuestion";

import CheckLoginRoute from "./CheckLoginRoute";
import ChangePassword from "./screen/ChangePassword";
import CandidateShortlisted from "./screen/CandidateShortlisted";
import Candidates from "./screen/Candidate/Candidates";
import CandidateTestTaken from "./screen/CandidateTestTaken";
import CandidatePass from "./screen/CandidatePass";
import CandidateReviewed from "./screen/CandidateReviewed";
import CandidateInvited from "./screen/CandidateInvited";

import EmployeeReport from "./screen/EmployeeReport";

import ForgotPassword from "./Authentication/ForgotPassword";

import Interview from "./screen/InterviewScreen/Interview";

import jwtDecode from "jwt-decode";

import Login from "./Authentication/Login";
import Library from "./screen/Library";

import ManageAccess from "./screen/ManageAccess";
import ManageRoleAdditions from "./screen/ManageRoleAddtions";
import ManageDepartments from "./screen/ManageDepartments";
import ManageInvites from "./screen/ManageInvites";
import ManageSeniority from "./screen/ManageSeniority";

import NotFound from "./screen/NotFound";
import NewDashboard from "./screen/NewDashboard";

import ProtectedRoutes from "./ProtectedRoutes";
import Profile from "./screen/Profile";

import QuestionAnalytics from "./screen/QuestionAnalytics";

import ResetPassword from "./Authentication/ResetPassword";

import ReactTooltip from "react-tooltip";

import TestReports from "./screen/TestReports";
import TestAnalytics from "./screen/TestAnalytics";

import ViewShortlisted from "./screen/ViewShortlisted";

import { getCookie, deleteAllCookie } from "../src/constant";

function App() {
  const token = getCookie("Xh7ERL0G");

  const redirectTowebSite = () => {
    deleteAllCookie();
    return window.open("https://theeliteqa.com/", "_self");
  };
  return (
    <>
      <ReactTooltip />
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              token === null || token === undefined ? (
                <>{redirectTowebSite()}</>
              ) : (
                <Navigate to="/dashboard" />
              )
            }
          />
          {/* <Route path='/' element={<CheckLoginRoute> <Login /></CheckLoginRoute>} />
          <Route path="/forgotpassword" element={<CheckLoginRoute><ForgotPassword /></CheckLoginRoute>} />
          <Route path="/resetpassword/:email" element={<CheckLoginRoute><ResetPassword /></CheckLoginRoute>} /> */}
          <Route
            path="/changepassword"
            element={
              <ProtectedRoutes>
                <ChangePassword />{" "}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoutes>
                {" "}
                <NewDashboard />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/employeereport"
            element={
              <ProtectedRoutes>
                <EmployeeReport />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoutes>
                <ManageDepartments />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/manageaccess"
            element={
              <ProtectedRoutes>
                <ManageAccess />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/manageseniority"
            element={
              <ProtectedRoutes>
                <ManageSeniority />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/userprofile"
            element={
              <ProtectedRoutes>
                <Profile />{" "}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/assessment"
            element={
              <ProtectedRoutes>
                <Assessments />{" "}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/candidateshortlisted/:id"
            element={
              <ProtectedRoutes>
                <CandidateShortlisted />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/candidatepass/:id"
            element={
              <ProtectedRoutes>
                <CandidatePass />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/candidatereviewed/:id"
            element={
              <ProtectedRoutes>
                <CandidateReviewed />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/candidateinvited/:id"
            element={
              <ProtectedRoutes>
                <CandidateInvited />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/assessmentoverview/:id"
            element={
              <ProtectedRoutes>
                <AssessmentOverview />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/assessmentquestion/:id"
            element={
              <ProtectedRoutes>
                <AssementQuestion />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/candidatetesttaken/:id"
            element={
              <ProtectedRoutes>
                <CandidateTestTaken />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/library"
            element={
              <ProtectedRoutes>
                <Library />{" "}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/interview"
            element={
              <ProtectedRoutes>
                <Interview />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/candidate"
            element={
              <ProtectedRoutes>
                <Candidates />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/testreports"
            element={
              <ProtectedRoutes>
                <TestReports />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/managedepartment"
            element={
              <ProtectedRoutes>
                <ManageDepartments />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/manageinvite"
            element={
              <ProtectedRoutes>
                <ManageInvites />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/manageroleadditions"
            element={
              <ProtectedRoutes>
                <ManageRoleAdditions />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/testanalytics/:id"
            element={
              <ProtectedRoutes>
                <TestAnalytics />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/questionanalytics"
            element={
              <ProtectedRoutes>
                <QuestionAnalytics />
              </ProtectedRoutes>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
