
import React from 'react'
import { Navigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode';
import {getCookie,deleteAllCookie} from '../src/constant'

const ProtectedRoutes = ({ children }) => {
  
    const token = getCookie('Xh7ERL0G')
    
    if(token===null || token === undefined ) {
        return window.open("https://theeliteqa.com/", "_self");
        
    }
    
    // decode the jwt token and check the expiration time
    const decoded = jwt_decode(token);
    const expirationTime = (decoded.exp * 1000)- 60000;

    // if token is not there redirect to login or token expired
    if (token === null || Date.now() >= expirationTime) {
        deleteAllCookie()
        return window.open("https://theeliteqa.com/", "_self");
    }
    return children
}

export default ProtectedRoutes