import { Column } from "@ant-design/plots";

const AntColumnGraph = (props) => {
  console.log("Column Graph", props.data.results);

  function getCandidateCounts(marksArray, scoresArray) {
    const candidateCounts = [];

    marksArray.forEach((marksObj) => {
      const marksRange = marksObj.marks;
      const candidates = scoresArray?.reduce((total, scoreObj) => {
        const percentage = scoreObj.percentage;
        if (
          percentage > 0 &&
          percentage >= parseInt(marksRange.split("-")[0]) &&
          percentage <= parseInt(marksRange.split("-")[1])
        ) {
          return total + 1;
        } else {
          return total;
        }
      }, 0);

      candidateCounts.push({
        marks: marksRange,
        candidates: candidates,
      });
    });

    return candidateCounts;
  }
  const data = [
    {
      marks: "0",
      candidates: 0,
    },
    {
      marks: "0-9",
      candidates: 0,
    },
    {
      marks: "10-19",
      candidates: 1,
    },
    {
      marks: "20-29",
      candidates: 5,
    },
    {
      marks: "30-39",
      candidates: 4,
    },
    {
      marks: "40-49",
      candidates: 1,
    },
    {
      marks: "50-59",
      candidates: 0,
    },
    {
      marks: "60-69",
      candidates: 0,
    },
    {
      marks: "70-79",
      candidates: 0,
    },
    {
      marks: "80-89",
      candidates: 0,
    },
    {
      marks: "90-99",
      candidates: 0,
    },
    {
      marks: "100",
      candidates: 0,
    },
  ];
  const config = {
    data: getCandidateCounts(data, props.data.results),
    xField: "marks",
    yField: "candidates",

    columnWidthRatio: 0.5,
    yAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
      title: {
        text: "Number Of Candidates",
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
      title: {
        text: "Score Range In Percentage",
      },
    },
    meta: {
      marks: {
        alias: "marks",
      },
      candidates: {
        alias: "candidates",
      },
    },
    color: "#00C49A",
  };
  return <Column {...config} style={{ margin: "80px 50px 0px 50px" }} />;
};

export default AntColumnGraph;
