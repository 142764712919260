import { deleteApi, getApi, postApi, putApi } from "./api-interface";

export const getTenTest = () => {
  return getApi(`test/getTenTest`);
};

// export const getShortlistedApi = async (id) => {
//     return await getApi(`invites/getShortlisted/${id}`) invites/getShortlisted
// };

export const getShortlistedApi = async (id) => {
  return await getApi(`invites/getShortlisted/${id}`);
};
/* Member APIs */

export const inviteMemberApi = (payload) => {
  return postApi(`invites/createMember`, payload);
};
export const getMemberApi = (page) => {
  return getApi(`invites/getMember?${page}`);
};

export const updateMemberApi = (id, payload) => {
  return putApi(`invites/editMember/${id}`, payload);
};

export const deleteMemberApi = (id) => {
  return deleteApi(`invites/deleteMember/${id}`);
};

/* Department APIs */
export const addDepartmentApi = (payload) => {
  return postApi(`invites/createDepartment`, payload);
};

export const getDepartmentContexApi = (page) => {
  return getApi(`invites/getDepartment`);
};
export const getDepartmentApi = (page) => {
  return getApi(`invites/get/Departments?${page}`);
};
export const deleteDepartmentApi = (id) => {
  return deleteApi(`invites/deleteDepartment/${id}`);
};

export const AssignInvitesApi = (id, payload) => {
  return putApi(`invites/editDepartment/${id}`, payload);
};
export const UpdateDepartmentStatusApi = (id, payload) => {
  return putApi(`invites/editDepartment/${id}`, payload);
};
export const getDepartmentMemberApi = () => {
  return getApi(`invites/getDepartmentMember`);
};

export const getvalidityUserApi = () => {
  return getApi(`invites/find`);
};

// Invite APIs

// export const updateUserApi = (payload) => {
//     return putApi(`/api/updatedUser`, payload);
// };

/* Category APIs */

export const getAsssearchApi = (uuid) => {
  return getApi(`test/getTenTest/${uuid}`);
};

export const searchAssApi = (id) => {
  return postApi(`test/filterAssessemnt/${id}`);
};
/* Dashboard APIs */

// export const getDashboardDataApi = () => {
//     return getApi(`/api/dashboard`);
// };
