import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";

function ManageAccessSideBar(props) {
  const [isOpen, setIsOpen] = useState(props.openSubMenu);

  const navigate = useNavigate();
  return (
    <div className="assessment-sidebar">
      <div className="assessment-sidebar-content">
        <div
          style={{
            paddingBottom: "13px",
            borderBottom: "1px solid #DDDDDD",
            paddingLeft: "0px",
          }}
          className={"test-notActive"}
        >
          <span>Settings</span>
        </div>

        <div
          onClick={() => {
            navigate("/managedepartment");
          }}
          style={{ marginTop: "15px" }}
          className={
            props.active == "managedepartment"
              ? "test-active"
              : "test-notActive"
          }
        >
          <svg
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.625 14.75C1.325 14.75 1.0625 14.6375 0.8375 14.4125C0.6125 14.1875 0.5 13.925 0.5 13.625V4.45625C0.5 4.15625 0.6125 3.89375 0.8375 3.66875C1.0625 3.44375 1.325 3.33125 1.625 3.33125H5.1875V1.625C5.1875 1.325 5.3 1.0625 5.525 0.8375C5.75 0.6125 6.0125 0.5 6.3125 0.5H9.6875C9.9875 0.5 10.25 0.6125 10.475 0.8375C10.7 1.0625 10.8125 1.325 10.8125 1.625V3.33125H14.375C14.675 3.33125 14.9375 3.44375 15.1625 3.66875C15.3875 3.89375 15.5 4.15625 15.5 4.45625V13.625C15.5 13.925 15.3875 14.1875 15.1625 14.4125C14.9375 14.6375 14.675 14.75 14.375 14.75H1.625ZM6.3125 3.33125H9.6875V1.625H6.3125V3.33125ZM14.375 10.0438H9.70625V11.1688H6.33125V10.0438H1.625V13.625H14.375V10.0438ZM7.45625 10.0438H8.58125V8.91875H7.45625V10.0438ZM1.625 8.91875H6.33125V7.79375H9.70625V8.91875H14.375V4.45625H1.625V8.91875Z"
              fill="white"
            />
          </svg>

          <span>Manage Departments</span>
        </div>

        <div
          onClick={() => {
            navigate("/manageaccess");
          }}
          className={
            props.active == "manageAccess" ? "test-active" : "test-notActive"
          }
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.75 7.5H15M15 7.5H17.25M15 7.5V5.25M15 7.5V9.75M0.75 15V14.25C0.75 12.8576 1.30312 11.5223 2.28769 10.5377C3.27226 9.55312 4.60761 9 6 9M6 9C7.39239 9 8.72774 9.55312 9.71231 10.5377C10.6969 11.5223 11.25 12.8576 11.25 14.25V15M6 9C6.79565 9 7.55871 8.68393 8.12132 8.12132C8.68393 7.55871 9 6.79565 9 6C9 5.20435 8.68393 4.44129 8.12132 3.87868C7.55871 3.31607 6.79565 3 6 3C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6C3 6.79565 3.31607 7.55871 3.87868 8.12132C4.44129 8.68393 5.20435 9 6 9Z"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span>Manage Access</span>
        </div>

        {/* <div
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className={isOpen ? "test-active" : "test-notActive"}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.75 7.5H15M15 7.5H17.25M15 7.5V5.25M15 7.5V9.75M0.75 15V14.25C0.75 12.8576 1.30312 11.5223 2.28769 10.5377C3.27226 9.55312 4.60761 9 6 9M6 9C7.39239 9 8.72774 9.55312 9.71231 10.5377C10.6969 11.5223 11.25 12.8576 11.25 14.25V15M6 9C6.79565 9 7.55871 8.68393 8.12132 8.12132C8.68393 7.55871 9 6.79565 9 6C9 5.20435 8.68393 4.44129 8.12132 3.87868C7.55871 3.31607 6.79565 3 6 3C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6C3 6.79565 3.31607 7.55871 3.87868 8.12132C4.44129 8.68393 5.20435 9 6 9Z"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Manage Access</span>
        </div>
        {isOpen && (
          <div className="manageMemberSubmenuitems">
            <div
              onClick={() => navigate("/manageaccess/invitemember")}
              className={
                props.activeSubMenu == "inviteMembers"
                  ? "sub-menu-active"
                  : "sub-menu-notActive"
              }
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.75 7.5H15M15 7.5H17.25M15 7.5V5.25M15 7.5V9.75M0.75 15V14.25C0.75 12.8576 1.30312 11.5223 2.28769 10.5377C3.27226 9.55312 4.60761 9 6 9M6 9C7.39239 9 8.72774 9.55312 9.71231 10.5377C10.6969 11.5223 11.25 12.8576 11.25 14.25V15M6 9C6.79565 9 7.55871 8.68393 8.12132 8.12132C8.68393 7.55871 9 6.79565 9 6C9 5.20435 8.68393 4.44129 8.12132 3.87868C7.55871 3.31607 6.79565 3 6 3C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6C3 6.79565 3.31607 7.55871 3.87868 8.12132C4.44129 8.68393 5.20435 9 6 9Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Invite Members</span>
            </div>
            <div
              onClick={() => navigate("/manageaccess/invitehr")}
              className={
                props.activeSubMenu == "inviteHR"
                  ? "sub-menu-active"
                  : "sub-menu-notActive"
              }
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.75 7.5H15M15 7.5H17.25M15 7.5V5.25M15 7.5V9.75M0.75 15V14.25C0.75 12.8576 1.30312 11.5223 2.28769 10.5377C3.27226 9.55312 4.60761 9 6 9M6 9C7.39239 9 8.72774 9.55312 9.71231 10.5377C10.6969 11.5223 11.25 12.8576 11.25 14.25V15M6 9C6.79565 9 7.55871 8.68393 8.12132 8.12132C8.68393 7.55871 9 6.79565 9 6C9 5.20435 8.68393 4.44129 8.12132 3.87868C7.55871 3.31607 6.79565 3 6 3C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6C3 6.79565 3.31607 7.55871 3.87868 8.12132C4.44129 8.68393 5.20435 9 6 9Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Invite HR Team</span>
            </div>

            <div
              onClick={() => navigate("/manageaccess/viewmembers")}
              className={
                props.activeSubMenu == "viewMembers"
                  ? "sub-menu-active"
                  : "sub-menu-notActive"
              }
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.75 7.5H15M15 7.5H17.25M15 7.5V5.25M15 7.5V9.75M0.75 15V14.25C0.75 12.8576 1.30312 11.5223 2.28769 10.5377C3.27226 9.55312 4.60761 9 6 9M6 9C7.39239 9 8.72774 9.55312 9.71231 10.5377C10.6969 11.5223 11.25 12.8576 11.25 14.25V15M6 9C6.79565 9 7.55871 8.68393 8.12132 8.12132C8.68393 7.55871 9 6.79565 9 6C9 5.20435 8.68393 4.44129 8.12132 3.87868C7.55871 3.31607 6.79565 3 6 3C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6C3 6.79565 3.31607 7.55871 3.87868 8.12132C4.44129 8.68393 5.20435 9 6 9Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>View Members</span>
            </div>
          </div>
        )} */}

        <div
          onClick={() => {
            navigate("/manageinvite");
          }}
          className={
            props.active == "invite" ? "test-active" : "test-notActive"
          }
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.5 1.5L8.25 9.75"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.5 1.5L11.25 16.5L8.25 9.75L1.5 6.75L16.5 1.5Z"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span> Manage Invites </span>
        </div>

        <div
          onClick={() => {
            navigate("/manageroleadditions");
          }}
          className={
            props.active == "roleAdditions" ? "test-active" : "test-notActive"
          }
        >
          <svg
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill="#ffffff"
            stroke="#ffffff"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <g id="Complete">
                <g id="user">
                  <g>
                    <path
                      d="M20,21V19a4,4,0,0,0-4-4H8a4,4,0,0,0-4,4v2"
                      fill="none"
                      stroke="#ffffff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.44"
                    ></path>
                    <circle
                      cx="12"
                      cy="7"
                      fill="none"
                      r="4"
                      stroke="#ffffff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.44"
                    ></circle>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <span> Manage Role Additions </span>
        </div>

        <div
          onClick={() => {
            navigate("/manageseniority");
          }}
          className={
            props.active == "manageSeniority" ? "test-active" : "test-notActive"
          }
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1.5C4.8675 1.5 1.5 4.8675 1.5 9C1.5 13.1325 4.8675 16.5 9 16.5C13.1325 16.5 16.5 13.1325 16.5 9C16.5 4.8675 13.1325 1.5 9 1.5ZM9 3C11.4853 3 13.5 5.01472 13.5 7.5C13.5 9.98528 11.4853 12 9 12C6.51472 12 4.5 9.98528 4.5 7.5C4.5 5.01472 6.51472 3 9 3ZM9 6C8.17157 6 7.5 6.67157 7.5 7.5C7.5 8.32843 8.17157 9 9 9C9.82843 9 10.5 8.32843 10.5 7.5C10.5 6.67157 9.82843 6 9 6ZM9 10.5C7.61929 10.5 6.5 11.6193 6.5 13C6.5 14.3807 7.61929 15.5 9 15.5C10.3807 15.5 11.5 14.3807 11.5 13C11.5 11.6193 10.3807 10.5 9 10.5Z"
              fill="white"
            />
          </svg>
          <span>Manage Seniority</span>
        </div>
      </div>
    </div>
  );
}

export default ManageAccessSideBar;
