import React, { useContext, useState } from "react";
import "./index.css";
import { AssignInvitesApi } from "../../apis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateTestContext from "../../store/CreateTestContext";

const EditInviteMember = (props) => {
  const createTestContext = useContext(CreateTestContext);
  const [fromData, setFromData] = useState(props.data);
  const [errorMsg, setErrorMsg] = useState({
    inviteAssigned: "",
  });
  const handelChange = (e) => {
    setFromData({ ...fromData, [e.target.name]: e.target.value });
    setErrorMsg({});
  };
  const handelonSubmit = async () => {
    if (parseInt(fromData.inviteAssigned) <= 0) {
      return setErrorMsg({ inviteAssigned: "Must be greater than 0" });
    }
    const res = await AssignInvitesApi(props.data._id, {
      inviteAssigned: parseInt(fromData.inviteAssigned),
    });
    if (res) {
      props.getDepartment();
      props.memberData();
      props.closeModal();
      toast("Updated successfully", {
        className: "toast-message",
      });
    }
  };

  return (
    <div className="edit-invite-member-outer-container">
      <div className="edit-invite-inner-container">
        <div className="edit-invite-header-container">
          <span>Update Department Invites Details</span>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              props.closeModal();
            }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.0032 13.6551L7.00642 8.65882L2.01082 13.6551C1.55102 14.1148 0.805358 14.1151 0.345266 13.6554C-0.114825 13.1957 -0.115113 12.4499 0.344547 11.99L5.34122 6.99369L0.344476 1.99628C-0.102534 1.534 -0.0962087 0.798602 0.358851 0.34437C0.813839 -0.110149 1.54922 -0.115324 2.01082 0.332296L7.00642 5.32856L12.0032 0.332296C12.4666 -0.103824 13.1925 -0.0928997 13.6426 0.35702C14.0927 0.806652 14.1041 1.53256 13.6684 1.99628L8.67162 6.99369L13.6684 11.99C14.1157 12.4519 14.1098 13.1873 13.6551 13.6419C13.2004 14.0967 12.4651 14.1024 12.0031 13.6551H12.0032Z"
              fill="#99B2C6"
            />
          </svg>
        </div>
        <div className="manage-access-form-container">
          <div className="top-forms-container">
            <div className="select-input-outer-container">
              <span>Department</span>
              <div className="input-inner-container name-input-box">
                <select name="department" id="" value={fromData.department}>
                  <option>{fromData.department}</option>
                </select>
              </div>
            </div>
            <div className="input-outer-container">
              <span>Assign Invites</span>
              <div className="input-inner-container name-input-box">
                <input
                  type="number"
                  placeholder="Assign Invites"
                  name="inviteAssigned"
                  onChange={handelChange}
                />
              </div>
              <span className="errorMsg">{errorMsg.inviteAssigned}</span>
            </div>
          </div>

          <div className="send-invite-btn-container">
            <button onClick={handelonSubmit}>
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10" cy="10.2449" r="10" fill="white" />
                <g clip-path="url(#clip0_6565_5953)">
                  <path
                    d="M14.5 5.24487L9 10.7449"
                    stroke="#FF6812"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.5 5.24487L11 15.2449L9 10.7449L4.5 8.74487L14.5 5.24487Z"
                    stroke="#FF6812"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6565_5953">
                    <rect
                      width="12"
                      height="12"
                      fill="white"
                      transform="translate(3.5 4.24487)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditInviteMember;
